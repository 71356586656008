.section-3 {
  padding-top: 1px;
  width: 1070px;
  margin: auto;
  margin-top: 50px;
  position: relative;
}

.section-3 h1 {
  font-size: 5rem;
  color: var(--primary);
  width: fit-content;
  padding-bottom: 50px;
  margin: -2px;
}

.section-3 .swiper {
  width: 100%;
  min-height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.section-3 .swiper-pagination {
  top: 70px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section-3 .second.swiper-slide {
  display: flex;
}

.section-3 .second.swiper-slide>div {
  height: 300px;
  background-position: center;
  background-size: cover;
}

.section-3 .second.swiper-slide>div:nth-child(1) {
  width: 40%;
  height: 321px;
}



.section-3 .second.swiper-slide>div:nth-child(2) {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  padding-left: 55px;
  color: #cdcdcd;
}

.section-3 .second.swiper-slide>div:nth-child(2)>figure {
  width: 130px;
  height: 120px;
  margin-left: 40px;
}

.section-3 .second.swiper-slide>div:nth-child(2)>figure>img {
  width: 100%;
  height: 100%;
}

.section-3 .second.swiper-slide>div:nth-child(2) h3 {
  font-weight: 700;
  font-size: 2.7rem;
}

.section-3 .second.swiper-slide>div:nth-child(2) h4 {
  font-weight: 600;
  font-size: 2rem;
}

.section-3 .second.swiper-slide>div:nth-child(2) p {
  font-weight: 400;
  font-size: 1.6rem;
}

.section-3 .second.swiper-slide>div:nth-child(2) h5 {
  font-weight: 400;
  font-size: 1.7rem;
}

.loveit {
  width: 100% !important;
  font-size: 3rem !important;
  text-align: center;
  padding-top: 90px;
  color: rgb(90, 90, 90) !important;
}

@media (max-width: 800px) {
  .section-3 .second.swiper-slide {
    flex-direction: column;
  }

  .section-3 h1 {
    font-size: 3rem;
    text-align: center;
  }

  .second.swiper-slide>div {
    width: 100% !important;
  }

  .second.swiper-slide>div:nth-child(2) {
    /* margin-top: 30px; */
    padding: 0 !important;
    align-items: center !important;
  }

  .second.swiper-slide>div:nth-child(2) figure {
    margin: 0 !important;
  }
}

@media (max-width: 345px) {
  .main-section .section-3 {
    width: 270px;
  }

  .second.swiper-slide>div:nth-child(1) {
    height: 203px !important;
  }

  .second.swiper-slide>div:nth-child(2) {
    /* height: 300px; */
  }

  .second.swiper-slide>div:nth-child(2) h5 {
    font-size: 1.4rem !important;
  }

  .section-3 .second.swiper-pagination {
    top: 95px;
  }

  .loveit {
    font-size: 2rem !important;
    padding-top: 65px;
  }
}

@media (min-width: 346px) and (max-width: 480px) {
  .main-section .section-3 {
    width: 310px;
    text-align: center !important;
  }

  .second.swiper-slide>div:nth-child(1) {
    height: 233px !important;
  }

  .second.swiper-slide>div:nth-child(2) {
    /* height: 400px; */
  }

  .second.swiper-slide>div:nth-child(2) h5 {
    font-size: 1.4rem !important;
  }

  .loveit {
    font-size: 2rem !important;
    padding-top: 65px;
  }

  .section-3 .second.swiper-pagination {
    top: 60px;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .main-section .section-3 {
    width: 500px;
  }

  .second.swiper-slide>div:nth-child(1) {
    height: 300px !important; 
  }

  .second.swiper-slide>div:nth-child(2) {
    /* height: 400px; */
  }

  .second.swiper-slide>div:nth-child(2) h5 {
    font-size: 1.7rem !important;
  }

  .section-3 .second.swiper-pagination {
    top: 15%;
  }

  .loveit {
    font-size: 2.5rem !important;
  }

  .section-3 .second.swiper-pagination {
    top: 120px;
  }
}

@media (min-width: 601px) and (max-width: 786px) {
  .main-section .section-3 {
    width: 650px;
  }

  .section-3 .second.swiper-slide {
    align-items: center;
  }

  .second.swiper-slide>div:nth-child(1) {
    width: 70% !important;
    height: 342px !important;
  }

  .second.swiper-slide>div:nth-child(2) {
    /* height: 400px; */
  }

  .second.swiper-slide>div:nth-child(2) h5 {
    font-size: 2rem !important;
  }

  .loveit {
    font-size: 2.8rem !important;
  }

  .section-3 .second.swiper-pagination {
    top: 60px;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .main-section .section-3 {
    width: 700px;
  }
}

@media (min-width: 1001px) and (max-width: 1100px) {
  .main-section .section-3 {
    width: 900px;
  }

  .second.swiper-slide>div:nth-child(1) {
    height: 270px !important;
  }

  .second.swiper-slide>div:nth-child(2) {
    /* height: 300px; */
  }

  .second.swiper-slide>div:nth-child(2) h5 {
    font-size: 1.4rem !important;
  }

  .section-3 .second.swiper-pagination {
    top: 15%;
  }

  .loveit {
    font-size: 2rem !important;
  }

  .section-3 .second.swiper-pagination {
    top: 10%;
  }
}