dl,
ol,
ul {
  font-size: 1.4rem;
}
.sticky-title {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #161413; 
  z-index: 100; 
  padding: 8px 0; 
}
.affilated {
  width: 1070px;
  height: 430px;
  margin: 60px auto 70px auto;

  user-select: none;
}
.affilated > div {
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  border-top: 1px solid rgb(90, 90, 90);
  border-bottom: 1px solid rgb(90, 90, 90);
  margin: auto;
}
.affilated h4 {
  font-size: 2.4rem;
  color: rgb(210, 210, 210);
  margin-top: 7px;
}
.affilated p {
  margin-top: 7px;
  font-size: 1.4rem;
  color: rgb(210, 210, 210);
}

.head{
  width: 500px;
}
.head-p {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.body-p {
  display: flex;
  justify-content: space-between;
}
.title-p{
  width: 200px;
}
.title-p1{
  width: 177px;
}
@media (max-width: 1024px) {
  .affilated > div {
    width: 94%;
  }
  .affilated h4 {
  margin-left: 10px;
    font-size: 2rem;
  }
  .affilated p {
    font-size: 1.2rem;
  }
}

.affilated > div::-webkit-scrollbar-thumb {
  background: rgb(53, 53, 53);
  border: 3px solid gray; /* creates padding around scroll thumb */
}

.affilated > div::-webkit-scrollbar-track {
  background: gray;
}

.affilated > div::-webkit-scrollbar {
  width: 9px;
}

@media (max-width: 500px) {
  .affilated {
    width: 1070px;
    height: 460px;
  }
  .head{
    width: 270px;
  }
  .title-p{
    width: 86px;
  }
  .title-p1{
    width: 75px;
  }
}

@media (max-width: 345px) {
  .affilated {
    width: 270px;
  }
}

@media (min-width: 346px) and (max-width: 480px) {
  .affilated {
    width: 310px;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .affilated {
    width: 500px;
  }
}
@media (min-width: 601px) and (max-width: 786px) {
  .affilated {
    width: 650px;
  }
}
@media (min-width: 800px) and (max-width: 1000px) {
  .affilated {
    width: 700px;
  }
}
@media (min-width: 1001px) and (max-width: 1100px) {
  .affilated {
    width: 900px;
  }
}
