.footer {
  padding-top: 1px;
  width: 100%;
  margin: auto;
}
.footer > div {
  width: 1070px;
  margin: auto;
  margin-top: 40px;
}
.footer > div > .btnn {
  display: flex;
  justify-content: center;
  padding: 5px 0;
}
.footer > div > .btnn button {
  font-size: 2rem;
  padding: 5px 120px 5px 120px;
  border-radius: 20px;
  font-weight: 600;
  transition: all 0.2s;
  background-color: rgb(210, 210, 210);
}
.footer > div > .btnn button:hover {
  background-color: var(--primary);
}
.footer .payment-method {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}
.footer .payment-method h3 {
  font-size: 2rem;
  color: rgb(90, 90, 90);
  font-weight: 200;
}
.footer .payment-method div {
  display: flex;
  margin-top: 15px;
}
.footer .payment-method div figure {
  width: 62px;
  height: 40px;
  margin-left: 5px;
  margin-right: 5px;
  transition: all 0.3s;
  background-color: white;
}
.footer .payment-method div figure img {
  width: 100%;
  height: 100%;
}

.find-us {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}
.find-us h4 {
  font-size: 2rem;
  color: rgb(90, 90, 90);
  font-weight: 600;
}
.icons {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.icons span {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: rgb(210, 210, 210);
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icons span a{
  display: flex;
  text-align: center;
}
.icons span i {
  font-size: 2.4rem;
  transition: all 0.2s;
}
.icons span svg{
  width: 28px;
  height: 28px;
}
.icons span:nth-child(1):hover {
  background-color: #3a5794;
  color: white;
}
.icons span:nth-child(2):hover {
  background: linear-gradient(
    45deg,
    #405de6,
    #5851db,
    #c13584,
    #e1308c,
    #fd1d1d
  );
  color: white;
}

.secure {
  text-align: center;
}
.secure h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: rgb(90, 90, 90) !important;
}
.icons span:nth-child(3):hover {
  background-color: #27a831;
  color: white;
}
.telegramLogo {
  color: black;
}
.telegramLogo:hover {
  color: black;
}
.icons span:nth-child(4):hover {
  background-color: #0088cc;
  color: white;
}
.icons span:nth-child(5):hover {
  background-color: #ff0000;
  color: white;
}
.icons span:nth-child(6):hover {
  background: linear-gradient(
    60deg,
    #050505,
    #e692bf,
    hsla(336, 88%, 57%, 0.831),
    hsl(181, 92%, 72%),
    hsla(136, 22%, 52%, 0.725)
  );
  color: white;
}  
.icons span:nth-child(7):hover {
  background: linear-gradient(
    60deg,
    #04dc8d,
    #04dc8d,
    hsl(158, 96%, 44%),
    hsla(158, 96%, 44%, 1)
  );
  color: white;
}
.icon-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.icon-logo figure {
  width: 130px;
  height: 130px;
}
.icon-logo img {
  width: 100%;
  height: 100%;
}

.copyright {
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: space-evenly;
  color: rgb(90, 90, 90);
}
.copyright p {
  font-size: 1.7rem;
  transition: all 0.2s;
  color: rgb(90, 90, 90) !important;
  font-weight: 600;
}
.copyright p a {
  color: rgb(90, 90, 90) !important;
  text-decoration: none;
}
.copyright p:hover,
.copyright p a:hover {
  color: var(--primary);
}
.copyright button{
  font-weight: 500;
  font-size: "2rem" ;
  background-color: #2a2a2a;
  width: 280px;
  height: 25px;
  margin-bottom: 7px;
  color:rgb(210, 210, 210);
}
.copyright button:hover {
  background-color: var(--primary);
  color: rgb(0, 0, 0);
}
@media (min-width: 0px) and (max-width: 800px) {
  .footer {
    margin-top: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer > div {
    width: 90%;
  }
  .footer > div > .btnn button {
    padding: 5px 80px 5px 80px;
    font-weight: 600;
  }
  .footer .payment-method h3 {
    font-size: 1.6rem;
    font-weight: 600;
  }
  .footer .payment-method div figure {
    width: 55px;
  }
  .copyright {
    margin-top: 5px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .copyright p {
    font-size: 1.6rem;
  }
  .icons span svg {
    width: 24px;
    height: 24px;
  }
}

@media (max-width: 345px) {
  .footer > div {
    width: 310px;
  }
  .footer > div > .btnn button {
    padding: 5px 110px 5px 110px;
    font-weight: 600;
  }
  .footer > div > .btnn {
    padding: 5px 0;
  }
  .secure h3 {
    font-size: 1.3rem;
  }
  .copyright p {
    font-size: 1.3rem ;
  }
  .icons span {
    width: 45px;
    height: 34px;
  }
}
@media (min-width: 346px) and (max-width: 480px) {
  .footer > div > .btnn {
    padding: 10px 0;
  }
  .footer > div {
    width: 345px;
    text-align: center !important;
  }
  .footer > div > .btnn button {
    padding: 5px 110px 5px 110px;
    font-weight: 600;
  }
  .secure h3 {
    font-size: 1.3rem;
  }

  .copyright p {
    font-size: 1.3rem ;
  }
  .icons span {
    width: 45px;
    height: 40px;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .footer > div {
    width: 500px;
    text-align: center !important;
  }
  .footer > div > .btnn button {
    font-weight: 600;
    padding: 5px 110px 5px 110px;
  }
  .secure h3 {
    font-size: 1.3rem;
  }

  .copyright p {
    font-size: 1.3rem ;
  }
}

@media (min-width: 601px) and (max-width: 785px) {
  .footer > div {
    width: 650px;
    text-align: center !important;
  }
  .footer > div > .btnn button {
    padding: 5px 110px 5px 110px;
    font-weight: 600;
  }
  .secure h3 {
    font-size: 1.3rem;
  }

  .copyright p {
    font-size: 1.3rem ;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .footer > div {
    width: 700px;
  }
}
@media (min-width: 1001px) and (max-width: 1100px) {
  .footer > div {
    width: 900px;
  }
}
