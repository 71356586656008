.navBar {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
  margin: 0;
  padding-top: 2px;
  padding-bottom: 3px;
  background-color: #161413;
}

.navBar header {
  display: flex;
  width: 1250px;
  margin: auto;
}
.navBar header .figure .par {
  font-size: 15px;
}
/* LOGO-SETTING */

.navBar header .figure {
  display: flex;
  margin-top: -10px !important;
  cursor: pointer;
}
.navBar header .figure img {
  width: 130px;
  height: 120px;
}
.navBar header .figure .figcaption {
  color: var(--primary);
  font-size: 2.5rem;
  margin-top: 79px;
  margin-left: -50px;
}
.NavLink {
  text-decoration: none !important;
  font-weight: 600;
}

/* NAVBAR-SETTING */

nav ul {
  margin-top: 55px;
}
.loginSuccess {
  margin-right: 100px;
}
nav ul li {
  margin-top: 5px;
  margin-left: 5px;
  position: relative;
}
nav ul li::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0%;
  left: 45%;
  border-bottom: 3px solid var(--primary);
  transition: all 0.3s;
}
nav ul li:last-child {
  margin-right: 10px;
}
nav ul li span {
  border-radius: 20px;
  font-size: 1.8rem;
  color: rgb(210, 210, 210);
  transition: all 0.2s;
  transition: all 0.2s;
  padding: 2px 10px 2px 10px !important ;
}
nav ul .Navlink {
  color: rgb(210, 210, 210);
}
nav ul li:hover span {
  color: var(--primary) !important;
}
.NavLink:hover,
.activeNavBtn:hover {
  text-decoration: none;
}
.activeNav li::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 85%;
  left: 8%;
  border-bottom: 3px solid var(--primary);
}
.activeNav li span {
  color: var(--primary) !important;
}
.activeNav:hover li span {
  background-color: transparent;
}
.activeNavBtn button {
  background-color: var(--primary) !important;
}
.activeNavBtn1 button {
  font-size: 16px;
  font-weight: 600 !important;
  color: gray !important;
}
.activeNavBtn i {
  color: var(--primary);
}
nav ul button {
  padding: 4px 20px 4px 20px !important;
  margin-right: -10px;
  border-radius: 20px;
  font-size: 1.7rem;
  margin-top: 5px;
  transition: all 0.2s;
}
nav button > .NavLink {
  /* color: black; */
}
.navBar nav ul > div {
  padding-left: 10px;
}
.loginBtnForMobile {
  color: white;
  font-weight: 600;
  margin-top: 20px;
  margin-left: 28px;
  font-size: 15px;
  background-color: #161413;
}
.loginBtn {
  background-color: rgb(210, 210, 210) !important;
  color: var(--primary-act);
  margin-right: 20px;
  color: black;
  font-weight: 600;
}
nav ul button:not(.england):hover,
nav ul button:not(.england):focus {
  background-color: var(--primary) !important;
}

.england {
  position: absolute;
  top: -35px;
  left: 15px;
  background-color: transparent;
  color: rgb(210, 210, 210);
  display: flex;
  justify-content: center;
  align-items: center;
}
.england img {
  width: 40px;
  height: 20px;
  margin-right: 5px;
}
.dustbin {
  position: absolute;
  top: -38px;
  right: 40px;
  background-color: transparent;
  color: rgb(210, 210, 210);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  text-decoration: none;
}
.dustbin3 {
  margin-top: 15px;
  margin-right: 8px;
  margin-left: 43px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  text-decoration: none;
}
.dustbin3 > i {
  color: rgb(238, 238, 238);
}
.dustbinCart {
  position: absolute;
  top: 10px;
  right: -80px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.3rem;
  text-decoration: none;
}
.dustbinCart > i {
  color: rgb(238, 238, 238) !important;
}
.dustbin4,
.dustbin2 {
  position: absolute;
  top: 5px;
  right: -30px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  text-decoration: none;
}
.dustbin4 > i,
.dustbin2 > i {
  color: rgb(210, 210, 210);
}
.dustbin4:hover,
.dustbin:hover,
.dustbin2:hover {
  color: var(--primary);
  text-decoration: none;
}
.regBtn {
  color: var(--primary-act);
  background-color: rgb(210, 210, 210) !important;
}

/* MEDIA QUERY */

@media (max-width: 800px) {
  .navBar header {
    padding: 0 0 5px 0;
  }
  .navBar header .figure {
    margin-left: -20px !important;
    margin-top: -5px !important;
  }
  .navBar header .figure .figcaption {
    font-size: 1.7rem;
    margin-top: 86px;
    margin-left: -60px;
  }
  .england {
    top: -225px !important;
    left: 15px !important;
  }
}
@media (max-width: 1210px) {
  nav ul div button {
    padding: 5px 20px 5px 20px !important;
    border-radius: 20px !important;
    font-size: 1.8rem !important;
    margin-top: 10px;
  }
  .england {
    top: -230px;
    left: 0;
  }
  .navBar nav ul {
    margin-top: 60px;
  }
  nav ul li {
    width: fit-content;
    margin-bottom: 10px !important;
  }
  nav ul li span {
    width: fit-content;
  }
  .navbar-toggler {
    background-color: white;
    font-size: 1.8rem;
    margin-right: 10px;
    margin-top: 60px;
  }
}
@media (max-width: 450px) {
  .dustbin4,
.dustbin2 {
  top: 13px;
}
  .england {
    top: -150px !important;
    left: 15px !important;
  }
  .NavLink {
    text-decoration: none !important;
    font-weight: 400;
  }
  nav ul li span {
    font-size: 1.4rem;
    color: rgb(7, 7, 7);
    transition: all 0.2s;
    padding: 2px 4px 2px 4px !important ;
  }
  /* .navBar header {
    width: 270px;
    margin: 0;
  } */
  .navBar header nav {
    padding-left: 10px;
    background-color: #2a2a2a;
  }
  .navBar header .figure img {
    width: 80px;
    height: 60px;
    position: absolute;
    left: 75px;
  }
  .navBar header .figure .par {
    color: var(--primary);
    font-size: 2.2rem;
    font-weight: 500;
    margin-top: 13px;
  }

  .navBar header .figure .com {
    color: var(--primary);
    font-size: 1.43rem;
    margin-bottom: 2px;
    align-self: flex-end;
  }

  /* .navBar header .figure {
    /* margin-left: 0px !important;    
    margin-top: -5px !important; */
  /* } */
  .navBar header .figure .figcaption {
    font-size: 1.4rem;
    margin-top: 30px;
    margin-left: 0px;
  }

  .navbar-toggler {
    background-color: white;
    font-size: 1.3rem;
    margin-left: 27px;
    margin-top: 10px;
  }
  .dustbin {
    top: -215px;
    left: 20px;
  }
  .dustbin4,
  .dustbin2 {
    right: -35px;
    font-size: 2.3rem;
    text-decoration: none;
  }

  .dustbin4 > i,
  .dustbin2 > i {
    color: rgb(243, 243, 243);
  }
}

@media (max-width: 499px) and (min-width: 401px) {
  .dustbin {
    top: -215px;
    left: -60px;
  }
}
@media (min-width: 700px) and (max-width: 849px) {
  .dustbin {
    top: -215px;
    left: -390px;
  }
  .navBar header {
    width: 98%;
    margin: auto;
  }
}

@media (min-width: 850px) and (max-width: 1200px) {
  .navBar nav ul {
    padding-left: 30px !important;
  }
  .dustbin {
    top: -215px;
    left: -490px;
  }
  .navbar-toggler {
    margin-top: 55px !important;
  }
  .navBar header {
    width: 96%;
    margin: auto;
  }
}

@media (min-width: 1400px) and (max-width: 1440px) {
  .dustbin {
    right: 30px;
  }
  .navBar header {
    width: 93%;
    margin: auto;
  }
}

@media (max-width: 345px) {
  .navBar header nav {
    padding-left: 25px;
  }
  .navBar header {
    width: 280px;
    margin: 0;
  }
  .navBar header .figure img {
    width: 80px;
    height: 60px;
    position: absolute;
    left: 49px;
  }
  .navBar {
    width: 100%;
  }
  .navbar-toggler {
    margin-left: 8px;
  }
}

@media (min-width: 346px) and (max-width: 480px) {
  .navBar header nav {
    padding-left: 40px;
    background-color: #2a2a2a;
  }
  .navBar header {
    width: 310px;
    margin: 0;
  }

  .navBar header .figure img {
    width: 75px;
    height: 59px;
    position: absolute;
    left: 80px;
  }
  .dustbin4,
  .dustbin2 {
    right: -60px;
    font-size: 2.3rem;
    text-decoration: none;
  }

  .dustbin4 > i,
  .dustbin2 > i {
    color: rgb(245, 245, 245);
  }
}
@media (max-width: 376px) {
  .navBar {
    width: 100%;
  }
  .dustbin4,
  .dustbin2 {
    right: -40px;
    font-size: 2.3rem;
    text-decoration: none;
  }
  .dustbin3 {
    margin-right: 20px;
    margin-left: 30px;
  }
}
@media (max-width: 353px) {
  .dustbin4,
  .dustbin2 {
    right: -30px;
    font-size: 2.3rem;
    text-decoration: none;
  }
  .loginBtnForMobile {
    margin-left: 20px;
    margin-right: 10px;
  }
}
@media (min-width: 481px) and (max-width: 600px) {
  .navBar header {
    width: 570px;
  }
  .navBar header nav {
    padding-left: 40px;
  }
}

@media (min-width: 602px) and (max-width: 786px) {
  .navBar header {
    width: 700px;
  }
  .navBar header nav {
    padding-left: 40px;
  }
  .navbar-toggler {
    margin-top: 27px;
  }
  .loginBtnForMobile{
    margin-top: 38px;
    margin-left: 272px;
    margin-right: 52px;
    font-size: 20px;
  }
.dustbin4 {
  top: 42px;
  right: -13px;
  font-size: 3rem;
}

.dustbin2 {
  top: 31px;
  right: -12px;
  font-size: 3rem;
}
.dustbin3{
  margin-top: 38px;
  margin-left: 295px;
  margin-right: 62px;
}
  .navBar header .figure img {
    width: 110px;
    height: 95px;
    position: absolute;
    left: 85px;
  }
  .navBar header .figure .par {
    color: var(--primary);
    font-size: 3.2rem;
    font-weight: 500;
    margin-top: 38px;
  }

  .navBar header .figure .com {
    color: var(--primary);
    font-size: 2.1rem;
    margin-top: 7px;
    margin-bottom: 2px;
    align-self: flex-end;
  }
}
@media (min-width: 800px) and (max-width: 1000px) {
  .navBar header {
    width: 830px;
  }
  .navBar header nav {
    padding-left: 40px;
  }
}
@media (min-width: 1001px) and (max-width: 1100px) {
  .navBar header {
    width: 1010px;
  }
  .navBar header nav {
    padding-left: 10px;
  }
}
@media (min-width: 1101px) and (max-width: 1380px) {
  .navBar header {
    width: 1250px;
  }
  .navBar header nav {
    padding-left: 10px;
  }
}
.select-lang {
  background-color: transparent;
  color: rgb(210, 210, 210);
  border: none;
  font-size: 20px;
  cursor: default;
  font-weight: 600;
}
.select-lang option {
  background-color: black;
}
