.logIn {
  padding-bottom: 60px;
}

.form-modal {
  position: relative;
  width: 400px;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0 3px 20px 0px rgba(60, 237, 183, 0.2);
}
.form-modal button {
  cursor: pointer;
  position: relative;
  text-transform: capitalize;
  font-size: 4rem;
  z-index: 2;
  outline: none;
  background: #fff;
  transition: 0.2s;
}

.form-modal .btn {
  border-radius: 20px;
  border: none;
  font-size: 1.6rem;
  padding: 0.5em 1em 0.5em 1em !important;
  transition: 0.5s;
  border: 1px solid #ebebeb;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}

.form-modal .login {
  background: var(--primary);
  color: black;
}

.form-modal .login:hover {
  background: #222;
  color: white;
}

.form-toggle {
  position: relative;
  width: 100%;
  height: auto;
}

.form-toggle button {
  width: 100%;
  float: left;
  padding: 1.2rem;
  margin-bottom: 1.5em;
  border: none;
  transition: 0.2s;
  font-size: 3rem;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

#login-toggle {
  background: var(--primary);
  color: black;
}

.form-modal form {
  position: relative;
  width: 90%;
  height: auto;
  left: 50%;
  top: -20px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
}

#login-form {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 1em;
}

#login-form button {
  width: 100%;
  margin-top: 0.5em;
  padding: 0.6em;
}

.form-modal input {
  position: relative;
  width: 100%;
  margin-top: 0.6em;
  margin-bottom: 0.6em;
  border-radius: 20px;
  border: none;
  background: #ebebeb;
  outline: none;
  transition: 0.4s;
  padding-left: 18px;
}

.form-modal input {
  font-size: 1.5rem;
}
.form-modal input::-webkit-input-placeholder {
  color: #222;
  font-size: 1.7rem;
}

.logIn .form-modal p {
  font-size: 1.6rem;
  padding-left: 20px;
}
.logIn .form-modal p a {
  color: black;
  text-decoration: none;
  transition: 0.2s;
}

.logIn .form-modal p a:hover {
  color: var(--primary);
}

.fa-google {
  color: #dd4b39;
}

.box-sd-effect .fa-facebook-square {
  color: #0072c6;
}

.box-sd-effect:hover {
  box-shadow: 0 10px 5px 0px rgba(60, 237, 183, 0.2);
  transform: scale(1.06);
}
.login {
  font-weight: bold;
}

@media only screen and (max-width: 500px) {
  .logIn {
    padding-bottom: 60px;
  }
  .form-modal {
    width: 80%;
  }
  #login-toggle {
    font-size: 3rem;
  }
  input {
    margin: 0;
  }
  #login-form {
    padding: 0;
  }
  .box-sd-effect i {
    padding-left: -10px !important;
  }
  .box-sd-effect {
    font-size: 1.4em !important;
  }
}
