.payment-options {
  display: flex;
  gap: 50px;
  margin: 0 30px;
}

.payment-option input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.payment-option .payment-option-label {
  width: 110px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  color: black;
  background-color: rgb(210, 210, 210);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
}

.payment-option .payment-option-label:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.payment-option .payment-option-label:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #999;
  transition: all 0.2s ease-in-out;
}

.payment-option input[type="radio"]:checked+.payment-option-label:before {
  background-color: rgb(0, 255, 234);
  border-color: rgb(0, 255, 234);
}

.payment-option input[type="radio"]:checked+.payment-option-label {
  background-color: rgb(0, 255, 234);
}

.payment-option input[type="radio"]:focus+.payment-option-label:before {
  outline: 2px solid rgb(0, 255, 234);
}

@media (max-width:444px){
  .title-payment{
    font-size: 17px;
   }
}
