.page-giftshopCon {
  padding-top: 1px;
  width: 1070px;
  margin: auto;
  margin-top: 50px;
  border-bottom: 1px solid white;
}
.page-giftshopCon h1 {
  align-self: center;
  font-size: 5rem;
  font-weight: 200 !important;
  color: var(--primary);
}
.page-giftshop {
  width: 100%;
  min-height: 250px;
  border-top: 1px solid #d2d2d2;
  /* margin-top: 20px; */
  display: flex;
  align-items: flex-start;
}
.page-giftshop .video{
  width: 272px;
  height: 204px
}
.page-giftshop > div {
  min-height: 250px;
}
.page-giftshop > div:nth-child(1) {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.expand-content {
  overflow: hidden;
  /* text-overflow:inherit; */
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.gift-shop-modal .modal-content {
  height: 350px !important;
  overflow-y: scroll !important;
  background-color: #2a2a2a;
  color: #d2d2d2;
}
.gift-shop-modal .modal-content .modal-body p {
  font-size: 14px;
}



video {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 85%;
  aspect-ratio: 13 / 8;
  object-fit: cover;
}
.page-giftshop > div:nth-child(2) {
  width: 69%;
  /* padding-top: 30px; */
}
.page-giftshop > div:nth-child(1) > figure {
  width: 80%;
  /* height: 210px; */
  margin: 0;
  background-position: center;
  background-size: cover;
  aspect-ratio: 1.33/1;
}
.page-giftshop > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #d2d2d2;
  position: relative;
}
.page-giftshop > div:nth-child(2) > h1 {
  font-size: 3rem;
  align-self: flex-start;
}
.page-giftshop > div:nth-child(2) > h2 {
  margin: 0;
  font-size: 3rem;
}
.page-giftshop > div:nth-child(2) > p {
  font-size: 1.8rem;
}
.page-soldout {
  position: absolute;
  left: -15px;
  bottom: 100px;
  width: 50%;
  height: 50px;
  background-color: var(--primary);
  transform: rotate(-20deg);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.1rem;
  color: black;
}

.addCartbtn,
.addCart {
  padding: 5px 20px;
  align-self: flex-end;
  border-radius: 20px;
  font-size: 1.7rem;
  background-color: rgb(210, 210, 210);
  font-weight: 600;
  cursor: pointer;
}

.addCartbtn{
 margin-top: 65px;
 margin-left: 574px;
}

.addCartbtn:hover,
.addCart:hover {
  background-color: var(--primary) !important;
}

.addCartbtn:focus,
.addCart:focus {
  outline: none;
  background-color: rgb(180, 180, 180) !important; 
}

.addCartbtn:active, 
.addCart:active {
  background-color: var(--secondary) !important;
}


@media (max-width: 600px) {
  .page-giftshopCon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .page-giftshop {
    display: flex;
    flex-direction: column;
  }
  .page-giftshop > div {
    width: 100% !important;
    align-items: center;
  }
}

@media (max-width: 345px) {
  .page-giftshop .video{
    width: 240px;
    height: 180px
  }
  .page-giftshopCon {
    width: 270px;
    margin-top: 15px;

  }
  .page-giftshop {
    min-height: 600px;
  }
  .page-giftshop > div:nth-child(2) {
    min-height: 310px;
  }
  video {
    width: 100%;
  }
  .page-giftshop > div:nth-child(1) > figure {
    width: 100%;
    /* height: 170px; */
    margin: 0;
  }
  .page-giftshop > div:nth-child(2) h2 {
    font-size: 2.5rem;
    text-align-last: left;
  }

  .page-giftshop > div:nth-child(2) p {
    font-size: 1.5rem;
    width: 275px;
  }
  .page-giftshop > div:nth-child(2) button {
    margin-top: 30px;
    align-self: center;
  }
  .page-soldout {
    width: 100%;
    font-size: 1.7rem;
    left: 6px;
    bottom: 110px;
    height: 40px;
  }
  .addCartbtn{
    margin-left: 60px;
   }
}

@media (min-width: 346px) and (max-width: 480px) {
  .page-giftshopCon {
    width: 310px;
    margin-top: 15px;
  }
  .page-giftshop {
    min-height: 550px;
  }
  .page-giftshop > div:nth-child(2) {
    min-height: 310px;
  }
  video {
    width: 100%;
  }
  .page-giftshop > div:nth-child(1) > figure {
    width: 95%;
    /* height: 190px; */
    margin: 0;
  }
  .page-giftshop > div:nth-child(2) h2 {
    font-size: 2.5rem;
  }
  .page-giftshop .video{
    width: 280px;
    height: 210px
  }

  .page-giftshop > div:nth-child(2) p {
    font-size: 1.5rem;
    width: 309px;
  }
  .page-giftshop > div:nth-child(2) button {
    margin-top: 30px;
  }
  .page-soldout {
    width: 100%;
    font-size: 1.7rem;
    left: 6px;
    bottom: 110px;
    height: 40px;
  }
  .addCartbtn{
    margin-left: 146px;
   }
}

@media (min-width: 481px) and (max-width: 600px) {
  .page-giftshopCon {
    width: 500px;
  }

  .page-giftshop > div:nth-child(2) {
    min-height: 410px;
  }
  .page-giftshop > div:nth-child(1) > figure {
    width: 70%;
    /* height: 250px; */
    margin: 0;
  }
  video {
    width: 100%;
  }
  .page-giftshop > div:nth-child(2) h2 {
    font-size: 2.5rem;
  }

  .page-giftshop > div:nth-child(2) p {
    font-size: 1.5rem;
  }
  .page-giftshop > div:nth-child(2) button {
    margin-top: 30px;
  }
  .page-soldout {
    width: 60%;
    font-size: 1.7rem;
    left: 130px;
    bottom: 170px;
    height: 40px;
  }
}

@media (min-width: 601px) and (max-width: 800px) {
  .page-giftshopCon {
    width: 650px;
  }
  .page-giftshop > div:nth-child(1) {
    width: 100%;
    height: 250px;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
  }
  video {
    width: 80%;
  }
  .page-giftshop > div:nth-child(2) {
    padding-left: 20px;
    padding-top: 45px;
  }
  .page-giftshop > div:nth-child(1) > figure {
    margin-top: 35px;
    width: 100%;
    /* height: 85%; */
  }
  .page-giftshop > div:nth-child(2) h2 {
    font-size: 3rem;
  }
  .page-giftshop > div:nth-child(2) p {
    font-size: 2rem;
  }
  .page-giftshop > div:nth-child(2) button {
    margin-top: 30px;
  }
  .page-soldout {
    width: 100%;
    font-size: 1.9rem;
    left: 10px;
    bottom: 140px;
    height: 40px;
  }
}

@media (min-width: 801px) and (max-width: 900px) {
  .page-giftshopCon {
    width: 650px;
  }
  .page-giftshop > div:nth-child(1) {
    width: 50%;
    justify-content: center;
    align-items: center;
  }
  .page-giftshop > div:nth-child(2) {
    padding-left: 20px;
  }
  .page-giftshop > div:nth-child(1) > figure {
    width: 100%;
    /* height: 75%; */
  }
  .page-giftshop > div:nth-child(2) h2 {
    font-size: 3rem;
  }
  .page-giftshop > div:nth-child(2) p {
    font-size: 2rem;
  }
  .page-giftshop > div:nth-child(2) button {
    margin-top: 30px;
  }
  .page-soldout {
    width: 100%;
    font-size: 1.9rem;
    left: 10px;
    bottom: 140px;
    height: 40px;
  }
}
@media (min-width: 801px) and (max-width: 1000px) {
  .page-giftshopCon {
    width: 700px;
  }
  .page-giftshop > div:nth-child(1) {
    width: 50%;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 1001px) and (max-width: 1100px) {
  .page-giftshopCon {
    width: 900px;
  }
  .page-soldout {
    width: 60%;
    font-size: 1.7rem;
    left: -15px;
    bottom: 100px;
    height: 40px;
  }
  .page-giftshop > div:nth-child(1) {
    width: 50%;
    justify-content: center;
    align-items: center;
  }
  .addCartbtn{
    margin-top: 40px;
    margin-left: 355px;
   }
}
@media scerrn and (min-width: 1102px) {
  .page-giftshop > div:nth-child(1) {
    width: 50%;
    justify-content: center;
    align-items: center;
  }

}
