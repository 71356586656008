.FAQ {
  /* width: 85.5%; */
  width: 1070px;
  margin: auto;
  margin-top: 80px;
  padding-left: 40px;
  padding-bottom: 80px;
}
.FAQ h1 {
  font-size: 5rem;
  color: var(--primary);
}
.FAQ > div {
  width: 80% !important;
}
.FAQ > div h5 {
  font-size: 2.3rem;
  color: rgb(210,210,210);
}
.FAQ .container {
  margin-top: 50px !important;
}
.card .card-header {
  background-color: transparent;
  padding: 10px;
}
.card-header a:hover {
  text-decoration: none;
}
.card .card-header a {
  display: flex;
  justify-content: space-between;
}
.card-header a i {
  font-size: 3rem;
  color: rgb(210,210,210);
}
.FAQ .card {
  background-color: transparent;
  border: none;
  background-color: none;
  border-bottom: 1px solid rgb(90,90,90);
}
.card-body p {
  font-size: 1.7rem;
  color: rgb(210,210,210);
}
[data-toggle="collapse"][aria-expanded="true"]  .rotate-icon {
  display: inline-block;
  transition: transform 0s ease;
  transform: rotate(180deg);
}
@media (max-width: 900px) {
  .FAQ {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
    margin-top: 20px;
  }
  .FAQ h1 {
    font-size: 3rem;
  }
  .FAQ > div {
    width: 100% !important;
  }
  .FAQ > div h5 {
    font-size: 1.6rem;
  }
  .FAQ .container {
    margin-top: 30px !important;
  }
  .card-body p {
    font-size: 1.5rem;
  }
}

@media (min-width:346px) and (max-width:480px)
{
  .FAQ
  {
    margin: 20px 15px;
    width: 370px;
  }
}

@media (max-width:384px){
  .FAQ
  {
    width: 340px;
  }
}
@media (max-width:345px)
{
  .FAQ
  {
    margin: 20px 5px;
    width: 300px;
  }
}
@media (min-width:481px) and (max-width:600px)
{
  .FAQ
  {
    width: 500px;
  }
}
@media (min-width:601px) and (max-width:786px)
{
  .FAQ
  {
    width: 650px;
  }
}
@media (min-width:800px) and (max-width:1000px)
{
  .FAQ
  {
    width: 700px;
  }
}
@media (min-width:1001px) and (max-width:1100px)
{
  .FAQ
  {
    width: 900px;
  }
}