.signUp {
  padding-bottom: 60px;
}

.form-modal {
  position: relative;
  width: 400px;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0 3px 20px 0px rgba(60, 237, 183, 0.2);
}
.form-modal button {
  cursor: pointer;
  position: relative;
  text-transform: capitalize;
  font-size: 4rem;
  z-index: 2;
  outline: none;
  background: #fff;
  transition: 0.2s;
}
.btnGroup {
  display: flex;
  justify-content: flex-start;
}
.form-modal .btn {
  border-radius: 20px;
  border: none;
  font-size: 1.6rem;
  transition: 0.5s;
  border: 1px solid #ebebeb;
}
.btnGroup > .btn {
  width: 48% !important;
  padding: 0 !important;
}
.btnGroup > .btn:nth-child(2) {
  margin-left: 10px;
}
.form-modal .signup {
  background: var(--primary);
  color: black;
}

.form-modal .signup:hover {
  background: #222 !important;
  color: white;
}

.form-toggle {
  position: relative;
  width: 100%;
  height: auto;
}
.form-toggle button {
  width: 100%;
  float: left;
  padding: 0.2rem;
  margin-bottom: 1em;
  border: none;
  transition: 0.2s;
  font-size: 3rem;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

#signup-toggle {
  background: var(--primary);
  color: black;
}

.form-modal form {
  position: relative;
  width: 90%;
  height: auto;
  left: 50%;
  top: -30px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
}
.form-modal input {
  position: relative;
  width: 100%;
  padding: 0.5em 0.5em 0.5em 0.5em;
  margin-top: 0em;
  margin-bottom: 0.3em;
  border-radius: 20px;
  border: none;
  background: #ebebeb;
  outline: none;
  transition: 0.4s;
}
.form-modal input:hover {
  box-shadow: 0 10px 5px 0px rgba(60, 237, 183, 0.2);
  transform: scale(1.06);
}
.form-modal input {
  font-size: 1.5rem;
}
.form-modal input::-webkit-input-placeholder {
  color: #222;
  font-size: 1.5rem;
}

.rc-code {
  width: 100% !important;
}

.signUp .form-modal p {
  font-size: 1.6rem;
  padding-left: 17px;
}

.signUp .form-modal p a {
  text-decoration: none;
  color: black;
  font-size: 1.6rem;
  transition: 0.2s;
}

.signUp .form-modal p a:hover {
  color: black;
}

.form-modal i {
  margin-left: 12px;
  margin-right: 10px;
  font-size: 3rem;
}
.signUp .fa-facebook-square {
  color: #0072c6;
}

.box-sd-effect:hover {
  box-shadow: 0 10px 5px 0px rgba(60, 237, 183, 0.2);
  transform: scale(1.06);
}
.createacnt {
  font-weight: bold;
}
.btnGroup > button:nth-child(2),
.btnGroup > button:nth-child(1) {
  display: flex;
  align-items: center;
  padding: 0px !important;
}
.btnGroup > button:nth-child(1) > img {
  margin-right: 10px;
  width: 15%;
  height: 80%;
  margin-left: 12px;
}

.password-input {
  position: relative;
  display: inline-block;
}

.password-input i {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 2.2rem;
}

@media only screen and (max-width: 350px) {
  .signUp .form-toggle button {
    font-size: 2.4rem;
  }
  #signup-form p {
    font-size: 1.7rem;
  }
  .box-sd-effect i {
    padding-left: -10px !important;
    margin-right: 20px !important;
  }
  .box-sd-effect {
    font-size: 1.4em !important;
  }

  .form-modal i {
    margin-right: 5px !important;
  }
}
