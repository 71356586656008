/* @import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/css/flag-icon.min.css'); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
:root {
  --primary: rgb(0, 255, 234);
  --secondary: aqua;
  --primary-act: #161413;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family: "Poppins", sans-serif;
}
.skiptranslate iframe {
  display: none !important;
}
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  font-family: var(--font-family);
}
::before,
::after {
  box-sizing: border-box;
}
a {
  text-decoration: none !important;
}
button,
button:focus,
li:focus {
  border: none;
  outline: none;
}
html {
  font-size: 62.5%;
}

body {
  font-family: var(--font-family);
  background-color: var(--primary-act);
}
.CookieConsent {
  width: 100%;
  height: 55vh;
  background: rgba(0, 255, 234, 0.7) !important;
  z-index: 90;
  display: flex !important;
  flex-direction: column;
  justify-content: flex-end !important;
  align-items: center !important;
  transition: all 1s;
}
.CookieConsent div {
  margin-bottom: 30px;
}
.CookieConsent div:nth-child(1) {
  flex: 0 0 0px;
  display: flex;
  justify-content: center;
  align-items: center !important;
  margin: 0 !important;
}
.CookieConsent div:nth-child(2) button {
  margin: 0 !important;
}

.CookieConsent button {
  padding: 3px 20px 3px 20px !important;
  background-color: white !important;
  border-radius: 30px !important;
  cursor: pointer !important;
  font-size: 20px;
}
.CookieConsentbutton:hover {
  background-color: var(--primary);
}
.CookieConsent p {
  color: white;
  margin-bottom: 10px;
  font-size: 1.8rem;
}
.CookieConsent p .cookiesLink {
  font-size: 1.8rem;
  color: white;
  text-decoration: underline;
}
.popUpDiv {
  width: 400px;
  position: fixed;
  bottom: 20px;
  left: 2%;
  z-index: 200;
  opacity: 0;
  transition: opacity 1s;
  background-color: rgb(90, 90, 90);
  color: white;
  border-radius: 10px;
}

.popUpDiv.active {
  opacity: 1;
}
.popUpDiv {
  display: none; /* Make sure it's not visible or clickable */
}

.popUpDiv.active {
  display: block;
}
.popUpDiv > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.popUpDiv > div:nth-child(1) > img {
  width: 100%;
  height: 75%;
}
.popUpDiv > div > h1 {
  font-size: 1.6rem;
}
.popUpDiv > div > p {
  font-size: 1.5rem;
}
.popUpDiv > div:nth-child(2) {
  position: relative;
}
.popUpDiv > div:nth-child(2) > i {
  position: absolute;
  right: 10px;
  top: -10px;
  font-size: 2rem;
  cursor: pointer;
}
@media (min-width: 300px) and (max-width: 1100px) {
  .CookieConsent {
    height: 53vh;
  }
}
@media (min-width: 300px) and (max-width: 1100px) {
  .CookieConsent p {
    margin: 30px;
  }
}
@media (max-width: 800px) {
  .popUpDiv {
    width: 290px !important;
  }
  .popUpDiv > div:nth-child(1) > img {
    height: 90%;
  }
  .popUpDiv > div > h1 {
    font-size: 1.3rem;
  }
  .popUpDiv > div > p {
    font-size: 1.2rem;
  }
}

@media (min-width: 600px) and (max-width: 800px) {
  .popUpDiv {
    width: 420px !important;
    /* left: 15%; */
  }
  .popUpDiv > div:nth-child(1) > img {
    height: 90%;
  }
  .popUpDiv > div > h1 {
    font-size: 2rem;
  }
  .popUpDiv > div > p {
    font-size: 1.9rem;
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .popUpDiv {
    /* left: 10%; */
  }
  .popUpDiv > div:nth-child(1) > img {
    height: 90%;
  }
}

@media (min-width: 1280px) {
  .popUpDiv {
    /* left: 10%; */
  }
  .popUpDiv > div:nth-child(1) > img {
    height: 90%;
  }
}
