.stickyBar {
  position: -webkit-sticky; 
  position: sticky;
  top: 120px; 
  z-index: 999;
  background-color: #161413;
}

.stickBar{
  position: -webkit-sticky;
  position: sticky;
  top: 116px;
  padding-top: 5px;
  z-index: 999;
  background-color: #161413;
}

.instantPrice .row {
   height: 0px;
  overflow: hidden;
  row-gap: "30px";
  transition: all 0.3s; 
}

/* .instantPrice .row .show::-webkit-scrollbar {
  height: 100px;
  width: 10px;
}
.instantPrice .row .show::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.instantPrice .row .show::-webkit-scrollbar-thumb {
  background: #888;
}
.instantPrice .row .show::-webkit-scrollbar-thumb:hover {
  background: #555;
} */


/* Ticket Counter */
.ticket-counter {
  display: flex;
  align-items: center;
}

.lucky-dip-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #2a2a2a;
  padding: 10px 15px;
  border-radius: 10px;
  max-width: 500px;
  margin: 0 auto;
}
.counter-number {
  font-size: 22px;
  border: 1px solid #00ffea;
  padding: 5px 10px;
  font-weight: bold;
  border-radius: 4px;
  background-color: #202020;
  color: #00ffea;
  display: flex;
  align-self: center;
  cursor: pointer;
  user-select: none;
}

.counter-number:hover {
  border: 1px solid #00d7c5;
  background-color: #3c3c3c;
  color: #00efdb;
}

.lucky-dip-slider {
  width: 20vw;
  margin: 0 20px;
  cursor: pointer;
  -webkit-appearance: none;
  height: 4px;
  background: linear-gradient(to right, #00d7c5 0%, #00d7c5 0%, #464646 0%);
  background: #464646;
  outline: none;
  border-radius: 5px;
}

.lucky-dip-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #00d7c5;
  cursor: pointer;
}

.lucky-dip-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}

.ticket-limit-text {
  font-size:14px;
  color: gold;
}

.progress-container {
  display: flex;
  min-width: 1070px;
  width: 100%;
  height: 50px;
  font-family: Arial, sans-serif;
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
position:"relative";
}

.available {
  width:65%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgb(0 188 173) 0, #00ffe9 100%);
  color: black;
  font-size: 18px;
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
  font-weight: 500;
}

.won {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #ffd700 0, #ffb20a 100%);
  width:35%;
  right:0rem;
  bottom:6rem;
  color: black;
  clip-path: polygon(17% 0, 100% 0, 100% 100%, 0 100%);
  font-size: 18px;
  font-weight: 500;
}

.progress-text {
  display: block;
  text-align: center;
}

.progress-text span {
  display: block;
  font-size: 12px;
  color: black;
}


.instantPrice .row.show {
  height: fit-content;
  /* overflow-y: scroll;  */

}

.instantPrice .item-wrapper {
  overflow: hidden;
  margin-left: 5px;
  
}

.instantPrice .items {
  min-height: 182px;
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background-position: center;
  background-size: cover;
  transition: all 0.5s;
  align-items: center;
  overflow: hidden;
  margin-bottom: 8px;
}

.instantPrice .items:hover {
  margin-bottom: 10px;
  transform: scale(1.03);
}

.instantPrice .ticket-no,
.instantPrice .not-won {
  border-radius: 50px;
  width: 100%;
  height: 21.27px;
  background-color: rgb(90, 90, 90);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  cursor: pointer;
  transition: all 0.3s;
  color: rgb(210, 210, 210);
}

.instantPrice .not-won {
  width: 100px;
  background-color: transparent;
  border: 2px solid var(--primary);
  color: var(--primary);
}

.instantPrice .items:hover .not-won,
.instantPrice .items:hover .ticket-no {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

.instantPrice p {
  margin-top: 15px;
}

.instantPrice hr,
.tickets hr {
  margin-top: 0;
  width: 100%;
  background-color: gray;
}
.discriptionSection span,
.ticketsSection span,
.priceSection span {
  
  cursor: pointer;
  font-size: 4.9rem;
  margin-right: 2rem;
  left: 1px;
  bottom: 66px;
  position: absolute;
  width: 4.2rem; 
  text-align: center; 
}

.ticketsSection span{
  bottom:8px;
}
.discriptionSection h1{
  margin-left: 0rem;
}
.discriptionSection span{
  bottom: -4px;
}

/* .instantPrice .seeAll,
.tickets .seeAll {
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 5px 5px 5px 0px ;
  background-color: #161413;
  border: none;
  outline: none;
  color: rgb(210, 210, 210);
  transition: all 0.3s;
}
.instantPrice .seeAll{
  display: flex;
  align-items: center;
  gap: 30px;
  margin-left:470px ;
  padding: 5px 5px 5px 0px ;
  background-color: #161413;
  border: none;
  outline: none;
  color: rgb(210, 210, 210);
  transition: all 0.3s;
}
.tickets .seeAll {
  width: 100%;
  justify-content: flex-end;
}

.instantPrice .seeAll label,
.tickets .seeAll label {
  margin-bottom: 0;
  font-size: 18px;
  cursor: pointer;
}

.instantPrice .seeAll i,
.tickets .seeAll i {
  font-size: 35px;
  transform: rotate(-90deg);
  position: relative;
  bottom: 2px;
  transition: all 0.3s;
  cursor: pointer;
} */
.instantPrice .stickBar .hrLine{
  width:1070px;

}
.instantPrice .seeAll.less i,
.tickets .seeAll.less i {
  font-size: 35px;
  transform: rotate(90deg);
  position: relative;
  bottom: 2px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
}

.instantPrice .see-all {
  margin-left: auto;
}
@media (min-width: 1800px) {
  .lucky-dip-slider {
    width: 13vw;
  }
}
@media (max-width:1101px){
  .progress-container{
    min-width:900px;
  }
}
@media (max-width: 776px) {
  .instantPrice .seeAll,
  .tickets .seeAll {
    justify-content: flex-end;
  }
  .progress-container{
    min-width:650px;
  }
}

@media (max-width: 991px) {
  .instantPrice .row {
    height: 0px;
    overflow: hidden;
    transition: all 0.3s;
  }
}

@media (max-width: 575px) {
  .lucky-dip-container{
    padding: 10px 10px;
  }
  .counter-number {
    font-size: 16px;
    padding: 3px 8px;
  }
  
  .lucky-dip-slider {
    width: 30vw;
    margin: 0 15px;
    margin-bottom: 5px;
  }
  
  
  .progress-container{
    min-width:320px;
  }

  .available {
    clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%);
    font-size: 15px;
  }
  
  .won {
    clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%);
    font-size: 15px;
  }
  .progress-text span {
    font-size: 10px;
  }
  
  .instantPrice .seeAll label,
  .tickets .seeAll label {
    font-size: 16px;
  }
  .instantPrice .seeAll {
margin-left: 0;
  }
  .instantPrice .seeAll i,
.tickets .seeAll i {
  font-size: 25px;
}
  .instantPrice .row {
    height: 0px;
    overflow: hidden;
    transition: all 0.3s;
  }
  .instantPrice .seeAll.less i,
.tickets .seeAll.less i {
  font-size: 25px;
}
.stickyBar {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  padding-top: 3px;
  top: 56px; 
  padding-top: 3px;
  z-index: 999;
  text-align: center;
}

.stickBar{
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 56px;
  z-index: 999;
  background-color: #161413;
}

.discriptionSection span,
.ticketsSection span,
.priceSection span {
  left: 1px;
  font-size:4.4rem ;
  bottom: 62px;
}
  
.priceSection span {
  left: 3px;
}
  
.ticketsSection span{
  bottom: 4px;
}
.discriptionSection span{
  bottom: -7px;
}
}

@media (max-width: 324px) {
  .progress-container{
    min-width:280px;
  }
  .instantPrice .seeAll label,
  .tickets .seeAll label {
    font-size: 15px;
    
  }

  .instantPrice .seeAll i,
.tickets .seeAll i {
  font-size: 20px;
}
.instantPrice .seeAll.less i,
.tickets .seeAll.less i {
  font-size: 25px;
}
.stickyBar {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 56px; /* Adjust this value to be the height of the navbar */
  z-index: 999;
  text-align: center;
}

.stickBar{
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 56px;
  z-index: 999;
  background-color: #161413;
}
}
