.title{
    color: rgb(217, 217, 217) !important;
    margin-left: 48px;
    margin-top: 22px;
    white-space: nowrap;
}
@media(max-width:376px){
  .title{
    margin-left: 25px;
}
}
.btn-design {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-left: 0px;
}
.home-section-2 {
  width: 1070px;
  margin: auto;
  margin-top: 50px;
}
.undisableClass {
  pointer-events: auto;
  cursor: pointer;
}
.home-section-2 > h1 {
  font-size: 5rem;
  color: var(--primary);
}

.home-section-2 .items-link {
  text-decoration: none;
}
.home-section-2 .items-container {
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.home-section-2 .items {
  width: 22.8%;
  height: 260px;
  margin-top: 30px;
  margin-right: 2.2%;
}

.home-section-2 .items .items-link > div {
  position: relative;
  overflow: hidden;
}
.items .items-link > div > div:hover {
  transform: scale(1.07);
}
.home-section-2 .items .items-link > div > div:hover .sold-out {
  width: 90%;
  transform: rotate(0deg);
}
.home-section-2 .items .items-link > div > div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  background-position: center;
  background-size: cover;
  transition: all 0.5s;
  align-items: center;
}

.home-section-2 .sold-out {
  width: 95%;
  height: 30px;
  background-color: var(--primary);
  transform: rotate(-19deg);
  text-align: center;
  margin: auto;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.3s;
  color: black;
  font-weight: 700;
}
/* .progress {
  border: 2px solid var(--primary);
  width: 100%;
  height: 3vh;
  border-radius: 50px;
  position: relative;
  background-color: transparent;
  margin-bottom: 15px;
}
.progress-bar {
  background-color: var(--primary);
} */
/* .home-section-2 .items-colorfull {
	width: 90%;
	height: 20px;
	border-radius: 10px;
	border: 2px solid var(--primary);
	margin-bottom: 15px;
	position: relative;
}
.home-section-2 .progress-bar {
	height: 100%;
	position: absolute;
	background-color: var(--primary);
	border-radius: 10px;
} */

.home-section-2 .items .items-link > div {
  width: 100%;
  height: 70%;
}
.home-section-2 .items h3 {
  font-size: 1.6rem;
  color: rgb(210, 210, 210);
  margin-top: 10px;
  padding-left: 10px;
}
.home-section-2 .items h1 {
  padding-left: 10px;
  font-size: 2.7rem;
  color: var(--primary);
}

@media (max-width: 345px) {
  .home-section-2 {
    width: 270px;
  }
  .home-section-2 > h1 {
    text-align: center;
    font-size: 3rem;
  }
  .home-section-2 .items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .home-section-2 .items {
    width: 100%;
    margin-right: 0;
  }
}

@media (min-width: 346px) and (max-width: 480px) {
  .home-section-2 {
    width: 300px;
  }
  .home-section-2 > h1 {
    text-align: center;
    font-size: 3rem;
  }
  .home-section-2 .items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .home-section-2 .items {
    width: 90%;
    margin-right: 0;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .home-section-2 {
    width: 500px;
  }
  .home-section-2 > h1 {
    text-align: center;
    font-size: 3rem;
  }
  .home-section-2 .items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .home-section-2 .items {
    width: 45%;
    height: 230px;
    margin-right: 0;
  }
}

@media (min-width: 601px) and (max-width: 786px) {
  .home-section-2 {
    width: 650px;
  }
  .home-section-2 > h1 {
    text-align: start;
    font-size: 3rem;
  }
  .home-section-2 .items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .home-section-2 .items {
    width: 41%;
    margin-right: 0;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .main-section .home-section-2 {
    width: 700px;
  }
  .main-section .items-container {
    justify-content: space-between;
  }
  .home-section-2 .items {
    width: 30%;
    height: 230px;
    margin-right: 0;
  }
}

@media (min-width: 1001px) and (max-width: 1100px) {
  .main-section .home-section-2 {
    width: 900px;
  }
  .home-section-2 .items-container {
    justify-content: space-between;
  }
  .home-section-2 .items {
    width: 30%;
    height: 265px;
    margin-right: 0;
  }
}
