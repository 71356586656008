.overlay{
    background:rgba(0,0,0,.6);
    position:fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}
.overlay-content{
    color: #fff;
    font-size:2rem;
}