.h-giftshop {
  width: 1070px;
  margin: auto;
  margin-top: 50px;
}
.h-giftshop > h1 {
  font-size: 5rem;
  color: var(--primary);
}
.h-giftshop > div {
  min-height: 220px;
  border: 1px solid rgb(210, 210, 210);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 50px;
  margin-bottom: 50px;
}
.h-giftshop > div > div {
  width: 22%;
  height: 200px;
  text-align: center;
}
.h-giftshop > div figure {
  width: 235px;
  height: 177px;
  height: 100%;
  background-size: cover;
  background-position: center;
}
.h-giftshop > div video,
.h-giftshop > div video source {
  width: 100%;
  height: 100%;
}
.h-giftshop > div > div:hover {
  transform: scale(1.03);
}

@media (min-width: 0px) and (max-width: 800px) {
  .h-giftshop {
    width: 90%;
    margin-top: 40px;
  }
  .h-giftshop > h1 {
    font-size: 3rem;
  }

  .h-giftshop > div > div {
    margin-bottom: 30px;
    margin-top: 30px;
  }
}

@media (max-width: 345px) {
  .h-giftshop {
    width: 270px;
  }
  .h-giftshop > h1 {
    text-align: center;
  }
  .h-giftshop > div > div {
    width: 95%;
  }
  .h-giftshop > div {
    flex-direction: column;
  }
  .h-giftshop > div video,
  .h-giftshop > div figure {
    width: 255px;
    height: 191px;
  }
}

@media (min-width: 346px) and (max-width: 480px) {
  .h-giftshop {
    width: 310px;
    text-align: center !important;
  }
  .h-giftshop > div > div {
    width: 95%;
  }
  .h-giftshop > div {
    flex-direction: column;
  }
  .h-giftshop > div video,
   .h-giftshop > div figure {
    width: 293px;
    height: 220px;
  }
} 

@media (min-width: 481px) and (max-width: 600px) {
  .h-giftshop {
    width: 500px;
  }
  .h-giftshop > div > div {
    width: 45%;
  }
}

@media (min-width: 601px) and (max-width: 786px) {
  .h-giftshop {
    width: 650px;
  }
  .h-giftshop > div > div {
    width: 45%;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .h-giftshop {
    width: 700px;
  }
  .h-giftshop > div > div {
    width: 30%;
    height: 150px;
  }
}

@media (min-width: 1001px) and (max-width: 1100px) {
  .h-giftshop {
    width: 900px;
  }
  .h-giftshop > div > div {
    width: 30%;
    height: 190px;
  }
}
