.stickyBar{
	position: -webkit-sticky;
	position: sticky;
	top: 120px; 
	z-index: 999;
	background-color: #161413;
}

.section-2 {
	width: 1070px;
	margin: 0 auto;
	margin-top: 50px;
}

            
.section-2 .fortune {
	position: relative;
	font-weight: 200;
	font-size:3.5rem;
	margin-top:-34px;
	margin-left: 5.1rem;
	cursor: pointer;
	color: var(--primary);
}
.section-2 hr{
	margin-top: 0;
	margin-left: -6px;
	width: 100%;
	background-color: gray;
}
.section-2 .fortune span {
	cursor: pointer;
	position: absolute;
	font-size: 4.9rem;
	font-weight: 200;
	bottom: -10px;
	left:-53px;
	width: 4.9rem; 
	color: var(--primary);
	text-align: center; 
}

Video {
	position: relative;
	z-index: 1;
}

.video_uppar_text {
	position: absolute;
	top: 30%;
	left: 35%;
	transform: translate(-50%, 0);
	z-index: 2;
	color: white;
	font-size: 30px;
}

.eCard {
	width: 100%;
	min-height: 320px;
	display: flex;
	align-items: center;
}

.eCard>div {
	width: 30%;
	min-height: 230px;
}

.eCard>div>video {
	width: 300px;
	height: 226px;
}

.eCard>div:nth-child(1)>h1 {
	font-size: 3rem;
	color: var(--primary);
	text-align: center;
}

.eCard>div:nth-child(2) {
	margin-left: 10px;
	color: rgb(290, 290, 290);
	display: flex;
	flex-direction: column;
}

.eCard>div:nth-child(2) p {
	font-size: 1.8rem;
	margin-bottom: 20px;
}

@media (min-width: 0px) and (max-width: 800px) {
	

	.section-2 hr{
		margin-left: -6px;
		width: 103%;
	}

	/* .section-2 h1 {
		text-align: center;
		padding-bottom: 15px;
		font-size: 3.4rem;
		font-weight: 400;
	} */
	.eCard>div:nth-child(2) p {
		font-size: 1.3rem;
		margin-bottom: 5px;
	}
	.eCard {
		flex-direction: column;
		justify-content: space-evenly;
	}
	.eCard h1{
		padding-bottom: 0;
	}

	.eCard>div {
		width: 100%;
	}

	.eCard>div:nth-child(2) {
		padding: 0;
		margin: 0;
		margin-top: 0px;
		text-align: center;
	}
	.section-2 .fortune {
		margin-top: -5px;
		margin-left: 4.3rem;
		font-size: 2.5rem;
	}
	.section-2 .fortune span {
		left: -48px;
		font-size:4.4rem ;
		width:4.4rem;
	}
}

@media (max-width: 345px) {
	.section-2 .fortune {
		font-size: 2rem;
	}
	.section-2 {
		width: 270px;
		margin-top: 20px;
	}
	.eCard>div {
		min-height: 132px;
	}
	.eCard>div>video {
		width: 275px;
		height: 206px;
	}
	.eCard>div {
		min-height: 132px;
	}
	.eCard>div>video {
		width: 275px;
		height: 206px;
	}

	/* .section-2 h1 {
		font-size: 2.5rem;
		font-weight: 400;
	} */
	.eCard h1{
		padding-bottom: 0;
	}
}

@media (min-width: 346px) and (max-width: 480px) {
	.section-2 {
		width: 300px;
		margin-top: 20px;
	}
	.eCard>div {
		min-height: 113px;
	}
	/* .section-2 h1 {
		font-size: 2.5rem;
	} */
	.eCard h1{
		padding-bottom: 0;
	}
}

@media (min-width: 481px) and (max-width: 600px) {
	.section-2 {
		width: 500px;
	}
}

@media (min-width: 601px) and (max-width: 785px) {
	.section-2 {
		width: 650px;
	}


	.eCard {
		width: 100%;
		align-items: center;
	}

	.eCard>div {
		width: 50%;
		align-items: center !important;
	}

	.eCard>div>video {
		width: 321px;
		height: 242px;
	}

	.eCard>div:nth-child(2) {
		align-items: flex-start;
	}
}

@media (min-width: 800px) and (max-width: 1000px) {
	.section-2 {
		width: 700px;
	}

	.eCard>div {
		width: 50%;
		min-height: 200px;
	}

	.eCard>div>video {
		width: 321px;
		height: 242px;
	}

	.eCard>div:nth-child(2) {
		margin-top: -20px;
		align-items: flex-start;
	}
}

@media (min-width: 1000px) and (max-width: 1100px) {
	.section-2 {
		width: 900px;
	}

	.eCard>div {
		width: 35%;
		min-height: 200px;
	}

	.eCard>div>video {
		width: 321px;
		height: 242px;
	}

	.eCard>div:nth-child(2) {
		margin-top: -20px;
		align-items: flex-start;
	}
}
