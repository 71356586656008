.col ,
.col-3{
  padding-left: 0;
  padding-right: 0;
}

.activeTickets-detail {
  min-height: 10vh;
  display: flex;
}
.activeTickets-detail > div {
  border: 1px solid rgb(22, 20, 19);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2A2A2A;
  color: rgb(210, 210, 210);
}
.activeTickets-detail .heading h1{
   font-size: 2rem;
}
.activeTickets-detail > div > figure {
  margin: 0;
  width: 100px;
  height: 80px;
  background-position: center;
  background-size: cover;
}
.activeTickets-detail > div > h1 {
  font-size: 1.5rem;
}
.ticket-ids p {
  font-size: 1.3rem;
}
@media (max-width: 600px) {
  /* .activeTickets-detail > div > h1 {
    font-size: 0.5rem;
  } */
  .ticket-ids p {
    font-size: 1rem;
  }
  .heading h3{
    font-size: 1.0rem;
  }
  .activeTickets-detail > div {
    border: 3px solid #161413;
    border-left: 0px;
    border-right: 0px;
  }
  .activeTickets-detail .heading h1{
    font-size: 1.0rem;
 }
 .activeTickets-detail > div > figure {
  margin: 0;
  width: 75px;
  height: 56px;
  background-position: center;
  background-size: cover;
}

}
