@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap);
/* @import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/css/flag-icon.min.css'); */
:root {
  --primary: rgb(0, 255, 234);
  --secondary: aqua;
  --primary-act: #161413;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family: "Poppins", sans-serif;
}
.skiptranslate iframe {
  display: none !important;
}
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  font-family: "Poppins", sans-serif;
  font-family: var(--font-family);
}
::before,
::after {
  box-sizing: border-box;
}
a {
  text-decoration: none !important;
}
button,
button:focus,
li:focus {
  border: none;
  outline: none;
}
html {
  font-size: 62.5%;
}

body {
  font-family: "Poppins", sans-serif;
  font-family: var(--font-family);
  background-color: #161413;
  background-color: var(--primary-act);
}
.CookieConsent {
  width: 100%;
  height: 55vh;
  background: rgba(0, 255, 234, 0.7) !important;
  z-index: 90;
  display: flex !important;
  flex-direction: column;
  justify-content: flex-end !important;
  align-items: center !important;
  transition: all 1s;
}
.CookieConsent div {
  margin-bottom: 30px;
}
.CookieConsent div:nth-child(1) {
  flex: 0 0;
  display: flex;
  justify-content: center;
  align-items: center !important;
  margin: 0 !important;
}
.CookieConsent div:nth-child(2) button {
  margin: 0 !important;
}

.CookieConsent button {
  padding: 3px 20px 3px 20px !important;
  background-color: white !important;
  border-radius: 30px !important;
  cursor: pointer !important;
  font-size: 20px;
}
.CookieConsentbutton:hover {
  background-color: rgb(0, 255, 234);
  background-color: var(--primary);
}
.CookieConsent p {
  color: white;
  margin-bottom: 10px;
  font-size: 1.8rem;
}
.CookieConsent p .cookiesLink {
  font-size: 1.8rem;
  color: white;
  text-decoration: underline;
}
.popUpDiv {
  width: 400px;
  position: fixed;
  bottom: 20px;
  left: 2%;
  z-index: 200;
  opacity: 0;
  transition: opacity 1s;
  background-color: rgb(90, 90, 90);
  color: white;
  border-radius: 10px;
}

.popUpDiv.active {
  opacity: 1;
}
.popUpDiv {
  display: none; /* Make sure it's not visible or clickable */
}

.popUpDiv.active {
  display: block;
}
.popUpDiv > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.popUpDiv > div:nth-child(1) > img {
  width: 100%;
  height: 75%;
}
.popUpDiv > div > h1 {
  font-size: 1.6rem;
}
.popUpDiv > div > p {
  font-size: 1.5rem;
}
.popUpDiv > div:nth-child(2) {
  position: relative;
}
.popUpDiv > div:nth-child(2) > i {
  position: absolute;
  right: 10px;
  top: -10px;
  font-size: 2rem;
  cursor: pointer;
}
@media (min-width: 300px) and (max-width: 1100px) {
  .CookieConsent {
    height: 53vh;
  }
}
@media (min-width: 300px) and (max-width: 1100px) {
  .CookieConsent p {
    margin: 30px;
  }
}
@media (max-width: 800px) {
  .popUpDiv {
    width: 290px !important;
  }
  .popUpDiv > div:nth-child(1) > img {
    height: 90%;
  }
  .popUpDiv > div > h1 {
    font-size: 1.3rem;
  }
  .popUpDiv > div > p {
    font-size: 1.2rem;
  }
}

@media (min-width: 600px) and (max-width: 800px) {
  .popUpDiv {
    width: 420px !important;
    /* left: 15%; */
  }
  .popUpDiv > div:nth-child(1) > img {
    height: 90%;
  }
  .popUpDiv > div > h1 {
    font-size: 2rem;
  }
  .popUpDiv > div > p {
    font-size: 1.9rem;
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .popUpDiv {
    /* left: 10%; */
  }
  .popUpDiv > div:nth-child(1) > img {
    height: 90%;
  }
}

@media (min-width: 1280px) {
  .popUpDiv {
    /* left: 10%; */
  }
  .popUpDiv > div:nth-child(1) > img {
    height: 90%;
  }
}

@media (max-width: 450px){
  .navBar header nav{
    padding-left: 40px !important;
  }
} 

.order-body {
  width: 100%;
  min-height: 120vh;
  padding-bottom: 50px;
  background-color: rgb(240, 240, 240) !important;
}

.shoopingnavbar {
  width: 1080px;
  background-color: rgb(240, 240, 240) !important;
  margin: auto;
}

.shoopingnavbar .figure {
  margin-top: 10px !important;
  margin-left: -60px !important;
  cursor: pointer;
}

.shoopingnavbar .figure img {
  width: 130px;
  height: 120px;
}

.shoopingnavbar .figure .figcaption {
  color: black;
  font-size: 2.5rem;
  margin-top: 79px;
  margin-left: -50px;
}

.shoopingnavbar .figure .figcaption:hover .heading {
  text-decoration: none !important;
}

.shoopingnavbar nav ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.shoopingnavbar nav ul li {
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shoopingnavbar nav ul li h2 {
  font-size: 1.5rem;
  color: gray;
}

.shoopingnavbar nav ul li h1 {
  font-size: 2.4rem;
}

.shoopingnavbar nav input {
  padding: 5px 130px 5px 10px;
  font-size: 2rem;
  outline: none;
  border: none;
}

.shoopingnavbar .england {
  position: absolute;
  top: -45px;
  left: 15px;
  background-color: transparent;
  color: gainsboro;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shoopingnavbar .england img {
  width: 40px;
  height: 20px;
  margin-right: 5px;
  color: black !important;
}

.shoopingnavbar .dustbinn {
  position: relative;
  padding: 5px;
}

.dustbinn>span {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 0%;
  top: -10%;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black !important;
  font-size: 1.5rem;
  background-color: var(--primary);
}

.dustbinn {
  position: relative;
  font-size: 3rem;
  color: black;
}

.dustbinn:hover {
  color: var(--primary);
  text-decoration: none;
}

.dustbinn:hover span {
  color: black !important;
}

.shoopingnavbar .regBtn {
  color: var(--primary-act);
  background-color: rgb(210, 210, 210) !important;
}

.decBtn {
  margin-right: 10px;
  background-color: transparent !important;
}

.incBtn {
  margin-left: 10px;
  background-color: transparent !important;
}

.decBtn>i,
.incBtn>i {
  font-size: 1rem;
}

@media (min-width: 0px) and (max-width: 800px) {
  .header {
    padding: 0 0 5px 0;
  }

  .header .figure {
    margin-left: -20px !important;
    margin-top: -5px !important;
  }

  .header .figure .figcaption {
    font-size: 1.7rem;
    margin-top: 86px;
    margin-left: -60px;
  }

  .order-body nav {
    padding: 0 !important;
  }

  .order-body nav ul input {
    padding: 5px 10px 5px 20px !important;
    border-radius: 20px !important;
    font-size: 1.8rem !important;
    margin-top: 30px;
  }

  .order-body .england {
    top: -250px;
    left: 0;
  }

  .order-body nav ul li {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 10px !important;
  }

  .navbar-toggler {
    background-color: white;
    font-size: 1.8rem;
    margin-right: 10px;
    margin-top: 60px;
  }
}

@media (max-width: 400px) {
  .dustbin {
    top: -215px;
    left: 20px;
  }
}

@media (max-width: 499px) and (min-width: 401px) {
  .dustbin {
    top: -215px;
    left: -50px;
  }
}

@media (min-width: 769px) and (max-width: 900px) {
  .order-body .dustbin {
    top: -215px;
    left: -390px;
  }

  .navbar-toggler {
    background-color: white;
    font-size: 1.8rem;
    margin-right: 10px;
    margin-top: 40px;
  }
}

@media (min-width: 1000px) and (max-width: 1200px) {
  .order-body .dustbin {
    top: -215px;
    left: -390px;
  }

  .shoopingnavbar .figure {
    margin-left: 20px !important;
  }

  .shoopingnavbar nav input {
    padding: 5px 0px 5px 2px;
    font-size: 2rem;
    outline: none;
    border: none;
  }
}

@media (min-width: 1370px) and (max-width: 1440px) {
  .order-body .dustbin {
    top: -215px;
    left: -390px;
  }
}

.shopingcart {
  width: 85%;
  margin: 40px auto;
}

.shopingcart>div:nth-child(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shopingcart>div:nth-child(1) h1 {
  font-size: 4rem;
  color: black;
}

.shopingcart>div:nth-child(1) button:hover {
  background-color: rgb(81, 194, 156);
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.shopingcart>div:nth-child(1) button i {
  padding-right: 10px;
}

.shopingcart .head {
  width: 1090px;
  margin-top: 10px !important;
}

.shopingcart .head>div h1 {
  font-size: 2rem;
  font-weight: bolder;
  color: gray;
}

.shopping-item {
  width: 1150px;
  margin-top: 10px !important;
  background-color: white;
}

.iner-item {
  width: 100%;
}

.shopingcart>div:nth-child(1) {
  width: 95%;
}

.shopingcart>div:last-child {
  width: 95%;
}

.shopping-item>div:last-child i {
  font-size: 3rem;
  padding-left: 10px;
  cursor: pointer;
}

.shopping-item>div:last-child i:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.shopping-item>div:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(240, 240, 240);
}

.shopping-item>.iner-item>div:nth-child(1) {
  padding-left: 10px;
  padding-top: 10px;
  display: flex;
}

.shopping-item>.iner-item>div:not(:first-child) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.shopping-item>.iner-item>div:nth-child(1) figure {
  width: 110px;
  height: 100px;
  border-left: 2px solid black;
  border-right: 2px solid black;
}

.shopping-item>.iner-item>div:nth-child(1) figure img {
  width: 100%;
  height: 100%;
}

.shopping-item>.iner-item>div:nth-child(1) div {
  padding-left: 10px;
}

.shopping-item>.iner-item>div:nth-child(1) div h3 {
  font-size: 1.8rem;
  color: darkgrey;
  padding-top: 5px;
  padding-bottom: 5px;
}

.shopping-item>.iner-item>div:nth-child(1) div h1 {
  font-size: 1.9rem;
}

.shopping-item>.iner-item>div:not(:first-child) h1 {
  font-size: 2.3rem;
  color: black;
}

.shopping-item>.iner-item>div {
  border: 3px solid gainsboro;
}

.proc-chekout,
.cont-shoping,
.checkout-section>div:nth-child(1) button {
  padding: 5px 20px 5px 20px;
  background-color: white;
  font-size: 1.7rem;
  transition: all 0.3s;
}

.proc-chekout,
.cont-shoping {
  background-color: var(--primary);
}

.checkout-section>div:nth-child(1) button:hover {
  background-color: var(--primary);
  cursor: pointer;
}

.checkout-section>div:nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.checkout-section>div:nth-child(2) div button:hover {
  background-color: rgb(81, 194, 156);
  cursor: pointer;
}

.checkout-section>div:nth-child(2) div:nth-child(1) {
  margin-right: -30px;
  padding-right: 20px;
}

.checkout-section>div:nth-child(2) div:nth-child(1) span {
  font-size: 3rem;
  margin-left: 10px;
}

.iner-item>div:nth-child(2) h1,
.iner-item>div:nth-child(3) h1 {
  color: darkgray !important;
}

.order-items-container {
  display: flex;
  flex-direction: column;
  max-height: 360px;
  overflow-y: auto;
}
.orderMainCart {
  width: 100%;
  margin-top: 35px;
}
.order-items-container> :last-child {
  margin-bottom: 0;
}

@media (max-width: 1200px) {

  .shopingcart>div:nth-child(1) h1 {
    font-size: 2rem;
  }

  .checkout-section>div:nth-child(2) div button {
    padding: 10px 9px 10px 9px;
    background-color: var(--primary);
    font-size: 1.8rem;
    margin-right: 10px;
  }

  .shopingcart>div:nth-child(1) {
    width: 100%;
  }

  .shopingcart>div:last-child {
    width: 100%;
  }
}

@media (min-width: 0px) and (max-width: 590px) {
  .checkout-section {
    min-height: 20vh;
    display: flex;
    flex-direction: column;
  }

  .checkout-section>div:nth-child(1),
  .checkout-section>div:nth-child(2) {
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .checkout-section>div:nth-child(2) div button {
    padding: 13px 5px 13px 9px;
    background-color: var(--primary);
    font-size: 1.6rem;
    margin-right: 10px;
  }

  .checkout-section>div:nth-child(1) button {
    padding: 13px 15px 13px 15px;
    font-size: 1.6rem;
    margin-right: 10px;
  }

  .totalprize {
    margin: 0 !important;
  }

  .totalprize h1 span {
    font-size: 2rem !important;
  }
}

@media (min-width: 1400px) and (max-width: 1450px) {
  .shopingcart>div:nth-child(1) {
    width: 88.5%;
  }

  .shopingcart>div:last-child {
    width: 88.5%;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .shopingcart>div:nth-child(1) {
    width: 99%;
  }

  .shopingcart>div:last-child {
    width: 99%;
  }
}

@media (min-width: 1330px) and (max-width: 1380px) {
  .shopingcart>div:nth-child(1) {
    width: 95%;
  }

  .shopingcart>div:last-child {
    width: 95%;
  }
}

@media (max-width: 345px) {
  .shoopingnavbar {
    width: 320px;
  }
  .orderMainCart {
    margin-top: 10px;
  }
  .order-items-container {
    max-height: 360px;
  } 
}


@media (min-width: 346px) and (max-width: 480px) {
  .shoopingnavbar {
    width: 370px;
  }
  .orderMainCart {
    margin-top: 15px;
  }
  .order-items-container {
    max-height: 360px;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .shoopingnavbar {
    width: 580px;
  }
}

@media (min-width: 601px) and (max-width: 786px) {
  .shoopingnavbar {
    width: 720px;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .shoopingnavbar {
    width: 950px;
  }
}

@media (min-width: 1000px) and (max-width: 1100px) {
  .shoopingnavbar {
    width: 1000px;
  }
}



.first-div {
  width: 1070px;
  margin: auto;
}

.first-div>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.first-div>div h1 {
  font-size: 4rem;
  font-weight: 500;
}

.cont-shop-btn {
  width: 250px;
  height: 40px;
  font-size: 1.6rem;
  background-color: var(--primary);
  margin-right: 20px;
}

.cont-shop-btn>i {
  text-decoration: none;
  padding-right: 8px;
}

.second-div {
  width: 1070px;
  margin: auto;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.second-div>div:nth-child(1) {
  width: 45%;
}

.second-div>div:nth-child(2) {
  width: 50%;
}
.form-group{
  margin-top: 35px;
}
.form-group span{
  font-size: 17px;
  padding: 30px 10px 17px 14px;
}




form .btn {
  width: 100%;
  margin-top: 15px;
  padding: 8px 0;
  font-size: 18px;
  text-align: center;
  background-color: rgb(0, 255, 234);
  color: black;
  border: none;
  transition: 0.4s;
}

form .btn:hover {
  background-color: rgb(0, 255, 234);
  color: black;
  -webkit-transform: scale(1.04);
          transform: scale(1.04);
}

.main-section h1{
  color: gray;
  font-size: 24px;
  font-weight: 600;
}
.order-head {
  width: 100%;
  display: flex;
}

.order-head>div {
  display: flex;
  justify-content: center;
  align-items: center !important;
}

.order-head>div:nth-child(1) {
  width: 84%;
  justify-content: flex-start;
  padding-left: 10px;
}

.order-head>div:nth-child(2) {
  width: 14%;
}

.order-head>div>h1 {
  font-size: 1.45rem;
  color: #80808e;
  font-weight: bolder;
}

.order-items {
  width: 100%;
  min-height: 105px;
  display: flex;
  margin-top: 10px;
  background-color: white;
  border: 2px solid rgb(240, 240, 240);
}

.order-items>div {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 4px solid rgb(240, 240, 240);
}

.order-items>div>h1 {
  font-size: 2rem;
  color: #8e8e9a;
}

.order-items>div:nth-child(1) {
  width: 85%;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 10px;
}

.order-items>div:last-child {
  border-right: none;
}

.order-items>div:last-child h1 {
  color: black;
}

.order-items-img {
  width: 100px;
  height: 75px;
  margin: 0;
  background-position: center;
  background-size: cover;
  position: relative;
}

.order-items-img>span {
  position: absolute;
  right: -10%;
  top: -8%;
  width: 29px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--primary);
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.order-items>div>div:nth-child(1) {
  height: 100%;
  display: flex;
  align-items: center;
}

.order-items>div>div:nth-child(2) {
  padding-left: 20px;
  padding-top: 10px;
  width: 90%;
}

.order-items>div>div>h4 {
  color: #8e8e9a;
  font-size: 1.7rem;
}

.order-items>div>div>h2 {
  color: black;
  margin-top: 10px;
  font-size: 1.8rem;
  display: flex;
  justify-content: space-between;
}

.order-items>div>div>h2>span:nth-child(1) {
  word-break: break-all;
}

.order-items>div>div>h2>span {
  margin-right: 10px;
}

.del-icon {
  display: flex;
  align-items: center;
  background-color: #f0f0f0 !important;
}

.del-icon i {
  font-size: 2rem;
}

.del-icon i:hover {
  -webkit-transform: scale(1.07);
          transform: scale(1.07);
  cursor: pointer;
}

.last-div {
  /* width: 100%; */
  margin-top: 30px;
}

.last-div>div {
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.last-div>div h1 {
  font-size: 4rem;
  margin-right: 20px;
}

.submit-co-code {
  width: 250px;
  height: 40px;
  background-color: white;
  color: #80808e;
  font-size: 1.7rem;
  cursor: pointer;
  transition: all 0.3s;
}

.pro-checkout-btn {
  width: 270px;
  height: 40px;
  background-color: var(--primary);
  margin-right: 20px;
  font-weight: 400;
}

.pro-checkout-btn>i {
  text-decoration: none;
  padding-right: 8px;
}

.last-div>div>div {
  text-align: right;
}

.last-div>div>div:nth-child(1) {
  width: 45%;
}

.last-div>div>div:nth-child(2) {
  width: 50%;
}

.last-div>div>div h1 {
  font-size: 2rem;
  padding-bottom: 10px;
}

.cont-shop-btn,
.pro-checkout-btn {
  transition: all 0.2s;
  font-size: 1.8rem;
  font-weight: 400;
}

.submit-co-code:hover,
.cont-shop-btn:hover,
.pro-checkout-btn:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  background-color: rgba(0, 255, 234, 0.87);
  cursor: pointer;
}

@media (max-width: 600px) {
  .second-div {
    display: flex;
    flex-direction: column;
  }

  .second-div>div:nth-child(1) {
    width: 100%;
    margin-bottom: 20px;
  }

  .second-div>div:nth-child(2) {
    width: 100%;
  }

  .first-div>div {
    flex-direction: column;
  }

  .last-div>.w-100>div:nth-child(1),
  .last-div>.w-100>div:nth-child(2) {
    width: 100%;
  }

  .last-div>.w-100 {
    flex-direction: column-reverse;
  }

  .order-head>div>h1 {
    font-size: 1.5rem;
  }

  .last-div>div>div h1 {
    font-size: 2.4rem;
  }

}

@media (min-width: 601px) and (max-width: 800px) {
  .order-headdiv h1 {
    font-size: 1.5rem;
  }
}

@media (max-width: 1100px) {
  .second-div {
    width: 100%;
  }

  .order-items {
    width: 900px;
  }
}

@media (max-width: 1350px) {
  .order-items>div>div>h2 {
    font-size: 1.7rem;
  }
}

@media (min-width: 1400px) {
  .order-items>div>div figure {
    height: 75%;
  }
}

@media (max-width: 345px) {


  .first-div,
  .second-div {
    width: 300px;
  }

  .cont-shop-btn,
  .pro-checkout-btn,
  .last-div>div>div h1 {
    margin-right: 0;
  }
  .first-div>div h1 {
    font-size: 1.8rem;
    font-weight: 400;
  }
  .main-section{
    margin-left: 8px;
  }
  .main-section h1{
    font-size: 15px;
    font-weight: 500;
  }
 
  .form-group{
    margin-top: 0px;
  }
  .form-group span{
    font-size: 12px;
    padding: 10px 2px 7px 5px;
  }

  .order-head>div:nth-child(1) {
    width: 82%;
  }
  
  .order-head>div:nth-child(2) {
    width: 14%;
  }
  
  .order-head>div>h1 {
    font-size: 1.4rem;
    font-weight:600;
  }
  .order-head,
  .order-items {
    width:400px;
  }
  
  .order-items {
    min-height: 60px;
    margin-top: 3px;
  }
  .order-items>div>h1 {
    font-size: 1.5rem;
    color: #8e8e9a;
  }
  .order-items-img {
    width: 50px;
    height: 37px;
  }
  .order-items>div:nth-child(1) {
    width: 79%;
    padding-left: 5px;
  }
  .order-items>div>div:nth-child(2) {
    padding-left: 20px;
    padding-top: 10px;
    width: 90%;
  }
  
  .order-items>div>div>h4 {
    color: #8e8e9a;
    font-size: 1rem;
  }
  
  .order-items>div>div>h2 {
    margin-top: 10px;
    font-size: 1.3rem;
  }
  .order-items-img>span {
    width: 19px;
    height: 18px;
    font-size: 11px;
  }
}

@media (min-width: 346px) and (max-width: 480px) {
  
  .form-group{
    margin-top: 0px;
  }
  .form-group span{
    font-size: 13px;
    padding: 8px 2px 7px 5px;
  }
  .first-div,
  .second-div {
    width: 310px;
  }

  .cont-shop-btn,
  .pro-checkout-btn,
  .last-div>div>div h1 {
    margin-right: 0;
  }
  .first-div>div h1 {
    font-size: 1.8rem;
    font-weight: 400;
  }
  .main-section{
    margin-left: 5px;
  }
  .main-section h1{
    font-size: 16px;
    font-weight: 500;
  }
 
  .order-head>div:nth-child(1) {
    width: 82%;
  }
  
  .order-head>div:nth-child(2) {
    width: 14%;
  }
  .order-items>div>h1 {
    font-size: 1.6rem;
    color: #8e8e9a;
  }
  .order-head>div>h1 {
    font-size: 1.4rem;
    font-weight:600;
  }
  .order-head,
  .order-items {
    width:420px;
  }
  
  .order-items {
    min-height: 75px;
    margin-top: 3px;
  }
  .order-items-img {
    width: 60px;
    height: 45px;
  }
  .order-items-img>span {
    width: 22px;
    height: 21px;
    font-size: 13px;
  }
  .order-items>div:nth-child(1) {
    width: 81%;
    padding-left: 5px;
  }
  .order-items>div>div:nth-child(2) {
    padding-left: 20px;
    padding-top: 10px;
    width: 90%;
  }
  
  .order-items>div>div>h4 {
    color: #8e8e9a;
    font-size: 1.1rem;
  }
  
  .order-items>div>div>h2 {
    margin-top: 10px;
    font-size: 1.5rem;
  }
}

@media (min-width: 481px) and (max-width: 600px) {

  .first-div,
  .second-div {
    width: 500px;
  }

  .cont-shop-btn,
  .pro-checkout-btn,
  .last-div>div>div h1 {
    margin-right: 0;
  }
}

@media (min-width: 601px) and (max-width: 786px) {

  .first-div,
  .second-div {
    width: 650px;
  }

  .cont-shop-btn,
  .pro-checkout-btn,
  .last-div>div>div h1 {
    margin-right: 9px;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {

  .first-div,
  .second-div {
    width: 880px;
  }
}

@media (min-width: 1000px) and (max-width: 1100px) {

  .first-div,
  .second-div {
    width: 900px;
  }

  .cont-shop-btn,
  .pro-checkout-btn,
  .last-div>div>div h1 {
    margin-right: 10px;
  }
}
.customized {
    color: white;
    font-size: 1.3rem;
}
.Toastify__toast--default, .Toastify__toast--error {
    background: rgb(90, 90, 90) !important;
}

.Toastify__progress-bar--default{
    background: var(--primary) !important;
}
.Toastify__close-button {
    color: white !important;
    opacity: 0.5 !important;
}
.Toastify__close-button--default:hover {
    color: var(--primary) !important;
    opacity: 1 !important;
}
.Toastify__close-button--error:hover {
    color: red !important;
    opacity: 1 !important;
}

.Toastify__progress-bar--error {
    background-color: red !important;
}

@media (max-width:500px){
    .Toastify__toast--default, .Toastify__toast--error {
        background: rgb(90, 90, 90) !important;
        z-index: 1050;
        top: 50px; 
    }
}
.overlay{
    background:rgba(0,0,0,.6);
    position:fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}
.overlay-content{
    color: #fff;
    font-size:2rem;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(210, 210, 210);
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
}

.subtotal-section h3{
    font-size: 30px;
  }
  .pro-check-btn {
    width: 300px;
    height: 40px;
    margin-top: 15px;
    margin-right: 0px;
    background-color: #ffc439;
  border-radius: 23px;
  outline: none;
  border: none; 
    font-weight: 400;
    transition: all 0.2s;
  font-size: 1.8rem;
  }
  .pro-check-btn > i {
    text-decoration: none;
    padding-right: 8px;
  }
  .pro-check-btn:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    background-color: rgba(0, 255, 234, 0.87);
    cursor: pointer;
  }
  @media (max-width: 346px){
    .subtotal-section h3{
        font-size: 17px;
      }
      .pro-check-btn {
        width: 230px;
        height: 30px;
        margin-top: 15px;
        margin-right: 29px; 
        font-weight: 400;
        transition: all 0.2s;
      font-size: 1.3rem;
      }
  }
  @media (min-width: 346px) and (max-width: 480px) {
    .subtotal-section h3{
        font-size: 21px;
      }
      .pro-check-btn {
        width: 230px;
        height: 30px;
        margin-top: 15px;
        margin-right: 29px; 
        font-weight: 400;
        transition: all 0.2s;
      font-size: 1.3rem;
      }
  }

.payment-options {
  display: flex;
  grid-gap: 50px;
  gap: 50px;
  margin: 0 30px;
}

.payment-option input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.payment-option .payment-option-label {
  width: 110px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  color: black;
  background-color: rgb(210, 210, 210);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
}

.payment-option .payment-option-label:hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.payment-option .payment-option-label:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #999;
  transition: all 0.2s ease-in-out;
}

.payment-option input[type="radio"]:checked+.payment-option-label:before {
  background-color: rgb(0, 255, 234);
  border-color: rgb(0, 255, 234);
}

.payment-option input[type="radio"]:checked+.payment-option-label {
  background-color: rgb(0, 255, 234);
}

.payment-option input[type="radio"]:focus+.payment-option-label:before {
  outline: 2px solid rgb(0, 255, 234);
}

@media (max-width:444px){
  .title-payment{
    font-size: 17px;
   }
}

.ccv_payment_btn {
  padding: 5px 2px;
  cursor: pointer;
  margin: 20px;
  background-color: aliceblue;
}

.credit-card-btn,
.credit-card-btn:hover {
  width: 100%;
  background: #ffc439;
  margin-top: 22px;
  border-radius: 23px;
  height: 45px;
  vertical-align: top;
  min-height: 30px;
  max-height: 55px;
  outline: none;
  border: none;
  font-size: 16px;
  line-height: 22px;
  color: #2C2E2F;
}

.py-30px {
  padding-top: 25px;
  padding-bottom: 25px;
}

.ban-contact {
  margin: 8px;
  width: 90px;
  height: 70px;
  cursor: pointer;
  margin-top: 20px;
}
@media (max-width: 445px) {
  .credit-card-btn,
.credit-card-btn:hover {
  width: 85%;
  margin-top: 15px;
  margin-left: 30px;
  height: 40px;
  font-size: 14px;
  line-height: 20px;
}
}
@media screen and (min-width: 1000px) {
  .ban-contact {
    margin: 8px 18px;
    margin-top: 20px;
  }
  
}


.shoopingcart-body {
  width: 100%;
  min-height: 120vh;
  padding-bottom: 150px;
  background-color: rgb(240, 240, 240) !important;
}

.shoopingnavbar {
  width: 1080px;
  background-color: rgb(240, 240, 240) !important;
  margin: auto;
}
.main-section{
  /* margin-top: 20px; */
  /* margin-left: 10px; */
}
.main-section h2{
  color:  gray;
  font-size: 21px;
  font-weight: 600;
}
.shoopingnavbar .figure {
  margin-top: 10px !important;
  margin-left: -60px !important;
  cursor: pointer;
}
.shoopingnavbar .figure img {
  width: 130px;
  height: 120px;
}
.shoopingnavbar .figure .figcaption {
  color: black;
  font-size: 2.5rem;
  margin-top: 79px;
  margin-left: -50px;
}
.shoopingnavbar .figure .figcaption:hover .heading {
  text-decoration: none !important;
}
.shoopingnavbar nav ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.shoopingnavbar nav ul li {
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.shoopingnavbar nav ul li h2 {
  font-size: 1.5rem;
  color: gray;
}

.shoopingnavbar nav ul li h1 {
  font-size: 2.4rem;
}
.shoopingnavbar nav input {
  padding: 5px 130px 5px 10px;
  font-size: 2rem;
  outline: none;
  border: none;
}
.shoopingnavbar .england {
  position: absolute;
  top: -45px;
  left: 15px;
  background-color: transparent;
  color: gainsboro;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shoopingnavbar .england img {
  width: 40px;
  height: 20px;
  margin-right: 5px;
  color: black !important;
}
.shoopingnavbar .dustbinn {
  position: relative;
  padding: 5px;
}
.dustbinn > span {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 0%;
  top: -10%;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black !important;
  font-size: 1.5rem;
  background-color: var(--primary);
}
.dustbinn {
  position: relative;
  font-size: 3rem;
  color: black;
}
.dustbinn:hover {
  color: var(--primary);
  text-decoration: none;
}
.shoopingnavbar .regBtn {
  color: var(--primary-act);
  background-color: rgb(210, 210, 210) !important;
}
.decBtn {
  cursor: pointer;
  margin-right: 10px;
  background-color: transparent !important;
}
.incBtn {
  cursor: pointer;
  margin-left: 10px;
  background-color: transparent !important;
}
.decBtn > i,
.incBtn > i {
  font-size: 1rem;
}

@media (min-width: 0px) and (max-width: 800px) {
  .main-section{
    margin-top: 0px;
    margin-left: 0px;
  }
  .header {
    padding: 0 0 5px 0;
  }
  .header .figure {
    margin-left: -20px !important;
    margin-top: -5px !important;
  }
  .header .figure .figcaption {
    font-size: 1.7rem;
    margin-top: 86px;
    margin-left: -60px;
  }
  .shoopingcart-body nav {
    padding: 0 !important;
  }
  .shoopingcart-body nav ul input {
    padding: 5px 10px 5px 20px !important;
    border-radius: 20px !important;
    font-size: 1.8rem !important;
    margin-top: 30px;
  }
  .shoopingcart-body .england {
    top: -250px;
    left: 0;
  }

  .shoopingcart-body nav ul li {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 10px !important;
  }

  .navbar-toggler {
    background-color: white;
    font-size: 1.8rem;
    margin-right: 10px;
    margin-top: 60px;
  }
}

@media (max-width: 400px) {
  .dustbin {
    top: -215px;
    left: 20px;
  }
}
@media (max-width: 499px) and (min-width: 401px) {
  .dustbin {
    top: -215px;
    left: -50px;
  }
}
@media (min-width: 769px) and (max-width: 900px) {
  .shoopingcart-body .dustbin {
    top: -215px;
    left: -390px;
  }
  .navbar-toggler {
    background-color: white;
    font-size: 1.8rem;
    margin-right: 10px;
    margin-top: 40px;
  }
}

@media (min-width: 1000px) and (max-width: 1200px) {
  .shoopingcart-body .dustbin {
    top: -215px;
    left: -390px;
  }
  .shoopingnavbar .figure {
    margin-left: 20px !important;
  }

  .shoopingnavbar nav input {
    padding: 5px 0px 5px 2px;
    font-size: 2rem;
    outline: none;
    border: none;
  }
}
@media (min-width: 1370px) and (max-width: 1440px) {
  .shoopingcart-body .dustbin {
    top: -215px;
    left: -390px;
  }
}
.shopingcart {
  width: 85%;
  margin: 40px auto;
}
.shopingcart > div:nth-child(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shopingcart > div:nth-child(1) h1 {
  font-size: 4rem;
  color: black;
}
.shopingcart > div:nth-child(1) button:hover {
  background-color: rgb(81, 194, 156);
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.shopingcart > div:nth-child(1) button i {
  padding-right: 10px;
}
.shopingcart .head {
  width: 1090px;
  margin-top: 10px !important;
}
.shopingcart .head > div h1 {
  font-size: 2rem;
  font-weight: bolder;
  color: gray;
}
.shopping-items-container {
  width: 100%;
  min-height: 50vh;
  margin-top: 40px;
  overflow-x: scroll;

}
.shopping-items-container::-webkit-scrollbar {
  width: 9px; 
}
.shopping-items-container ::-webkit-scrollbar-thumb {
  background: rgb(163, 163, 163);
  border: 3px solid rgb(163, 163, 163);
  border-radius: 5px;
}

.shopping-items-container ::-webkit-scrollbar-track {
  background: rgb(224, 224, 224);
}
::-webkit-scrollbar-thumb {
  background: rgb(163, 163, 163);
  border: 3px solid rgb(163, 163, 163);
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: rgb(224, 224, 224);
}

::-webkit-scrollbar {
  width: 9px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.shopping-item {
  width: 1150px;
  margin-top: 10px !important;
  background-color: white;
}
.iner-item {
  width: 100%;
}
.shopingcart > div:nth-child(1) {
  width: 95%;
}
.shopingcart > div:last-child {
  width: 95%;
}
.shopping-item > div:last-child i {
  font-size: 3rem;
  padding-left: 10px;
  cursor: pointer;
}
.shopping-item > div:last-child i:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.shopping-item > div:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(240, 240, 240);
}
.shopping-item > .iner-item > div:nth-child(1) {
  padding-left: 10px;
  padding-top: 10px;
  display: flex;
}
.shopping-item > .iner-item > div:not(:first-child) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.shopping-item > .iner-item > div:nth-child(1) figure {
  width: 110px;
  height: 100px;
  border-left: 2px solid black;
  border-right: 2px solid black;
}
.shopping-item > .iner-item > div:nth-child(1) figure img {
  width: 100%;
  height: 100%;
}
.shopping-item > .iner-item > div:nth-child(1) div {
  padding-left: 10px;
}
.shopping-item > .iner-item > div:nth-child(1) div h3 {
  font-size: 1.8rem;
  color: darkgrey;
  padding-top: 5px;
  padding-bottom: 5px;
}
.shopping-item > .iner-item > div:nth-child(1) div h1 {
  font-size: 1.9rem;
}
.shopping-item > .iner-item > div:not(:first-child) h1 {
  font-size: 2.3rem;
  color: black;
}
.shopping-item > .iner-item > div {
  border: 3px solid gainsboro;
}
.shopping-items-container {
  padding-bottom: 50px;
}

.proc-chekout,
.cont-shoping,
.checkout-section > div:nth-child(1) button {
  padding: 5px 20px 5px 20px;
  background-color: white;
  font-size: 1.7rem;
  transition: all 0.3s;
}
.proc-chekout,
.cont-shoping {
  background-color: var(--primary);
}

.checkout-section > div:nth-child(1) button:hover {
  background-color: var(--primary);
  cursor: pointer;
}
.checkout-section > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.checkout-section > div:nth-child(2) div button:hover {
  background-color: rgb(81, 194, 156);
  cursor: pointer;
}
.checkout-section > div:nth-child(2) div:nth-child(1) {
  margin-right: -30px;
  padding-right: 20px;
}
.checkout-section > div:nth-child(2) div:nth-child(1) span {
  font-size: 3rem;
  margin-left: 10px;
}
.iner-item > div:nth-child(2) h1,
.iner-item > div:nth-child(3) h1 {
  color: darkgray !important;
}

@media (max-width: 1200px) {
  .shopping-items-container {
    overflow-x: scroll;
  }
  .shopingcart > div:nth-child(1) h1 {
    font-size: 2rem;
  }

  .checkout-section > div:nth-child(2) div button {
    padding: 10px 9px 10px 9px;
    background-color: var(--primary);
    font-size: 1.8rem;
    margin-right: 10px;
  }
  .shopingcart > div:nth-child(1) {
    width: 100%;
  }
  .shopingcart > div:last-child {
    width: 100%;
  }
}

@media (min-width: 0px) and (max-width: 590px) {
  .checkout-section {
    min-height: 20vh;
    display: flex;
    flex-direction: column;
  }
  .checkout-section > div:nth-child(1),
  .checkout-section > div:nth-child(2) {
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .checkout-section > div:nth-child(2) div button {
    padding: 13px 5px 13px 9px;
    background-color: var(--primary);
    font-size: 1.6rem;
    margin-right: 10px;
  }
  .checkout-section > div:nth-child(1) button {
    padding: 13px 15px 13px 15px;
    font-size: 1.6rem;
    margin-right: 10px;
  }
  .totalprize {
    margin: 0 !important;
  }
  .totalprize h1 span {
    font-size: 2rem !important;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
}
@media (min-width: 1400px) and (max-width: 1450px) {
  .shopingcart > div:nth-child(1) {
    width: 88.5%;
  }
  .shopingcart > div:last-child {
    width: 88.5%;
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .shopingcart > div:nth-child(1) {
    width: 99%;
  }
  .shopingcart > div:last-child {
    width: 99%;
  }
}
@media (min-width: 1330px) and (max-width: 1380px) {
  .shopingcart > div:nth-child(1) {
    width: 95%;
  }
  .shopingcart > div:last-child {
    width: 95%;
  }
}

@media (max-width: 345px) {
  .first-div-cont > div:last-child {
    margin-top: 15px !important;
  }
  .shoopingnavbar {
    width: 320px;
  }
}
@media (min-width: 346px) and (max-width: 480px) {
  .shoopingnavbar {
    width: 370px;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .shoopingnavbar {
    width: 580px;
  }
}

@media (min-width: 601px) and (max-width: 786px) {
  .shoopingnavbar {
    width: 720px;
  }
}
@media (min-width: 800px) and (max-width: 1000px) {
  .shoopingnavbar {
    width: 950px;
  }
}

@media (min-width: 1000px) and (max-width: 1100px) {
  .shoopingnavbar {
    width: 1000px;
  }
}

.mainCart {
  width: 100%;
}
.first-div-cont {
  width: 100%;
  margin-top: 35px;
}
.first-div-cont > div {
  width: 1090px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.first-div-cont > div h1 {
  font-size: 4rem;
  font-weight: 200;
}
.cont-shop-btn {
  width: 300px;
  height: 40px;
  font-size: 1.6rem;
  background-color: var(--primary);
  margin-right: 20px;
}
.cont-shop-btn > i {
  text-decoration: none;
  padding-right: 8px;
}

.second-div-cont {
  width: 100%;
  margin: auto;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.shopping-items-cont {
  width: 1070px;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
}

.items-head {
  width: 100%;
  min-height: 8vh;
  margin-bottom: 3px;
  display: flex;
}
.items-head > div {
  width: 50.5%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.items-head > div > h1 {
  font-size: 1.5rem;
  color: #80808e;
  font-weight: bolder;
}
.items-head > div:nth-child(4) {
  width: 10%;
}  .items-head > div:nth-child(3) {
  width: 13%;
}  
.items-head > div:nth-child(2) {
  width: 10%;
}  
.items-head > div:nth-child(1) {
  width: 63%;
  justify-content: flex-start;
  padding-left: 130px;
}
.shopping-items {
  width: 100%;
  min-height: 80px;
  display: flex;
  margin-top: 2px;
  background-color: #ffffff;
  border: 2px solid rgb(240, 240, 240);
}
.shopping-items > div:not(:last-child) {
  width: 13%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 4px solid rgb(240, 240, 240);
}
.shopping-items > div > h1 {
  font-size: 1.7rem;
  color: #8e8e9a;
  margin-bottom: -3px;
}
.shopping-items > div:nth-child(2) {
  width: 10%;
}
.shopping-items > div:nth-child(1) {
  width: 63%;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 10px;
}
.shopping-items > div:last-child {
  border-right: none;
}
.shopping-items > div:last-child h1 {
  color: black;
}
.shoping-items-img {
  width: 100px;
  height: 75px;
  margin: 0;
  background-position: center;
  background-size: cover;
}
.shopping-items > div > div:nth-child(1) {
  height: 100%;
  display: flex;
  align-items: center;
}
.shopping-items > div > div:nth-child(2) {
  padding-left: 20px;
  padding-top: 10px;
  width: 90%;
}
.shopping-items > div > div > h4 {
  color: #8e8e9a;
  font-size: 1.7rem;
}
.shopping-items > div > div > h2 {
  width: 100%;
  color: black;
  margin-top: 10px;
  font-size: 2rem;
  display: flex;
  justify-content: space-between;
}
.shopping-items > div > div > h2 > span {
  margin-right: 10px;
}

.del-icon {
  display: flex;
  align-items: center;
  background-color: #f0f0f0 !important;
}
.del-icon i {
  font-size: 2rem;
}
.del-icon i:hover {
  -webkit-transform: scale(1.07);
          transform: scale(1.07);
  cursor: pointer;
}
.last-div-cont {
  width: 100%;
  margin-top: 5px;
}
.last-div-cont > div {
  width: 1090px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.last-div-cont > div h1 {
  font-size: 4rem;
  margin-right: 20px;
}
.submit-co-code {
  width: 250px;
  height: 40px;
  background-color: white;
  color: #80808e;
  font-size: 1.7rem;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: 400;
}

.pro-checkout-btn {
  margin: 10px;
  width: 270px;
  height: 40px;
  background-color: var(--primary);
  margin-right: 20px;
  font-weight: 400;
}
.pro-checkout-btn > i {
  text-decoration: none;
  padding-right: 8px;
}
.last-div-cont > div > div {
  text-align: right;
}
.last-div-cont > div > div h1 {
  font-size: 2rem;
  padding-bottom: 10px;
}
.cont-shop-btn,
.pro-checkout-btn {
  transition: all 0.2s;
  font-size: 1.8rem;
}
.submit-co-code:hover,
.cont-shop-btn:hover,
.pro-checkout-btn:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  background-color: rgba(0, 255, 234, 0.87);
  cursor: pointer;
}

@media (max-width: 600px) {
  .first-div-cont > div {
    flex-direction: column;
  }
  .items-head > div > h1 {
    font-size: 1rem;
  }
  .last-div-cont div {
    align-items: center;
    flex-direction: column;
  }
  .last-div-cont > div > div {
    text-align: center;
  }
  .last-div-cont > div > div h1 {
    font-size: 2.4rem;
  }
}
@media (min-width: 601px) and (max-width: 800px) {
  .items-head div h1 {
    font-size: 1.5rem;
  }
}
@media (max-width: 1100px) {
  .second-div-cont {
    width: 100%;
  }
  /* .shopping-items-cont {
    overflow-x: scroll;
  } */
  .shopping-items,
  .items-head {
    width: 900px;
  }
}
@media (max-width: 1350px) {
  .shopping-items > div > div > h2 {
    font-size: 1.7rem;
  }
}

@media (min-width: 1400px) {
  .shopping-items > div > div figure {
    height: 100%;
  }
}

@media (max-width: 345px) {
  .main-section{
    margin-top: 0px;
    margin-left: 0px;
  }
  .main-section h2{
    font-size: 15px;
    font-weight: 500;
  }
  .first-div-cont > div,
  .shopping-items-cont,
  .last-div-cont > div {
    width: 300px;
  }
  .cont-shop-btn,
  .pro-checkout-btn,
  .last-div-cont > div > div h1 {
    font-size: 1.3rem ;
    margin-right: 0;
  }
   .pro-checkout-btn,
  .cont-shop-btn {
    margin-top: 10px;
    width: 240px;
    height: 35px;
  font-size: 1.4rem;
}
.first-div-cont {
  margin-top: 5px;
}
  .first-div-cont > div h1 {
    font-size: 3rem;
    font-weight: 200;
  }
  .second-div-cont {
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .shopping-items {
    min-height: 60px;
  }
  .shoping-items-img {
    width: 40px;
    height: 30px;
  }
  .shopping-items > div > div > h2 {
    font-size: 1.3rem;
  }
  .shopping-items > div > div > h4 {
    color: #8e8e9a;
    font-size: 0.9rem;
  }
  .shopping-items > div > h1 {
    font-size: 1.5rem;
  }
  .shopping-items,
  .items-head {
    width: 520px;
  }
  .shopping-items > div:nth-child(1) {
    width: 65%;
    padding-left: 5px;
  }
  .shopping-items > div:nth-child(2) {
    width: 17%;
  }
  .shopping-items > div:nth-child(3) {
    width: 17%;
  }
  .shopping-items > div:nth-child(4) {
    width: 17%;
  }
  .items-head > div > h1 {
    font-size: 1rem;
  }
  .items-head > div:nth-child(1) {
    width: 55%;
  }
  .items-head > div:nth-child(2) {
    width: 11%;
  }
  .items-head > div:nth-child(3) {
    width: 19%;
  }
  .items-head > div:nth-child(4) {
    width: 7%;
  }
 

}

@media (min-width: 346px) and (max-width: 480px) {
  /* .main-section{
    margin-top: 20px;
    margin-left: 5px;
  } */
  .main-section h2{
    font-size: 17px;
    font-weight: 500;
  }
  .first-div-cont > div,
  .shopping-items-cont,
  .last-div-cont > div {
    width: 310px;
  }
  .cont-shop-btn,
  .pro-checkout-btn,
  .last-div-cont > div > div h1 {
    margin-top: 10px;
    margin-right: 0;
    font-size: 1.5rem ;
  }
  .pro-checkout-btn,
  .cont-shop-btn {
    width: 250px;
    height: 35px;
  font-size: 1.4rem;
}
  .first-div-cont > div h1 {
    font-size: 3rem;
    font-weight: 200;
  }
  .shoping-items-img {
    width: 50px;
    height: 37px;
  }
   .pro-checkout-btn,
  .cont-shop-btn {
    width: 240px;
    height: 35px;
  font-size: 1.4rem;
}
.first-div-cont {
  margin-top: 5px;
}
  .first-div-cont > div h1 {
    font-size: 3rem;
    font-weight: 200;
  }
  .second-div-cont {
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .shopping-items {
    min-height: 65px;
  }
  .shopping-items > div > div > h2 {
    font-size: 1.3rem;
  }
  .shopping-items > div > div > h4 {
    color: #8e8e9a;
    font-size: 1rem;
  }
  .shopping-items > div > h1 {
    font-size: 1.6rem;
  }
  .shopping-items,
  .items-head {
    width: 550px;
  }
  .shopping-items > div:nth-child(1) {
    width: 65%;
    padding-left: 5px;
  }
  .shopping-items > div:nth-child(2) {
    width: 17%;
  }
  .shopping-items > div:nth-child(3) {
    width: 17%;
  }
  .shopping-items > div:nth-child(4) {
    width: 17%;
  }
  .items-head > div > h1 {
    font-size: 1.1rem;
  }
  .items-head > div:nth-child(1) {
    width: 55%;
  }
  .items-head > div:nth-child(2) {
    width: 11%;
  }
  .items-head > div:nth-child(3) {
    width: 19%;
  }
  .items-head > div:nth-child(4) {
    width: 7%;
  }
 

}

@media (min-width: 481px) and (max-width: 600px) {
  .first-div-cont > div,
  .shopping-items-cont,
  .last-div-cont > div {
    width: 500px;
  }
  .cont-shop-btn,
  .pro-checkout-btn,
  .last-div-cont > div > div h1 {
    margin-right: 0;
  }
}

@media (min-width: 601px) and (max-width: 786px) {
  .first-div-cont > div,
  .last-div-cont > div,
  .shopping-items-cont {
    width: 650px;
  }
  .cont-shop-btn,
  .pro-checkout-btn,
  .last-div-cont > div > div h1 {
    margin-right: 9px;
  }
}
@media (min-width: 800px) and (max-width: 1000px) {
  .first-div-cont > div,
  .last-div-cont > div,
  .shopping-items-cont {
    width: 880px;
  }
}

@media (min-width: 1000px) and (max-width: 1100px) {
  .first-div-cont > div,
  .last-div-cont > div,
  .shopping-items-cont {
    width: 900px;
  }
  .cont-shop-btn,
  .pro-checkout-btn,
  .last-div-cont > div > div h1 {
    margin-right: 10px;
  }
}

.navBar {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  margin: 0;
  padding-top: 2px;
  padding-bottom: 3px;
  background-color: #161413;
}

.navBar header {
  display: flex;
  width: 1250px;
  margin: auto;
}
.navBar header .figure .par {
  font-size: 15px;
}
/* LOGO-SETTING */

.navBar header .figure {
  display: flex;
  margin-top: -10px !important;
  cursor: pointer;
}
.navBar header .figure img {
  width: 130px;
  height: 120px;
}
.navBar header .figure .figcaption {
  color: var(--primary);
  font-size: 2.5rem;
  margin-top: 79px;
  margin-left: -50px;
}
.NavLink {
  text-decoration: none !important;
  font-weight: 600;
}

/* NAVBAR-SETTING */

nav ul {
  margin-top: 55px;
}
.loginSuccess {
  margin-right: 100px;
}
nav ul li {
  margin-top: 5px;
  margin-left: 5px;
  position: relative;
}
nav ul li::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0%;
  left: 45%;
  border-bottom: 3px solid var(--primary);
  transition: all 0.3s;
}
nav ul li:last-child {
  margin-right: 10px;
}
nav ul li span {
  border-radius: 20px;
  font-size: 1.8rem;
  color: rgb(210, 210, 210);
  transition: all 0.2s;
  transition: all 0.2s;
  padding: 2px 10px 2px 10px !important ;
}
nav ul .Navlink {
  color: rgb(210, 210, 210);
}
nav ul li:hover span {
  color: var(--primary) !important;
}
.NavLink:hover,
.activeNavBtn:hover {
  text-decoration: none;
}
.activeNav li::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 85%;
  left: 8%;
  border-bottom: 3px solid var(--primary);
}
.activeNav li span {
  color: var(--primary) !important;
}
.activeNav:hover li span {
  background-color: transparent;
}
.activeNavBtn button {
  background-color: var(--primary) !important;
}
.activeNavBtn1 button {
  font-size: 16px;
  font-weight: 600 !important;
  color: gray !important;
}
.activeNavBtn i {
  color: var(--primary);
}
nav ul button {
  padding: 4px 20px 4px 20px !important;
  margin-right: -10px;
  border-radius: 20px;
  font-size: 1.7rem;
  margin-top: 5px;
  transition: all 0.2s;
}
nav button > .NavLink {
  /* color: black; */
}
.navBar nav ul > div {
  padding-left: 10px;
}
.loginBtnForMobile {
  color: white;
  font-weight: 600;
  margin-top: 20px;
  margin-left: 28px;
  font-size: 15px;
  background-color: #161413;
}
.loginBtn {
  background-color: rgb(210, 210, 210) !important;
  color: var(--primary-act);
  margin-right: 20px;
  color: black;
  font-weight: 600;
}
nav ul button:not(.england):hover,
nav ul button:not(.england):focus {
  background-color: var(--primary) !important;
}

.england {
  position: absolute;
  top: -35px;
  left: 15px;
  background-color: transparent;
  color: rgb(210, 210, 210);
  display: flex;
  justify-content: center;
  align-items: center;
}
.england img {
  width: 40px;
  height: 20px;
  margin-right: 5px;
}
.dustbin {
  position: absolute;
  top: -38px;
  right: 40px;
  background-color: transparent;
  color: rgb(210, 210, 210);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  text-decoration: none;
}
.dustbin3 {
  margin-top: 15px;
  margin-right: 8px;
  margin-left: 43px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  text-decoration: none;
}
.dustbin3 > i {
  color: rgb(238, 238, 238);
}
.dustbinCart {
  position: absolute;
  top: 10px;
  right: -80px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.3rem;
  text-decoration: none;
}
.dustbinCart > i {
  color: rgb(238, 238, 238) !important;
}
.dustbin4,
.dustbin2 {
  position: absolute;
  top: 5px;
  right: -30px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  text-decoration: none;
}
.dustbin4 > i,
.dustbin2 > i {
  color: rgb(210, 210, 210);
}
.dustbin4:hover,
.dustbin:hover,
.dustbin2:hover {
  color: var(--primary);
  text-decoration: none;
}
.regBtn {
  color: var(--primary-act);
  background-color: rgb(210, 210, 210) !important;
}

/* MEDIA QUERY */

@media (max-width: 800px) {
  .navBar header {
    padding: 0 0 5px 0;
  }
  .navBar header .figure {
    margin-left: -20px !important;
    margin-top: -5px !important;
  }
  .navBar header .figure .figcaption {
    font-size: 1.7rem;
    margin-top: 86px;
    margin-left: -60px;
  }
  .england {
    top: -225px !important;
    left: 15px !important;
  }
}
@media (max-width: 1210px) {
  nav ul div button {
    padding: 5px 20px 5px 20px !important;
    border-radius: 20px !important;
    font-size: 1.8rem !important;
    margin-top: 10px;
  }
  .england {
    top: -230px;
    left: 0;
  }
  .navBar nav ul {
    margin-top: 60px;
  }
  nav ul li {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 10px !important;
  }
  nav ul li span {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .navbar-toggler {
    background-color: white;
    font-size: 1.8rem;
    margin-right: 10px;
    margin-top: 60px;
  }
}
@media (max-width: 450px) {
  .dustbin4,
.dustbin2 {
  top: 13px;
}
  .england {
    top: -150px !important;
    left: 15px !important;
  }
  .NavLink {
    text-decoration: none !important;
    font-weight: 400;
  }
  nav ul li span {
    font-size: 1.4rem;
    color: rgb(7, 7, 7);
    transition: all 0.2s;
    padding: 2px 4px 2px 4px !important ;
  }
  /* .navBar header {
    width: 270px;
    margin: 0;
  } */
  .navBar header nav {
    padding-left: 10px;
    background-color: #2a2a2a;
  }
  .navBar header .figure img {
    width: 80px;
    height: 60px;
    position: absolute;
    left: 75px;
  }
  .navBar header .figure .par {
    color: var(--primary);
    font-size: 2.2rem;
    font-weight: 500;
    margin-top: 13px;
  }

  .navBar header .figure .com {
    color: var(--primary);
    font-size: 1.43rem;
    margin-bottom: 2px;
    align-self: flex-end;
  }

  /* .navBar header .figure {
    /* margin-left: 0px !important;    
    margin-top: -5px !important; */
  /* } */
  .navBar header .figure .figcaption {
    font-size: 1.4rem;
    margin-top: 30px;
    margin-left: 0px;
  }

  .navbar-toggler {
    background-color: white;
    font-size: 1.3rem;
    margin-left: 27px;
    margin-top: 10px;
  }
  .dustbin {
    top: -215px;
    left: 20px;
  }
  .dustbin4,
  .dustbin2 {
    right: -35px;
    font-size: 2.3rem;
    text-decoration: none;
  }

  .dustbin4 > i,
  .dustbin2 > i {
    color: rgb(243, 243, 243);
  }
}

@media (max-width: 499px) and (min-width: 401px) {
  .dustbin {
    top: -215px;
    left: -60px;
  }
}
@media (min-width: 700px) and (max-width: 849px) {
  .dustbin {
    top: -215px;
    left: -390px;
  }
  .navBar header {
    width: 98%;
    margin: auto;
  }
}

@media (min-width: 850px) and (max-width: 1200px) {
  .navBar nav ul {
    padding-left: 30px !important;
  }
  .dustbin {
    top: -215px;
    left: -490px;
  }
  .navbar-toggler {
    margin-top: 55px !important;
  }
  .navBar header {
    width: 96%;
    margin: auto;
  }
}

@media (min-width: 1400px) and (max-width: 1440px) {
  .dustbin {
    right: 30px;
  }
  .navBar header {
    width: 93%;
    margin: auto;
  }
}

@media (max-width: 345px) {
  .navBar header nav {
    padding-left: 25px;
  }
  .navBar header {
    width: 280px;
    margin: 0;
  }
  .navBar header .figure img {
    width: 80px;
    height: 60px;
    position: absolute;
    left: 49px;
  }
  .navBar {
    width: 100%;
  }
  .navbar-toggler {
    margin-left: 8px;
  }
}

@media (min-width: 346px) and (max-width: 480px) {
  .navBar header nav {
    padding-left: 40px;
    background-color: #2a2a2a;
  }
  .navBar header {
    width: 310px;
    margin: 0;
  }

  .navBar header .figure img {
    width: 75px;
    height: 59px;
    position: absolute;
    left: 80px;
  }
  .dustbin4,
  .dustbin2 {
    right: -60px;
    font-size: 2.3rem;
    text-decoration: none;
  }

  .dustbin4 > i,
  .dustbin2 > i {
    color: rgb(245, 245, 245);
  }
}
@media (max-width: 376px) {
  .navBar {
    width: 100%;
  }
  .dustbin4,
  .dustbin2 {
    right: -40px;
    font-size: 2.3rem;
    text-decoration: none;
  }
  .dustbin3 {
    margin-right: 20px;
    margin-left: 30px;
  }
}
@media (max-width: 353px) {
  .dustbin4,
  .dustbin2 {
    right: -30px;
    font-size: 2.3rem;
    text-decoration: none;
  }
  .loginBtnForMobile {
    margin-left: 20px;
    margin-right: 10px;
  }
}
@media (min-width: 481px) and (max-width: 600px) {
  .navBar header {
    width: 570px;
  }
  .navBar header nav {
    padding-left: 40px;
  }
}

@media (min-width: 602px) and (max-width: 786px) {
  .navBar header {
    width: 700px;
  }
  .navBar header nav {
    padding-left: 40px;
  }
  .navbar-toggler {
    margin-top: 27px;
  }
  .loginBtnForMobile{
    margin-top: 38px;
    margin-left: 272px;
    margin-right: 52px;
    font-size: 20px;
  }
.dustbin4 {
  top: 42px;
  right: -13px;
  font-size: 3rem;
}

.dustbin2 {
  top: 31px;
  right: -12px;
  font-size: 3rem;
}
.dustbin3{
  margin-top: 38px;
  margin-left: 295px;
  margin-right: 62px;
}
  .navBar header .figure img {
    width: 110px;
    height: 95px;
    position: absolute;
    left: 85px;
  }
  .navBar header .figure .par {
    color: var(--primary);
    font-size: 3.2rem;
    font-weight: 500;
    margin-top: 38px;
  }

  .navBar header .figure .com {
    color: var(--primary);
    font-size: 2.1rem;
    margin-top: 7px;
    margin-bottom: 2px;
    align-self: flex-end;
  }
}
@media (min-width: 800px) and (max-width: 1000px) {
  .navBar header {
    width: 830px;
  }
  .navBar header nav {
    padding-left: 40px;
  }
}
@media (min-width: 1001px) and (max-width: 1100px) {
  .navBar header {
    width: 1010px;
  }
  .navBar header nav {
    padding-left: 10px;
  }
}
@media (min-width: 1101px) and (max-width: 1380px) {
  .navBar header {
    width: 1250px;
  }
  .navBar header nav {
    padding-left: 10px;
  }
}
.select-lang {
  background-color: transparent;
  color: rgb(210, 210, 210);
  border: none;
  font-size: 20px;
  cursor: default;
  font-weight: 600;
}
.select-lang option {
  background-color: black;
}

.bar{
    background-color: var(--primary);
  color: #161413;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  line-height: 23px;
  padding: 5px 20px;
  flex-wrap: wrap;
  height: auto;
  margin-bottom: 3px;
}

@media (max-width:430px){
.bar{
  font-size: 14px;
  line-height: 18px;
  padding: 5px 15px;
  margin-bottom: 4px;

    }
}
.winner {
  width: 100%;
  margin-top: 50px;
}
.winner > div {
  width: 1070px;
  margin: auto;
}
.winner > div > div > h1 {
  font-size: 5rem;
  font-weight: 200;
  color: var(--primary);
}
.winner > div > div:nth-child(1) {
  display: flex;
}
.winner > div > div:nth-child(1) > h1,
.winner > div > div:nth-child(1) > div {
  width: 40%;
  display: flex;
  align-items: center;
}
.winner > div > div:nth-child(1) > div {
  width: 60%;
}

.date {
  padding: 3px 10px 3px 10px;
  background-color: rgb(210, 210, 210);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.date > h1 {
  font-size: 2rem;
  display: flex;
  align-items: center;
  color: black;
  padding-left: 5px;
  font-weight: bold;
  justify-content: center;
  margin: 0;
}
.date > i {
  font-size: 2rem;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  color: black;
}
.winner .swiper:hover .winner-content_hov {
  visibility: visible;
}
.winner .swiper .winner-content_hov {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.68);
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  padding-left: 50px;
  visibility: hidden;
}
.winner .swiper .winner-content_hov h1 {
  font-size: 30px;
  padding: 10px 0;
}
.winner .swiper .winner-content_hov p {
  font-size: 16px;
  margin: 0;
}
.winner .portfolio-item-container {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 50px;
}

.winner .portfolio-item-container > div {
  width: 45%;
  height: 408px;
  margin-top: 30px;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.winner .portfolio-item button {
  padding: 3px 20px 3px 20px;
  font-size: 2rem;
  border-radius: 20px;
  margin-top: 10px;
  background-color: rgb(90, 90, 90);
  font-weight: bold;
}

.winner .swiper {
  width: 100%;
  height: 90%;
  margin-left: auto;
  margin-right: auto;
}

.winner .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: rgb(210, 210, 210);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}
.winner .swiper-slide {
  background-position: center;
  background-size: cover;
}
.winner button:hover {
  background-color: var(--primary);
}
.winner .swiper-slide:hover {
  -webkit-transform: scale(1.07);
          transform: scale(1.07);
}
.winner .swiper-button-next,
.winner .swiper-button-prev {
  border: 2px solid rgb(210, 210, 210);
  border-radius: 50%;
  padding: 20px;
  font-size: 1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-button-next::after,
.winner .swiper-button-prev::after {
  font-size: 3rem;
  color: white;
}
@media (max-width: 1000px) {
  .winner .swiper .winner-content_hov {
    padding-left: 0px;
  }
  .winner .swiper .winner-content_hov h1 {
    font-size: 23px;
    padding: 6px 0;
  }
  .winner .swiper .winner-content_hov p {
    font-size: 16px;
    margin: 0;
  }
}

@media (max-width: 800px) {
  .winner > div {
    text-align: center;
  }
  .winner > div > div:nth-child(1) {
    display: flex;
    flex-direction: column;
  }
  .winner > div > div:nth-child(1) > h1,
  .winner > div > div:nth-child(1) > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .winner > div > div:nth-child(1) > h1 {
    font-size: 3rem;
  }
  .winner .date {
    margin-left: 0 !important;
  }
  .winner .portfolio-item-container {
    justify-content: center;
  }
}

@media (max-width: 345px) {
  .winner > div {
    width: 270px;
    text-align: center;
  }
  .winner .portfolio-item-container > div {
    width: 100%;
    height: 249px;
  }
  .winner .portfolio-item button{
    font-size: 1.4rem;
  }
}

@media (min-width: 346px) and (max-width: 480px) {
  .winner > div {
    width: 310px;
    text-align: center;
  }
  .winner .portfolio-item-container > div {
    width: 100%;
    height: 279px;
  }
  .winner .portfolio-item button{
    font-size: 1.5rem;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .winner > div {
    width: 500px;
  }
  .winner .portfolio-item-container {
    justify-content: space-between;
  }
  .winner .portfolio-item-container > div {
    width: 46%;
    height: 279px;
  }
  .winner .portfolio-item button{
    font-size: 1.5rem;
  }
}

@media (min-width: 601px) and (max-width: 786px) {
  .winner > div {
    width: 650px;
  }

  .winner .portfolio-item-container {
    justify-content: space-between;
  }
  .winner .portfolio-item-container > div {
    width: 46%;
    height: 270px;
  }
  .winner .portfolio-item button {
    font-size: 1.6rem;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .winner > div {
    width: 700px;
  }
  .winner .portfolio-item-container > div {
    width: 46%;
    height: 288px;
  }
  .winner > div > div:nth-child(1) > h1,
  .winner > div > div:nth-child(1) > div {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
}

@media (min-width: 1001px) and (max-width: 1100px) {
  .winner > div {
    width: 900px;
  }
  .winner .portfolio-item-container > div {
    width: 46%;
    height: 357px;
  }
  .winner > div > div:nth-child(1) > h1,
  .winner > div > div:nth-child(1) > div {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
}

.footer {
  padding-top: 1px;
  width: 100%;
  margin: auto;
}
.footer > div {
  width: 1070px;
  margin: auto;
  margin-top: 40px;
}
.footer > div > .btnn {
  display: flex;
  justify-content: center;
  padding: 5px 0;
}
.footer > div > .btnn button {
  font-size: 2rem;
  padding: 5px 120px 5px 120px;
  border-radius: 20px;
  font-weight: 600;
  transition: all 0.2s;
  background-color: rgb(210, 210, 210);
}
.footer > div > .btnn button:hover {
  background-color: var(--primary);
}
.footer .payment-method {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}
.footer .payment-method h3 {
  font-size: 2rem;
  color: rgb(90, 90, 90);
  font-weight: 200;
}
.footer .payment-method div {
  display: flex;
  margin-top: 15px;
}
.footer .payment-method div figure {
  width: 62px;
  height: 40px;
  margin-left: 5px;
  margin-right: 5px;
  transition: all 0.3s;
  background-color: white;
}
.footer .payment-method div figure img {
  width: 100%;
  height: 100%;
}

.find-us {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}
.find-us h4 {
  font-size: 2rem;
  color: rgb(90, 90, 90);
  font-weight: 600;
}
.icons {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.icons span {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: rgb(210, 210, 210);
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icons span a{
  display: flex;
  text-align: center;
}
.icons span i {
  font-size: 2.4rem;
  transition: all 0.2s;
}
.icons span svg{
  width: 28px;
  height: 28px;
}
.icons span:nth-child(1):hover {
  background-color: #3a5794;
  color: white;
}
.icons span:nth-child(2):hover {
  background: linear-gradient(
    45deg,
    #405de6,
    #5851db,
    #c13584,
    #e1308c,
    #fd1d1d
  );
  color: white;
}

.secure {
  text-align: center;
}
.secure h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: rgb(90, 90, 90) !important;
}
.icons span:nth-child(3):hover {
  background-color: #27a831;
  color: white;
}
.telegramLogo {
  color: black;
}
.telegramLogo:hover {
  color: black;
}
.icons span:nth-child(4):hover {
  background-color: #0088cc;
  color: white;
}
.icons span:nth-child(5):hover {
  background-color: #ff0000;
  color: white;
}
.icons span:nth-child(6):hover {
  background: linear-gradient(
    60deg,
    #050505,
    #e692bf,
    hsla(336, 88%, 57%, 0.831),
    hsl(181, 92%, 72%),
    hsla(136, 22%, 52%, 0.725)
  );
  color: white;
}  
.icons span:nth-child(7):hover {
  background: linear-gradient(
    60deg,
    #04dc8d,
    #04dc8d,
    hsl(158, 96%, 44%),
    hsla(158, 96%, 44%, 1)
  );
  color: white;
}
.icon-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.icon-logo figure {
  width: 130px;
  height: 130px;
}
.icon-logo img {
  width: 100%;
  height: 100%;
}

.copyright {
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: space-evenly;
  color: rgb(90, 90, 90);
}
.copyright p {
  font-size: 1.7rem;
  transition: all 0.2s;
  color: rgb(90, 90, 90) !important;
  font-weight: 600;
}
.copyright p a {
  color: rgb(90, 90, 90) !important;
  text-decoration: none;
}
.copyright p:hover,
.copyright p a:hover {
  color: var(--primary);
}
.copyright button{
  font-weight: 500;
  font-size: "2rem" ;
  background-color: #2a2a2a;
  width: 280px;
  height: 25px;
  margin-bottom: 7px;
  color:rgb(210, 210, 210);
}
.copyright button:hover {
  background-color: var(--primary);
  color: rgb(0, 0, 0);
}
@media (min-width: 0px) and (max-width: 800px) {
  .footer {
    margin-top: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer > div {
    width: 90%;
  }
  .footer > div > .btnn button {
    padding: 5px 80px 5px 80px;
    font-weight: 600;
  }
  .footer .payment-method h3 {
    font-size: 1.6rem;
    font-weight: 600;
  }
  .footer .payment-method div figure {
    width: 55px;
  }
  .copyright {
    margin-top: 5px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .copyright p {
    font-size: 1.6rem;
  }
  .icons span svg {
    width: 24px;
    height: 24px;
  }
}

@media (max-width: 345px) {
  .footer > div {
    width: 310px;
  }
  .footer > div > .btnn button {
    padding: 5px 110px 5px 110px;
    font-weight: 600;
  }
  .footer > div > .btnn {
    padding: 5px 0;
  }
  .secure h3 {
    font-size: 1.3rem;
  }
  .copyright p {
    font-size: 1.3rem ;
  }
  .icons span {
    width: 45px;
    height: 34px;
  }
}
@media (min-width: 346px) and (max-width: 480px) {
  .footer > div > .btnn {
    padding: 10px 0;
  }
  .footer > div {
    width: 345px;
    text-align: center !important;
  }
  .footer > div > .btnn button {
    padding: 5px 110px 5px 110px;
    font-weight: 600;
  }
  .secure h3 {
    font-size: 1.3rem;
  }

  .copyright p {
    font-size: 1.3rem ;
  }
  .icons span {
    width: 45px;
    height: 40px;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .footer > div {
    width: 500px;
    text-align: center !important;
  }
  .footer > div > .btnn button {
    font-weight: 600;
    padding: 5px 110px 5px 110px;
  }
  .secure h3 {
    font-size: 1.3rem;
  }

  .copyright p {
    font-size: 1.3rem ;
  }
}

@media (min-width: 601px) and (max-width: 785px) {
  .footer > div {
    width: 650px;
    text-align: center !important;
  }
  .footer > div > .btnn button {
    padding: 5px 110px 5px 110px;
    font-weight: 600;
  }
  .secure h3 {
    font-size: 1.3rem;
  }

  .copyright p {
    font-size: 1.3rem ;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .footer > div {
    width: 700px;
  }
}
@media (min-width: 1001px) and (max-width: 1100px) {
  .footer > div {
    width: 900px;
  }
}

.myacount-con
{
     width: 1070px; 
    margin: auto;
    margin-top: 60px;
    display: flex;
    justify-content: center;
}

@media (max-width:900px)
{
    .myacount-con
    {
        flex-direction: column;
        justify-content: flex-start;
    }
}

@media (max-width:345px)
{
    .myacount-con
    {
        width: 299px;
    }
}


@media (min-width:346px) and (max-width:480px)
{
    .myacount-con
    {
        width: 310px;
    }
}

@media (min-width:481px) and (max-width:600px)
{
    .myacount-con
    {
        width: 500px;
    }
}

@media (min-width:601px) and (max-width:800px)
{
    .myacount-con
    {
        width: 650px;
    }
}

@media (min-width:801px) and (max-width:1000px)
{
    .myacount-con
    {
        width: 700px;
    }
}

@media (min-width:1001px) and (max-width:1100px)
{
    .myacount-con
    {
        width: 900px;
    }
}
.my-acount-sideNav {
  width: 35%;
  height: 430px;
}
.my-acount-sideNav ul {
  width: 65%;
  height: 100%;
  position: relative;
}
.my-acount-sideNav-links {
  color: rgb(210, 210, 210);
}
.my-acount-sideNav .logout {
  position: absolute;
  bottom: 0;
}
.my-acount-sideNav-activelink li {
  color: black;
  background-color: var(--primary) !important;
}
.my-acount-sideNav-activelink:hover {
  color: white !important;
}
.my-acount-sideNav-links:hover {
  color: var(--primary);
  text-decoration: none;
}
.my-acount-sideNav-activelink {
  background-color: var(--primary);
}
.my-acount-sideNav ul li {
  width: 100%;
  padding: 9px 10px 9px 10px;
  font-size: 2rem;
  margin: 8px 0 8px 0;
}
.my-acount-sideNav ul li i {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.fa-alt {
  -webkit-transform: rotate(-30deg);
          transform: rotate(-30deg);
}

@media (max-width: 900px) {
  .my-acount-sideNav {
    width: 100%;
  }
  .my-acount-sideNav ul {
    width: 100%;
  }
  .my-acount-sideNav .logout {
    position: relative;
  }
}

@media (min-width: 700px) and (max-width: 900px) {
  .my-acount-sideNav ul .my-acount-sideNav-links {
    width: 100%;
  }
  .my-acount-sideNav ul .my-acount-sideNav-links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .my-acount-sideNav ul li {
    width: 50%;
  }
}
@media (min-width: 902px) and (max-width: 1100px) {
  .my-acount-sideNav ul {
    width: 90%;
  }
}

@media (max-width: 426px) {
  .my-acount-sideNav ul li {
    width: 100%;
    padding: 5px 5px 5px 5px;
    font-size: 1.5rem;
    margin: 2px 0 2px 0;
  }
  .my-acount-sideNav {
    width: 35%;
    height: 180px;
  }
  .my-acount-sideNav {
    width: 100%;
  }
  .my-acount-sideNav ul {
    width: 100%;
  }
  .my-acount-sideNav .logout {
    position: relative;
  }
}
.my-account-components {
  width: 70%;
  padding-bottom: 60px;
}
@media (max-width: 900px) {
  .my-account-components {
    width: 100%;
  }
}

.address-container {
	display: flex;
	width: 100%; /* Ensure container takes full width */
  }

  .address-container input {
	flex: 1 1; /* Allow input to take available space */
	color: tan;
	border: 1px solid #ccc; /* Optional: add border */
	border-radius: 5px; /* Optional: add border-radius */
  }
  .address-container textarea {
	width: 100%;
	max-width: 700px;
	resize: none; /* Prevent resizing */
  }

.account-detail h2 {
	color: rgb(255, 255, 255);
	padding-top: 6px;
}
.account-detail h1 {
	padding: 9px 10px 9px 10px;
	font-size: 1.8rem;
	background-color: #2a2a2a;
	color: #b0b0b0;
	margin-top: 8px;
	margin-bottom: 8px;
	padding: 1.33rem;
}
.account-detail h1 i {
	margin-right: 10px;
}
.account-detail h1 input {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	background-color: transparent;
	outline: none;
	border: none;
}
.account-detail h1 input::-webkit-input-placeholder {
	color: #b0b0b0;
	text-transform: capitalize;
}
.account-detail h1 input,
.account-detail h1 input::placeholder {
	color: #b0b0b0;
	text-transform: capitalize;
}
.account-detail h1.textarea {
	padding-left: 50px;
	padding-bottom: 40px;
}
.account-detail h1 textarea {
	width: 100%;
	margin: auto;
	background-color: transparent;
	border: none;
	outline: none;
	color: #b0b0b0;
	line-height: 1.6;
	text-transform: capitalize;
}
.account-detail h1 textarea::-webkit-input-placeholder {
	color: #b0b0b0;
}
.account-detail h1 textarea::placeholder {
	color: #b0b0b0;
}
.account-detail button {
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 15px;
	padding-right: 15px;
	font-size: 2rem;
	border-radius: 10px;
	background-color: var(--primary);
	color: black;
	margin-top: 20px;
	margin-bottom: 10px;
}

.pr-2{
	padding-right: 1rem;
}

@media (min-width: 600px) and (max-width: 900px) {
	.account-detail h2 {
		text-align: center;
	}
}

@media (max-width: 344px){
	.account-detail h1 {
		padding: 9px 10px 9px 10px;
		font-size: 1.1rem;
		margin-top: 0px;
		margin-bottom: 2.5px;
		padding: 1.1rem;
	}
	.address-container textarea {
		width: 100%;
		max-width: 500px;
	  }
	  .account-detail button {
		width: 93%;
		margin-left: 10px;
		padding-top: 8px;
		padding-bottom: 8px;
		padding-left: 10px;
		padding-right: 10px;
		font-size: 1.5rem;
		margin-top: 15px;
		margin-bottom: 5px;
	}
	
}
@media (min-width: 345px) and (max-width: 480px){
	.account-detail h1 {
		padding: 9px 10px 9px 10px;
		font-size: 1.2rem;
		margin-top: 0px;
		margin-bottom: 2.6px;
		padding: 1.2rem;
	}
	.address-container textarea {
		width: 100%;
		max-width: 500px;
	  }
	  .account-detail button {
		width: 95%;
		margin-left: 8px;
		padding-top: 8px;
		padding-bottom: 8px;
		padding-left: 10px;
		padding-right: 10px;
		font-size: 1.5rem;
		margin-top: 15px;
		margin-bottom: 5px;
	}
}

.wallet > div:nth-child(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wallet > div:nth-child(1) > h1 {
  color: rgb(210, 210, 210);
  font-size: 2.5rem;
}
.wallet > div:nth-child(1) figure {
  width: 100px;
  height: 100px;
  background-color: #333333;
  border-radius: 50%;
}
.wallet > div:nth-child(1) figure img {
  border-radius: 50%;
}
.wallet > div:nth-child(2) {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.wallet > div:nth-child(2) > .balance-box {
  width: 250px;
  height: 120px;
  background-color: #ffd452;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}
.wallet > div:nth-child(2) > .balance-box h1 {
  font-size: 2.5rem;
}

.wallet > div:nth-child(2) > h1 {
  font-size: 2.5rem;
  margin-top: 40px;
  color: rgb(210, 210, 210);
}
.wallet > h1 {
  color: rgb(210, 210, 210);
  font-size: 2.5rem;
  margin-top: 30px;
}

@media (max-width: 500px) {
  .wallet > div:nth-child(1) > h1 {
    margin-top: 10px;
  }
  .wallet > div:nth-child(1) figure {
    display: none;
  }
  .wallet > div:nth-child(2) > div {
    width: 100%;
    margin-top: 20px;
  }
  .wallet > h1 {
    text-align: center;
  }
}

.withdraw-btn {
  height: 60px;
  margin: 0px 0px 10px 0px;
  cursor: pointer;
  padding: 20px;
  background-color: #ffd452;
  border-radius: 20px;
  display: flex;
  margin: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #161413;
  font-size: 2rem;
}
.withdraw-btn.disabled {
  cursor: not-allowed;
}
.warning {
  color: #ff7000;
  font-size: 1.75rem;
}
.widthdraw-btns {
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.paypal-connected {
  font-size: 1.75rem;
  color: white;
  margin: 10px 0;
  text-align: center;
}
.paypal-connected-email {
  font-size: 1.4rem;
  text-align: center;
  color: #ffffff;
  margin: 10px 0;
}
.widthdraw-errors {
  color: #d2461b;
  padding-top: 20px;
  font-size: 1.5rem;
}

.pagination .page-link {
  color: #d2d2d2; 
  border: 1px solid rgb(153 145 145);
  padding: 1rem 1.5rem !important;
  margin: 0 0rem;
  border-radius: 0.25rem;
  background-color: #2A2A2A;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pagination .page-link:hover {
  background-color: #161413; 
  color: black; 
  border-color: #ccc; 
}

.pagination .page-item.active .page-link {
  background-color: var(--primary); 
  border-color: var(--primary);
  color: black !important; 
}

.pagination .page-item.disabled .page-link {
  color: #161413; 
  background-color: #e9ecef; 
  border-color: #dee2e6; 
}

.pagination .page-link:focus {
  color: #161413;
  font-weight: 600;
  border: #161413;
  box-shadow: 0 0 0 0.2rem rgba(114, 254, 238, 0.911); 
}

.pagination .page-link span {
  vertical-align: middle;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  background-color: #2A2A2A;
  border-radius: 6px !important;
  border-color: #d2d2d2 !important;
}
.page-item:first-child .page-link:hover,
.page-item:last-child .page-link:hover {
  background-color: #484848 !important;
  border-color: #666666 !important;
}

@media (max-width:426px){
  .withdraw-btn {
    height: 40px;
    margin: 0px 0px 35px 0px;
    padding: 10px;
    font-size: 1.6rem;
  }
  .paypal-connected {
    font-size: 1.4rem;
    color: white;
    margin: 8px 0;
    text-align: center;
  }
  
}

.transectionorderdetail {
  background-color: black;
  width: auto;
}

.transectionorderdetail .header {
  background-color: black;
  color: white;
  border-bottom: 1px solid rgb(90, 90, 90);
}

.transectionorderdetail .title {
  font-size: 24px;
}

.transectionorderdetail .body {
  background-color: black;
  color: white;
  font-size: 18px;
}

.transectionorderdetail .button {
  font-size: 18px;
  background-color: rgb(0, 255, 234);
  color: black;
}

/* Responsive Adjustments */
@media (max-width: 450px) {
  .transectionorderdetail .title {
    font-size: 16px;
  }

  .transectionorderdetail .head {
    font-size: 12px;
  }

  .transectionorderdetail .body {
    font-size: 11px;
  }

  .transectionorderdetail .button {
    font-size: 11px;
    font-weight: 500;
  }
}


.transection-section {
  margin-top: 8.1px;
  /* overflow-x: scroll; */
}
.overflower-video1,
.overflower-video{
  width: 713px;
  display: flex;
  flex-direction: column;
}
.transection-head1,
.transection-head{
  display: flex;
  flex-direction: row;
}
.transection-col1 .col,
.transection-col .col{
  margin: 0px;
  color: #161413;
   background-color: #ffd452;
   border: 1px solid #5a5a5a;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0px;
}
.transection-row-col .col,
.transection-row1 .col,
.transection-row .col{
  margin: 0px;
  color: white;
   border: 1px solid #5a5a5a;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0px;
}

.transection-head1,
.transection-head,
.transection-detail1,
.transection-detail {
  min-height: 7vh;
  width: 100%;
  display: flex;
}

.transection-head1 h1,
.transection-head h1{
  font-size: 2rem;
}

.transection-detail1>div ,
.transection-detail>div {
  border: 1px solid #5a5a5a;
  display: flex;

  align-items: center;
  justify-content: center;
  color: rgb(210, 210, 210);
  padding: 0px;
}
.transection-head1>div>h2,
.transection-head>div>h2 {
  color: var(--primary);
  font-size: 1.5rem;
}
.transection-detail1>div>h1,
.transection-detail>div>h1 {
  font-size: 1.5rem;
  overflow-wrap: anywhere;
}

.transection-detail1>div>p,
.transection-detail>div>p {
  font-size: 1.5rem;
  font-weight: 500;
}

.modal-backdrop.show {
  opacity: .1 !important;
}
.detail h3{
  color: rgb(210,210,210);
   margin: "10px";
  font-size: 1.75rem;
}
.table-responsive {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: auto;
  max-width: 800px;
  border: 1px solid rgb(90, 90, 90);
}

.transectionorderdetail .modal-content {
  border: 1px solid rgb(90, 90, 90);
}

.transectionorderdetail .close {
  font-size: 30px;
  color: rgb(0, 255, 234);
  opacity: 1;
}

/* Pagination CSS */

.pagination .page-link {
  color: #d2d2d2; 
  border: 1px solid rgb(153 145 145);
  padding: 1rem 1.5rem !important;
  margin: 0 0rem;
  border-radius: 0.25rem;
  background-color: #2A2A2A;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pagination .page-link:hover {
  background-color: #161413; 
  color: black; 
  border-color: #ccc; 
}

.pagination .page-item.active .page-link {
  background-color: var(--primary); 
  border-color: var(--primary);
  color: black !important; 
}

.pagination .page-item.disabled .page-link {
  color: #d2d2d2 !important; /* Make sure color is not overridden */
  background-color: #565656 !important; /* Same for background color */
  border-color: #656565 !important;
}

.pagination .page-link:focus {
  color: #161413;
  font-weight: 600;
  border: #161413;
  box-shadow: 0 0 0 0.2rem rgba(114, 254, 238, 0.911); 
}

.pagination .page-link span {
  vertical-align: middle;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  padding: 1rem 1.2rem !important;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  background-color: #2A2A2A;
  border-radius: 6px !important;
  border-color: #d2d2d2 !important;
}
.page-item:first-child .page-link:hover,
.page-item:last-child .page-link:hover {
  background-color: var(--primary) !important;
  color:#161413 !important;
  border-color: #666666 !important;
}

@media (max-width: 800px) {
  .transection-detail {
    flex-direction: column;
  }
  .transection-table{
    overflow-x: scroll;
  }
  .transection-head1 h1,
  .transection-head h1{
    font-size: 1.3rem;
  }
  .overflower-video{
    width: 300px;
    display: flex;
    flex-direction: row;
  }
  .overflower-video1{
    width: 375px;
  }
  .transection-head1 .amount,
.transection-detail1 .amount {
  flex: 0 0 22%;
  max-width: 22%;
}
  .transection-detail .col {
    margin: 0px 5px 5px 5px;
    color: white;
    border: 1px solid #4a4a4a; 
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 5px; 
    background-color: #333;
    font-size: 14px; 
    white-space: nowrap; 
    overflow: hidden; 
  }
  .transection-head>div {
  padding: 20px 10px 20px 10px;
}

.transection-col .col{
  padding: 20px 45px 20px 45px;
  margin: 0px 10px 5px 5px;
}
.transection-col1 .col{
  padding: 20px 10px 20px 20px;
  margin: 12px 10px 5px 5px;
}
.transection-row-col .col{
  padding: 20px 10px 20px 20px;
  margin: 12px 52px 5px 5px;
}
.transection-row .col{
  padding: 20px 10px 20px 20px;
  margin: 0px 10px 5px 5px;
}
.detail h3{
  font-size: 1.4rem;
}
.transection-head1>div>h2,
  .transection-head>div>h2 {
    color: var(--primary);
    font-size: 1rem;
  }
  .transection-detail1>div>h1,
  .transection-detail>div>h1 {
    font-size: 1.2rem;
  }
  .transection-head{
    display: flex;
    flex-direction: column;
  }
  .transection-head1,
  .transection-detail1 {
    min-height:5.5vh;
}
  .transection-detail1>div>p,
  .transection-detail>div>p {
    font-size: 1rem;
  }
  .setWidth{
    width: 550px;
  }
  .pagination .page-link {
    color: #d2d2d2; 
    border: 1px solid rgb(153 145 145);
    padding: 0.5rem 0.8rem !important;
    margin: 0 0rem;
    border-radius: 0.25rem;
    background-color: #2A2A2A;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  .pagination .page-link:focus {
    color: #161413;
    font-weight: 500;
    border: #161413;
    box-shadow: 0 0 0 0.2rem rgba(114, 254, 238, 0.911); 
  }
  .page-item:first-child .page-link,
  .page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-radius: 3px !important;
    padding: 0.5rem 0.8rem !important;
    background-color: #2A2A2A;
    border-color: #d2d2d2 !important;
    
  }
  @media (max-width: 324px) {
    .transection-col .col{
      margin: 0px 8px 5px 2px;
    }
  }
}
.mytickets>div:nth-child(1)
{
    display: flex;
    justify-content: center;
    padding: 0px 0 6px 0 !important;
}
.mytickets>div:nth-child(1) button
{
    padding: 10px 50px 10px 50px;
    margin-left: 10px;
    font-size: 1.5rem;
    background-color: #2A2A2A;
    color: rgb(210, 210, 210);
    cursor: pointer;
}
.mytickets>div:nth-child(1) button:hover
{
    background-color: var(--primary);
}
.ticketsdetail
{
    margin-top: 10px;
}

.tickets-head
{
    min-height: 9vh;
    display: flex;
}
.tickets-head>div
{
    border: 1px solid rgb(90,90,90);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}
.tickets-head>div>h1
{
    color: var(--primary);
    margin-bottom: 0;
    font-size: 1.5rem;
}
.ticketActiveNav button
{
    color:  black !important;
    background-color: var(--primary) !important;
} 
@media (max-width:800px)
{
    .tickets-head>div>h1
{
    color: var(--primary);
    font-size: 1rem;
}
.tickets-head>div>h1
{
    font-size: 1rem ;
}
.mytickets>div:nth-child(1) button
{
    padding: 10px 45px 10px 45px;
}
 
}
.FAQ {
  /* width: 85.5%; */
  width: 1070px;
  margin: auto;
  margin-top: 80px;
  padding-left: 40px;
  padding-bottom: 80px;
}
.FAQ h1 {
  font-size: 5rem;
  color: var(--primary);
}
.FAQ > div {
  width: 80% !important;
}
.FAQ > div h5 {
  font-size: 2.3rem;
  color: rgb(210,210,210);
}
.FAQ .container {
  margin-top: 50px !important;
}
.card .card-header {
  background-color: transparent;
  padding: 10px;
}
.card-header a:hover {
  text-decoration: none;
}
.card .card-header a {
  display: flex;
  justify-content: space-between;
}
.card-header a i {
  font-size: 3rem;
  color: rgb(210,210,210);
}
.FAQ .card {
  background-color: transparent;
  border: none;
  background-color: none;
  border-bottom: 1px solid rgb(90,90,90);
}
.card-body p {
  font-size: 1.7rem;
  color: rgb(210,210,210);
}
[data-toggle="collapse"][aria-expanded="true"]  .rotate-icon {
  display: inline-block;
  transition: -webkit-transform 0s ease;
  transition: transform 0s ease;
  transition: transform 0s ease, -webkit-transform 0s ease;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
@media (max-width: 900px) {
  .FAQ {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
    margin-top: 20px;
  }
  .FAQ h1 {
    font-size: 3rem;
  }
  .FAQ > div {
    width: 100% !important;
  }
  .FAQ > div h5 {
    font-size: 1.6rem;
  }
  .FAQ .container {
    margin-top: 30px !important;
  }
  .card-body p {
    font-size: 1.5rem;
  }
}

@media (min-width:346px) and (max-width:480px)
{
  .FAQ
  {
    margin: 20px 15px;
    width: 370px;
  }
}

@media (max-width:384px){
  .FAQ
  {
    width: 340px;
  }
}
@media (max-width:345px)
{
  .FAQ
  {
    margin: 20px 5px;
    width: 300px;
  }
}
@media (min-width:481px) and (max-width:600px)
{
  .FAQ
  {
    width: 500px;
  }
}
@media (min-width:601px) and (max-width:786px)
{
  .FAQ
  {
    width: 650px;
  }
}
@media (min-width:800px) and (max-width:1000px)
{
  .FAQ
  {
    width: 700px;
  }
}
@media (min-width:1001px) and (max-width:1100px)
{
  .FAQ
  {
    width: 900px;
  }
}
.signUp {
  padding-bottom: 60px;
}

.form-modal {
  position: relative;
  width: 400px;
  height: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: #fff;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0 3px 20px 0px rgba(60, 237, 183, 0.2);
}
.form-modal button {
  cursor: pointer;
  position: relative;
  text-transform: capitalize;
  font-size: 4rem;
  z-index: 2;
  outline: none;
  background: #fff;
  transition: 0.2s;
}
.btnGroup {
  display: flex;
  justify-content: flex-start;
}
.form-modal .btn {
  border-radius: 20px;
  border: none;
  font-size: 1.6rem;
  transition: 0.5s;
  border: 1px solid #ebebeb;
}
.btnGroup > .btn {
  width: 48% !important;
  padding: 0 !important;
}
.btnGroup > .btn:nth-child(2) {
  margin-left: 10px;
}
.form-modal .signup {
  background: var(--primary);
  color: black;
}

.form-modal .signup:hover {
  background: #222 !important;
  color: white;
}

.form-toggle {
  position: relative;
  width: 100%;
  height: auto;
}
.form-toggle button {
  width: 100%;
  float: left;
  padding: 0.2rem;
  margin-bottom: 1em;
  border: none;
  transition: 0.2s;
  font-size: 3rem;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

#signup-toggle {
  background: var(--primary);
  color: black;
}

.form-modal form {
  position: relative;
  width: 90%;
  height: auto;
  left: 50%;
  top: -30px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  flex-direction: column;
}
.form-modal input {
  position: relative;
  width: 100%;
  padding: 0.5em 0.5em 0.5em 0.5em;
  margin-top: 0em;
  margin-bottom: 0.3em;
  border-radius: 20px;
  border: none;
  background: #ebebeb;
  outline: none;
  transition: 0.4s;
}
.form-modal input:hover {
  box-shadow: 0 10px 5px 0px rgba(60, 237, 183, 0.2);
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
}
.form-modal input {
  font-size: 1.5rem;
}
.form-modal input::-webkit-input-placeholder {
  color: #222;
  font-size: 1.5rem;
}

.rc-code {
  width: 100% !important;
}

.signUp .form-modal p {
  font-size: 1.6rem;
  padding-left: 17px;
}

.signUp .form-modal p a {
  text-decoration: none;
  color: black;
  font-size: 1.6rem;
  transition: 0.2s;
}

.signUp .form-modal p a:hover {
  color: black;
}

.form-modal i {
  margin-left: 12px;
  margin-right: 10px;
  font-size: 3rem;
}
.signUp .fa-facebook-square {
  color: #0072c6;
}

.box-sd-effect:hover {
  box-shadow: 0 10px 5px 0px rgba(60, 237, 183, 0.2);
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
}
.createacnt {
  font-weight: bold;
}
.btnGroup > button:nth-child(2),
.btnGroup > button:nth-child(1) {
  display: flex;
  align-items: center;
  padding: 0px !important;
}
.btnGroup > button:nth-child(1) > img {
  margin-right: 10px;
  width: 15%;
  height: 80%;
  margin-left: 12px;
}

.password-input {
  position: relative;
  display: inline-block;
}

.password-input i {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  font-size: 2.2rem;
}

@media only screen and (max-width: 350px) {
  .signUp .form-toggle button {
    font-size: 2.4rem;
  }
  #signup-form p {
    font-size: 1.7rem;
  }
  .box-sd-effect i {
    padding-left: -10px !important;
    margin-right: 20px !important;
  }
  .box-sd-effect {
    font-size: 1.4em !important;
  }

  .form-modal i {
    margin-right: 5px !important;
  }
}

.logIn
{
    padding-bottom:60px;
}

.form-modal {
  position: relative;
  width: 400px;
  height: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: #fff;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0 3px 20px 0px rgba(60, 237, 183,0.2);
}
.form-modal button {
  cursor: pointer;
  position: relative;
  text-transform: capitalize;
  font-size: 4rem;
  z-index: 2;
  outline: none;
  background: #fff;
  transition: 0.2s;
}

.form-modal .btn {
  border-radius: 20px;
  border: none;
  font-size: 1.6rem;
  padding: 0.5em 1em 0.5em 1em !important;
  transition: 0.5s;
  border: 1px solid #ebebeb;
  margin-bottom: 0em;
  margin-top: 0.5em;
}

.form-modal .login {
  background: var(--primary);
  color: black;
}

.form-modal .login:hover {
  background: #222;
  color: white;
}

.form-toggle {
  position: relative;
  width: 100%;
  height: auto;
}

.form-toggle button {
  width: 100%;
  float: left;
  padding:1.2rem;
  margin-bottom: 1em;
  border: none;
  transition: 0.2s;
  font-size: 3rem;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}


#login-toggle {
  background: var(--primary);
  color: black;
}


.form-modal form {
  position: relative;
  width: 90%;
  height: auto;
  left: 50%;
  top: -20px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  flex-direction: column;
}

#login-form {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 1em;
}


#login-form button
{
  width: 100%;
  margin-top: 0.5em;
  padding: 0.6em;
}

.form-modal input {
  position: relative;
  width: 100%;
  margin-top: 0em;
  margin-bottom: 0.6em;
  border-radius: 20px;
  border: none;
  background: #ebebeb;
  outline: none;
  transition: 0.4s;
  padding-left: 18px;
}

.form-modal input
{
  font-size: 1.5rem;
}
.form-modal input::-webkit-input-placeholder {
  color: #222;
  font-size: 1.7rem;
}

.logIn .form-modal p {
  font-size: 1.6rem;
  padding-left: 20px;
}
.logIn .form-modal p a {
  color: black;
  text-decoration: none;
  transition: 0.2s;
}

.logIn .form-modal p a:hover {
  color: var(--primary);
}


.fa-google {
  color: #dd4b39;
} 

.box-sd-effect .fa-facebook-square {
  color: #0072c6;
}

.box-sd-effect:hover {
  box-shadow: 0 10px 5px 0px rgba(60, 237, 183,0.2);
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
}
.login
{
  font-weight: bold;
}

@media only screen and (max-width: 500px) {
    .logIn
    {
        padding-bottom:60px;
    }
    .form-modal {
    width: 80%;
  }
  #login-toggle
  {
      font-size: 3rem;
  }
  input
  {
      margin: 0;
  }
  #login-form
  {
      padding: 0;
  }
  .box-sd-effect i
  {
    padding-left: -10px !important;
  }
  .box-sd-effect
  {
    font-size: 1.4em !important;
  }

}


.page-giftshopCon {
  padding-top: 1px;
  width: 1070px;
  margin: auto;
  margin-top: 50px;
  border-bottom: 1px solid white;
}
.page-giftshopCon h1 {
  align-self: center;
  font-size: 5rem;
  font-weight: 200 !important;
  color: var(--primary);
}
.page-giftshop {
  width: 100%;
  min-height: 250px;
  border-top: 1px solid #d2d2d2;
  /* margin-top: 20px; */
  display: flex;
  align-items: flex-start;
}
.page-giftshop .video{
  width: 272px;
  height: 204px
}
.page-giftshop > div {
  min-height: 250px;
}
.page-giftshop > div:nth-child(1) {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.expand-content {
  overflow: hidden;
  /* text-overflow:inherit; */
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.gift-shop-modal .modal-content {
  height: 350px !important;
  overflow-y: scroll !important;
  background-color: #2a2a2a;
  color: #d2d2d2;
}
.gift-shop-modal .modal-content .modal-body p {
  font-size: 14px;
}



video {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 85%;
  aspect-ratio: 13 / 8;
  object-fit: cover;
}
.page-giftshop > div:nth-child(2) {
  width: 69%;
  /* padding-top: 30px; */
}
.page-giftshop > div:nth-child(1) > figure {
  width: 80%;
  /* height: 210px; */
  margin: 0;
  background-position: center;
  background-size: cover;
  aspect-ratio: 1.33/1;
}
.page-giftshop > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #d2d2d2;
  position: relative;
}
.page-giftshop > div:nth-child(2) > h1 {
  font-size: 3rem;
  align-self: flex-start;
}
.page-giftshop > div:nth-child(2) > h2 {
  margin: 0;
  font-size: 3rem;
}
.page-giftshop > div:nth-child(2) > p {
  font-size: 1.8rem;
}
.page-soldout {
  position: absolute;
  left: -15px;
  bottom: 100px;
  width: 50%;
  height: 50px;
  background-color: var(--primary);
  -webkit-transform: rotate(-20deg);
          transform: rotate(-20deg);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.1rem;
  color: black;
}

.addCartbtn,
.addCart {
  padding: 5px 20px;
  align-self: flex-end;
  border-radius: 20px;
  font-size: 1.7rem;
  background-color: rgb(210, 210, 210);
  font-weight: 600;
  cursor: pointer;
}

.addCartbtn{
 margin-top: 65px;
 margin-left: 574px;
}

.addCartbtn:hover,
.addCart:hover {
  background-color: var(--primary) !important;
}

.addCartbtn:focus,
.addCart:focus {
  outline: none;
  background-color: rgb(180, 180, 180) !important; 
}

.addCartbtn:active, 
.addCart:active {
  background-color: var(--secondary) !important;
}


@media (max-width: 600px) {
  .page-giftshopCon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .page-giftshop {
    display: flex;
    flex-direction: column;
  }
  .page-giftshop > div {
    width: 100% !important;
    align-items: center;
  }
}

@media (max-width: 345px) {
  .page-giftshop .video{
    width: 240px;
    height: 180px
  }
  .page-giftshopCon {
    width: 270px;
    margin-top: 15px;

  }
  .page-giftshop {
    min-height: 600px;
  }
  .page-giftshop > div:nth-child(2) {
    min-height: 310px;
  }
  video {
    width: 100%;
  }
  .page-giftshop > div:nth-child(1) > figure {
    width: 100%;
    /* height: 170px; */
    margin: 0;
  }
  .page-giftshop > div:nth-child(2) h2 {
    font-size: 2.5rem;
    text-align-last: left;
  }

  .page-giftshop > div:nth-child(2) p {
    font-size: 1.5rem;
    width: 275px;
  }
  .page-giftshop > div:nth-child(2) button {
    margin-top: 30px;
    align-self: center;
  }
  .page-soldout {
    width: 100%;
    font-size: 1.7rem;
    left: 6px;
    bottom: 110px;
    height: 40px;
  }
  .addCartbtn{
    margin-left: 60px;
   }
}

@media (min-width: 346px) and (max-width: 480px) {
  .page-giftshopCon {
    width: 310px;
    margin-top: 15px;
  }
  .page-giftshop {
    min-height: 550px;
  }
  .page-giftshop > div:nth-child(2) {
    min-height: 310px;
  }
  video {
    width: 100%;
  }
  .page-giftshop > div:nth-child(1) > figure {
    width: 95%;
    /* height: 190px; */
    margin: 0;
  }
  .page-giftshop > div:nth-child(2) h2 {
    font-size: 2.5rem;
  }
  .page-giftshop .video{
    width: 280px;
    height: 210px
  }

  .page-giftshop > div:nth-child(2) p {
    font-size: 1.5rem;
    width: 309px;
  }
  .page-giftshop > div:nth-child(2) button {
    margin-top: 30px;
  }
  .page-soldout {
    width: 100%;
    font-size: 1.7rem;
    left: 6px;
    bottom: 110px;
    height: 40px;
  }
  .addCartbtn{
    margin-left: 146px;
   }
}

@media (min-width: 481px) and (max-width: 600px) {
  .page-giftshopCon {
    width: 500px;
  }

  .page-giftshop > div:nth-child(2) {
    min-height: 410px;
  }
  .page-giftshop > div:nth-child(1) > figure {
    width: 70%;
    /* height: 250px; */
    margin: 0;
  }
  video {
    width: 100%;
  }
  .page-giftshop > div:nth-child(2) h2 {
    font-size: 2.5rem;
  }

  .page-giftshop > div:nth-child(2) p {
    font-size: 1.5rem;
  }
  .page-giftshop > div:nth-child(2) button {
    margin-top: 30px;
  }
  .page-soldout {
    width: 60%;
    font-size: 1.7rem;
    left: 130px;
    bottom: 170px;
    height: 40px;
  }
}

@media (min-width: 601px) and (max-width: 800px) {
  .page-giftshopCon {
    width: 650px;
  }
  .page-giftshop > div:nth-child(1) {
    width: 100%;
    height: 250px;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
  }
  video {
    width: 80%;
  }
  .page-giftshop > div:nth-child(2) {
    padding-left: 20px;
    padding-top: 45px;
  }
  .page-giftshop > div:nth-child(1) > figure {
    margin-top: 35px;
    width: 100%;
    /* height: 85%; */
  }
  .page-giftshop > div:nth-child(2) h2 {
    font-size: 3rem;
  }
  .page-giftshop > div:nth-child(2) p {
    font-size: 2rem;
  }
  .page-giftshop > div:nth-child(2) button {
    margin-top: 30px;
  }
  .page-soldout {
    width: 100%;
    font-size: 1.9rem;
    left: 10px;
    bottom: 140px;
    height: 40px;
  }
}

@media (min-width: 801px) and (max-width: 900px) {
  .page-giftshopCon {
    width: 650px;
  }
  .page-giftshop > div:nth-child(1) {
    width: 50%;
    justify-content: center;
    align-items: center;
  }
  .page-giftshop > div:nth-child(2) {
    padding-left: 20px;
  }
  .page-giftshop > div:nth-child(1) > figure {
    width: 100%;
    /* height: 75%; */
  }
  .page-giftshop > div:nth-child(2) h2 {
    font-size: 3rem;
  }
  .page-giftshop > div:nth-child(2) p {
    font-size: 2rem;
  }
  .page-giftshop > div:nth-child(2) button {
    margin-top: 30px;
  }
  .page-soldout {
    width: 100%;
    font-size: 1.9rem;
    left: 10px;
    bottom: 140px;
    height: 40px;
  }
}
@media (min-width: 801px) and (max-width: 1000px) {
  .page-giftshopCon {
    width: 700px;
  }
  .page-giftshop > div:nth-child(1) {
    width: 50%;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 1001px) and (max-width: 1100px) {
  .page-giftshopCon {
    width: 900px;
  }
  .page-soldout {
    width: 60%;
    font-size: 1.7rem;
    left: -15px;
    bottom: 100px;
    height: 40px;
  }
  .page-giftshop > div:nth-child(1) {
    width: 50%;
    justify-content: center;
    align-items: center;
  }
  .addCartbtn{
    margin-top: 40px;
    margin-left: 355px;
   }
}
@media scerrn and (min-width: 1102px) {
  .page-giftshop > div:nth-child(1) {
    width: 50%;
    justify-content: center;
    align-items: center;
  }

}

.how-to-play {
  width: 100%;
  min-height: 30vh;
  margin: auto;
  margin-top: 15px;
}
.how-to-play > div {
  width: 1070px;
  margin: auto;
}
.how-to-play > div > div h5 {
  font-size: 3rem;
  color: var(--primary);
}
.how-to-play > div > div p {
  color: rgb(210, 210, 210);
  font-size: 1.7rem;
}

@media (max-width: 800px) {
  .how-to-play > div > div h5 {
    font-size: 2.2rem;
  }
  .how-to-play > div > div p {
    font-size: 1.4rem;
  }
}

@media (max-width:345px)
{
  .how-to-play>div
  {
    width: 270px;
    text-align: center;
  }
}

@media (min-width: 346px) and (max-width: 480px) {
  .how-to-play > div {
    width: 310px;
    text-align: center;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .how-to-play > div {
    width:500px;
  }
}

@media (min-width: 800px) and (max-width:1000px) {
  .how-to-play > div {
    width: 700px;
  }
  .how-to-play > div > div h5 {
    font-size: 2.2rem;
  }
  .how-to-play > div > div p {
    font-size: 1.4rem;
  }
}

@media (min-width: 1001px) and (max-width:1100px) {
  .how-to-play > div {
    width: 900px;
  }
}

@media (min-width: 601px) and (max-width:786px) {
  .how-to-play > div {
    width:650px;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .how-to-play > div > div > h5 {
    font-size: 2.2rem;
  }
  .how-to-play > div > div p {
    font-size: 1.4rem;
  }
}

.h-hero-section {
  width: 100%;
  margin-top: 30px;
}
.h-hero-section > div {
  width: 1070px;
  margin: auto;
  position: relative;
}
.h-hero-section > div > h1 {
  font-size: 5rem;
  color: var(--primary) !important;
  font-weight: 200;
}

.hero.swiper {
  width: 100%;
  /* height: 600px; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}
.hero.siwper .swiper-wrapper {
  width: 100%;
  height: 100%;
  border: 1px solid red;
}
.hero.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  /* border: 1px solid red; */
  align-items: center;
}
.hero.swiper-slide {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.hero.swiper-slide img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero.swiper-slide img{
  width: 798px;
  height: 600px;
}
.swiper-slide button {
  font-size: 1.9rem;
  font-weight: 600;
  outline: none;
  border: none;
  border-radius: 30px;
  padding: 3px 20px 3px 20px;
  background-color: var(--primary);
  transition: all 0.2s;
}
.swiper-slide button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  cursor: pointer;
}
.hero.swiper-pagination {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  top: 65px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.swiper-pagination-bullet {
  padding: 7px;
  border: 2px solid rgb(210, 210, 210);
  outline: none;
  margin-left: 5px;
}
.swiper-pagination-bullet-active {
  background: gray;
}

@media (min-width: 0px) and (max-width: 800px) {
  .h-hero-section .hero.swiper {
    width: 90%;
    margin-top: 30px;
  }
  .h-hero-section .hero.swiper-pagination {
    top: 26% !important;
  }
  .h-hero-section > div > h1 {
    font-size: 3rem;
    text-align: center;
    font-weight: 200;
  }
  .h-hero-section .swiper button {
    font-size: 2rem;
  }
}

@media (max-width: 345px) {
  .hero.swiper-slide img{
    width: 319px;
  }
  .h-hero-section > div {
    /* width: 280px; */
    width: 100%;
  }
  .h-hero-section .hero.swiper {
    width: 100%;
    margin-top: 30px;
  }
  .h-hero-section .hero.swiper img{
    height: 240px;
  }
  .h-hero-section .hero.swiper-pagination {
    top: 55px !important;
  }
}

@media (min-width: 346px) and (max-width: 480px) {
  .hero.swiper-slide img{
    width: 373px;
  }
  .h-hero-section > div {
    /* width: 310px; */
    width: 100%;
  }
  .h-hero-section .hero.swiper {
    width: 100%;
    margin-top: 30px;
  }
  .h-hero-section .hero.swiper img{
    height: 281px;
  }
  .h-hero-section .hero.swiper-pagination {
    top: 55px !important;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .h-hero-section > div {
    width: 500px;
  }
  .h-hero-section .hero.swiper {
    width: 100%;
    margin-top: 30px;
  }
  .hero.swiper-slide img{
    width: 558px;
  }
  .h-hero-section .hero.swiper img{
    height: 420px;
  }
  .h-hero-section .hero.swiper-pagination {
    top: 55px !important;
  }
}

@media (min-width: 602px) and (max-width: 786px) {
  .h-hero-section > div {
    width: 650px;
  }
  .h-hero-section > div > h1 {
    text-align: start;
  }
  .h-hero-section .hero.swiper {
    width: 100%;
    margin-top: 30px;
  }
  .hero.swiper-slide img{
    width: 452px;
  }
  .h-hero-section .hero.swiper img{
    height: 340px;
  }
  .h-hero-section .hero.swiper-pagination {
    top: 55px !important;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .h-hero-section > div {
    width: 700px;
  }
  .h-hero-section > div > h1 {
    text-align: start;
  }
  .h-hero-section .hero.swiper {
    width: 100%;
    margin-top: 30px;
  }
  .h-hero-section .hero.swiper img{
    height: 340px;
  }
  .h-hero-section .hero.swiper-pagination {
    top: 55px !important;
  }
}

@media (min-width: 1001px) and (max-width: 1100px) {
  .h-hero-section > div {
    width: 900px;
  }
  .h-hero-section > div > h1 {
    text-align: start;
  }
  .h-hero-section .hero.swiper {
    width: 100%;
    margin-top: 30px;
  }
  .hero.swiper-slide img{
    width: 665px;
  }
  .h-hero-section .hero.swiper img{
    height: 500px;
  }
  .h-hero-section .hero.swiper-pagination {
    top: 75px !important;
  }
}
.main-section {
  width: 100%;
  min-height: 60vh;
}
.main-section .home-section1 {
  width: 1070px;
  margin: auto;
  margin-top: 50px;
}
.home-section1 div h1 {
  color: var(--primary);
  font-size: 5rem;
  margin-bottom: 30px !important;
  margin: -4px;
  font-weight: 200;
}
.home-section1 div p span {
  color: var(--primary);
  font-size: 3.5rem;
  font-weight: 600;
  padding-right: 1rem;
}
.home-section1 div p {
  font-weight: 400;
}
.home-section1 div p span.number:hover {
  cursor: pointer;
  color: #00b67a;
}
.home-section1 div p {
  color: rgb(210, 210, 210);
  font-size: 1.8rem;
}
.home-section1 h4 {
  color: rgb(210, 210, 210);
  font-size: 2.7rem;
  font-weight: 700;
}
.home-section1 .stars i {
  font-size: 3rem;
  background-color: #00b67a;
  color: white;
  padding: 5px;
  margin-left: 5px;
}
.home-section1 .sub-section {
  width: 62%;
  border: 1px solid var(--primary);
  margin-top: 30px;
}
.home-section1 .sub-section .img-fluid{
  width: 100%;
  height: auto;
}
.trustpilot {
  padding: 5px;
  display: flex;
  align-items: center;
}
.trustpilot i {
  font-size: 3rem;
  color: #00b67a;
}
.trustpilot h5 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: rgb(210, 210, 210);
  font-size: 2rem;
}
.home-section1 .like,
.home-section1 .comunity {
  padding: 3px 20px 3px 20px;
  font-size: small;
  font-weight: 600;
  margin-left: 8px;
  background-color: #367ef3;
  border-radius: 5px;
  transition: all 0.2;
}
.home-section1 .like i,
.home-section1 .comunity i {
  color: white;
  margin-right: 10px;
}
.home-section1 .like:hover,
.home-section1 .comunity:hover {
  /* transform: scale(1.05); */
  background-color: rgb(23, 84, 181);
}
.home-section1 .newsletter {
  margin-top: 80px;
  margin-bottom: 50px;
}
.home-section1 .newsletter button {
  padding: 5px 30px 5px 30px;
  font-size: 1.5rem;
  border-radius: 20px;
  transition: all 0.2s;
  background-color: rgb(210, 210, 210);
  font-weight: 600;
}
.home-section1 .newsletter button:hover {
  background-color: var(--primary);
}

@media (max-width: 800px) {
  .home-section1 div h1 {
    color: var(--primary);
    font-size: 3rem;
  }
  .home-section1 div p span {
    color: var(--primary);
    font-size: 2.6rem;
    font-weight: 600;
  }

  .home-section1 div p {
    color: rgb(210, 210, 210);
    font-size: 1.9rem;
  }
  .home-section1 div p:not(.last) {
    display: flex;
    flex-direction: column;
  }
  .home-section1 > div > p:nth-child(5) {
    line-height: 1.2;
  }
  .sub-section h4 {
    font-size: 2rem;
  }
  .sub-section h4,
  .stars {
    text-align: center;
  }
  .btn-group {
    display: flex;
    justify-content: center;
  }
  .home-section1 .like,
  .home-section1 .comunity {
    padding: 8px 10px 8px 10px !important;
    font-size: 1rem;
    background-color: #4287f5;
  }
  .home-section1 .like i,
  .home-section1 .comunity i {
    color: white;
    margin-right: 5px;
  }
  .home-section1 .newsletter {
    width: 100% !important;
    margin-top: 40px;
  }
  .home-section1 .newsletter button {
    padding: 5px 30px 5px 30px;
    font-size: 1.5rem;
  }
  .home-section1 .sub-section .img-fluid{
    width: 132px;
    height: 132px;
  }
}

@media (max-width: 345px) {
  .main-section .home-section1 {
    width: 270px;
    text-align: center !important;
  }
  .home-section1 div h1 {
    color: var(--primary);
    font-size: 3rem;
  }
  .sub-section {
    width: 100% !important;
  }
}

@media (min-width: 346px) and (max-width: 480px) {
  .main-section .home-section1 {
    width: 310px;
    text-align: center !important;
  }
  .sub-section {
    width: 100% !important;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .main-section .home-section1 {
    width: 500px;
    text-align: center;
  }
  .sub-section {
    width: 100% !important;
    height: 40vh !important;
    margin-top: 30px;
  }
  .main-section .sub-section h4 {
    text-align: start !important;
  }
  .main-section .btn-group {
    justify-content: flex-start;
  }
}

@media (min-width: 601px) and (max-width: 786px) {
  .main-section .home-section1 {
    width: 650px;
  }
  .main-section .sub-section {
    width: 90%;
  }
  .main-section .sub-section h4 {
    text-align: start !important;
  }
  .main-section .btn-group {
    justify-content: flex-start;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .main-section .home-section1 {
    width: 700px;
  }
  .main-section .sub-section {
    width: 90%;
  }
  .main-section .sub-section h4 {
    text-align: start !important;
  }
  .main-section .btn-group {
    justify-content: flex-start;
  }
}

@media (min-width: 1001px) and (max-width: 1100px) {
  .main-section .home-section1 {
    width: 900px;
  }
  .main-section .sub-section {
    width: 75%;
  }
  .home-section1 .newsletter button {
    font-size: 1.5rem;
  }
}

.trustpilot-widget {
  display: flex;
  justify-content: center;
  /* border: 1px solid red; */
}

.colorx {
  color: red;
  font-size: 30px;
}

.title{
    color: rgb(217, 217, 217) !important;
    margin-left: 48px;
    margin-top: 22px;
    white-space: nowrap;
}
@media(max-width:376px){
  .title{
    margin-left: 25px;
}
}
.btn-design {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-left: 0px;
}
.home-section-2 {
  width: 1070px;
  margin: auto;
  margin-top: 50px;
}
.undisableClass {
  pointer-events: auto;
  cursor: pointer;
}
.home-section-2 > h1 {
  font-size: 5rem;
  color: var(--primary);
}

.home-section-2 .items-link {
  text-decoration: none;
}
.home-section-2 .items-container {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.home-section-2 .items {
  width: 22.8%;
  height: 260px;
  margin-top: 30px;
  margin-right: 2.2%;
}

.home-section-2 .items .items-link > div {
  position: relative;
  overflow: hidden;
}
.items .items-link > div > div:hover {
  -webkit-transform: scale(1.07);
          transform: scale(1.07);
}
.home-section-2 .items .items-link > div > div:hover .sold-out {
  width: 90%;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.home-section-2 .items .items-link > div > div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  background-position: center;
  background-size: cover;
  transition: all 0.5s;
  align-items: center;
}

.home-section-2 .sold-out {
  width: 95%;
  height: 30px;
  background-color: var(--primary);
  -webkit-transform: rotate(-19deg);
          transform: rotate(-19deg);
  text-align: center;
  margin: auto;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.3s;
  color: black;
  font-weight: 700;
}
/* .progress {
  border: 2px solid var(--primary);
  width: 100%;
  height: 3vh;
  border-radius: 50px;
  position: relative;
  background-color: transparent;
  margin-bottom: 15px;
}
.progress-bar {
  background-color: var(--primary);
} */
/* .home-section-2 .items-colorfull {
	width: 90%;
	height: 20px;
	border-radius: 10px;
	border: 2px solid var(--primary);
	margin-bottom: 15px;
	position: relative;
}
.home-section-2 .progress-bar {
	height: 100%;
	position: absolute;
	background-color: var(--primary);
	border-radius: 10px;
} */

.home-section-2 .items .items-link > div {
  width: 100%;
  height: 70%;
}
.home-section-2 .items h3 {
  font-size: 1.6rem;
  color: rgb(210, 210, 210);
  margin-top: 10px;
  padding-left: 10px;
}
.home-section-2 .items h1 {
  padding-left: 10px;
  font-size: 2.7rem;
  color: var(--primary);
}

@media (max-width: 345px) {
  .home-section-2 {
    width: 270px;
  }
  .home-section-2 > h1 {
    text-align: center;
    font-size: 3rem;
  }
  .home-section-2 .items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .home-section-2 .items {
    width: 100%;
    margin-right: 0;
  }
}

@media (min-width: 346px) and (max-width: 480px) {
  .home-section-2 {
    width: 300px;
  }
  .home-section-2 > h1 {
    text-align: center;
    font-size: 3rem;
  }
  .home-section-2 .items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .home-section-2 .items {
    width: 90%;
    margin-right: 0;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .home-section-2 {
    width: 500px;
  }
  .home-section-2 > h1 {
    text-align: center;
    font-size: 3rem;
  }
  .home-section-2 .items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .home-section-2 .items {
    width: 45%;
    height: 230px;
    margin-right: 0;
  }
}

@media (min-width: 601px) and (max-width: 786px) {
  .home-section-2 {
    width: 650px;
  }
  .home-section-2 > h1 {
    text-align: start;
    font-size: 3rem;
  }
  .home-section-2 .items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .home-section-2 .items {
    width: 41%;
    margin-right: 0;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .main-section .home-section-2 {
    width: 700px;
  }
  .main-section .items-container {
    justify-content: space-between;
  }
  .home-section-2 .items {
    width: 30%;
    height: 230px;
    margin-right: 0;
  }
}

@media (min-width: 1001px) and (max-width: 1100px) {
  .main-section .home-section-2 {
    width: 900px;
  }
  .home-section-2 .items-container {
    justify-content: space-between;
  }
  .home-section-2 .items {
    width: 30%;
    height: 265px;
    margin-right: 0;
  }
}

.card-container {
  display: flex;
  flex-direction: column;
  background-color: #2a2a2a;
  align-items: center;
  width: 158px;
  padding-bottom: 15px;
  padding-left: 7px;
  padding-right: 7px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.card-image {
  width: 158px;
  height: 118px;
  object-fit: cover;
}

.card-heading {
  color: var(--primary);
  color: #d2d2d2 !important;
  font-size: 1.65rem !important;
  height: 40px;
  overflow: hidden;
  font-weight: 400 !important;
  margin: 10px 0px 5px 0px;
}

.card-quantity {
  display: flex;
  justify-content: center;
  width: 130px;
  margin: 10px 0;
  align-items: center;
}

.card-quantity button {
  border: 1px solid #00ffea;
  padding: 5px 5px;
  font-weight: bold;
  border-radius: 4px;
  background-color: #202020;
  color: #00ffea;
  display: flex;
  align-self: center;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  box-shadow: 5px 5px 10px rgb(15 15 15);
}
.card-quantity button:active {
  color: rgb(98 98 98);
  border: 2px solid var(--primary);
  background-color: var(--primary); 
  -webkit-transform: scale(0.95); 
          transform: scale(0.95); 
}

.card-quantity button:disabled {
  color: var(--primary);
  border: 2px solid rgb(105, 105, 105);
  background-color: rgb(105, 105, 105);
  opacity: 0.6; 
}
.card-quantity h2 {
  background-color: #202020;
  font-size: 16px;
  width: 45px;
  height: 35px;
  border-radius: 4px;
  color: gold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  margin: 0px 7px;
  box-shadow: 2px 2px 10px #00ffea;
}

.card-price {
  color: #d2d2d2 !important;
  font-size: 1.6rem !important;
  font-weight: 600 !important;
}

.card-price-text {
  color: #d2d2d2 !important;
  font-size: 1.5rem !important;
  font-weight: 400 !important;
}

.card-button {
  background-color: var(--primary);
  color: rgb(41, 41, 41);
  font-weight: 700;
  font-size: 13px;
  border: none;
  width: 120px;
  padding: 8px 10px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.card-button:hover {
  background-color: rgb(41, 41, 41);
  color: var(--primary);
  border: 1px solid var(--primary);
}

.card-container .progress {
  width: 100%;
  height: 8px;
  background-color: #e0e0e000;
  border-radius: 2px;
  border:1px solid var(--primary);
  overflow: hidden;
  margin: 2px 0 0px 0px;
}

.card-container .progress-bar {
  height: 100%;
  background-color: var(--primary);
  transition: width 0.4s ease;
}


.c-section-1 {
  width: 1070px;
  margin: auto;
  margin-top: 50px;
}
.undisableClass {
  pointer-events: auto;
  cursor: pointer;
}
.c-section-1 > h1 {
  font-size: 5rem;
  color: var(--primary);
}
.c-section-1 .items-link {
  text-decoration: none;
  height: 182px;
  display: flex;
}
.c-section-1 .items-container {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.c-section-1 .items {
  width: 22.8%;
  /* height: 260px; */
  margin-top: 30px;
  margin-right: 2.2%;
}

.c-section-1 .items .items-link > div {
  position: relative;
  overflow: hidden;
}
.items .items-link > div > div:hover {
  -webkit-transform: scale(1.07);
          transform: scale(1.07);
}
.c-section-1 .items .items-link > div > div:hover .sold-out {
  width: 90%;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.c-section-1 .items .items-link > div > div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  background-position: center;
  background-size: cover;
  transition: all 0.5s;
  align-items: center;
}
.c-section-1 .sold-out {
  width: 95%;
  height: 30px;
  background-color: var(--primary);
  -webkit-transform: rotate(-19deg);
          transform: rotate(-19deg);
  text-align: center;
  margin: auto;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.3s;
  color: black;
}
.items .progress {
  border: 1.6px solid rgb(0 188 173) !important;
  width: 94% !important;
  height: 1.7vh !important;
  border-radius: 16px;
  position: relative;
  background-color: transparent;
  margin-bottom: 15px;
  /* box-shadow: 3px 4px 10px rgb(0 94 102 / 88%);  */
}
.items .progress-bar {
  height: 100%;
  width: 0; 
  border-radius: 9px;
    background: var(--primary);
    background: linear-gradient(90deg, rgb(0 188 173) 0, #00ffe9 100%);
    transition: width .6s, background-color 0.4s ease;;
}
/* .c-section-1 .items-colorfull {
  width: 90%;
  height: 20px;
  border-radius: 10px;
  border: 2px solid var(--primary);
  margin-bottom: 15px;
  position: relative;
}
.c-section-1 .progress-bar {
  height: 100%;
  position: absolute;
  background-color: var(--primary);
  border-radius: 10px;
} */

.c-section-1 .items .items-link > div {
  width: 100%;
  height: 100%;
}
.c-section-1 .items h3 {
  font-size: 1.6rem;
  color: rgb(210, 210, 210);
  margin-top: 5px;
  padding-left: 10px;
}
.c-section-1 .items h2 {
  padding-left: 5px;
  font-size: 2.7rem;
  color: var(--primary);
}
.discount-bar {
  margin-right: 10px;
  text-decoration: line-through;
}

@media (max-width: 345px) {
  .c-section-1 .items .items-link > div > div {
    width:300px;
    height: 225px;
  }
  .c-section-1 {
    width: 270px;
  }
  .c-section-1 > h1 {
    text-align: center;
    font-size: 3rem;
  }
  .c-section-1 .items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .c-section-1 .items {
    width: 100%;
    margin-right: 0;
  }

  .card-container {
    width: 135px;
    padding-left: 3px;
    padding-right: 3px;
  }
  
  .card-image {
    width: 135px;
    height: 118px;
    object-fit: cover;
  }
  
  .card-heading {
    font-size: 1.35rem !important;
    height: 30px;
    overflow: hidden;
  }
  
  .card-quantity h2 {
    font-size: 1.45rem;
  }
  
  .card-price {
    font-size: 1.3rem !important;
  }
  
  .card-price-text {
    font-size: 1.3rem !important;
  }
  
}

@media (min-width: 346px) and (max-width: 480px) {
  .c-section-1 .items .items-link > div > div {
    width:300px;
    height: 225px;
  }
  .c-section-1 {
    width: 300px;
  }
  .c-section-1 > h1 {
    text-align: center;
    font-size: 3rem;
  }
  .c-section-1 .items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .c-section-1 .items {
    width: 90%;
    margin-right: 0;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .c-section-1 {
    width: 500px;
  }
  .c-section-1 > h1 {
    text-align: center;
    font-size: 3rem;
  }
  .c-section-1 .items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .c-section-1 .items {
    width: 45%;
    /* height: 230px; */
    margin-right: 0;
  }
}

@media (min-width: 601px) and (max-width: 786px) {
  .c-section-1 {
    width: 650px;
  }
  .c-section-1 > h1 {
    text-align: start;
    font-size: 3rem;
  }
  .c-section-1 .items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .c-section-1 .items {
    width: 41%;
    margin-right: 0;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .main-section .c-section-1 {
    width: 700px;
  }
  .main-section .items-container {
    justify-content: space-between;
  }
  .c-section-1 .items {
    width: 30%;
    /* height: 230px; */
    margin-right: 0;
  }
}

@media (min-width: 1001px) and (max-width: 1100px) {
  .main-section .c-section-1 {
    width: 900px;
  }
  .c-section-1 .items-container {
    justify-content: space-between;
  }
  .c-section-1 .items {
    width: 30%;
    /* height: 265px; */
    margin-right: 0;
  }
}

.section-3 {
  padding-top: 1px;
  width: 1070px;
  margin: auto;
  margin-top: 50px;
  position: relative;
}

.section-3 h1 {
  font-size: 5rem;
  color: var(--primary);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 50px;
  margin: -2px;
}

.section-3 .swiper {
  width: 100%;
  min-height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.section-3 .swiper-pagination {
  top: 70px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.section-3 .second.swiper-slide {
  display: flex;
}

.section-3 .second.swiper-slide>div {
  height: 300px;
  background-position: center;
  background-size: cover;
}

.section-3 .second.swiper-slide>div:nth-child(1) {
  width: 40%;
  height: 321px;
}



.section-3 .second.swiper-slide>div:nth-child(2) {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  padding-left: 55px;
  color: #cdcdcd;
}

.section-3 .second.swiper-slide>div:nth-child(2)>figure {
  width: 130px;
  height: 120px;
  margin-left: 40px;
}

.section-3 .second.swiper-slide>div:nth-child(2)>figure>img {
  width: 100%;
  height: 100%;
}

.section-3 .second.swiper-slide>div:nth-child(2) h3 {
  font-weight: 700;
  font-size: 2.7rem;
}

.section-3 .second.swiper-slide>div:nth-child(2) h4 {
  font-weight: 600;
  font-size: 2rem;
}

.section-3 .second.swiper-slide>div:nth-child(2) p {
  font-weight: 400;
  font-size: 1.6rem;
}

.section-3 .second.swiper-slide>div:nth-child(2) h5 {
  font-weight: 400;
  font-size: 1.7rem;
}

.loveit {
  width: 100% !important;
  font-size: 3rem !important;
  text-align: center;
  padding-top: 90px;
  color: rgb(90, 90, 90) !important;
}

@media (max-width: 800px) {
  .section-3 .second.swiper-slide {
    flex-direction: column;
  }

  .section-3 h1 {
    font-size: 3rem;
    text-align: center;
  }

  .second.swiper-slide>div {
    width: 100% !important;
  }

  .second.swiper-slide>div:nth-child(2) {
    /* margin-top: 30px; */
    padding: 0 !important;
    align-items: center !important;
  }

  .second.swiper-slide>div:nth-child(2) figure {
    margin: 0 !important;
  }
}

@media (max-width: 345px) {
  .main-section .section-3 {
    width: 270px;
  }

  .second.swiper-slide>div:nth-child(1) {
    height: 203px !important;
  }

  .second.swiper-slide>div:nth-child(2) {
    /* height: 300px; */
  }

  .second.swiper-slide>div:nth-child(2) h5 {
    font-size: 1.4rem !important;
  }

  .section-3 .second.swiper-pagination {
    top: 95px;
  }

  .loveit {
    font-size: 2rem !important;
    padding-top: 65px;
  }
}

@media (min-width: 346px) and (max-width: 480px) {
  .main-section .section-3 {
    width: 310px;
    text-align: center !important;
  }

  .second.swiper-slide>div:nth-child(1) {
    height: 233px !important;
  }

  .second.swiper-slide>div:nth-child(2) {
    /* height: 400px; */
  }

  .second.swiper-slide>div:nth-child(2) h5 {
    font-size: 1.4rem !important;
  }

  .loveit {
    font-size: 2rem !important;
    padding-top: 65px;
  }

  .section-3 .second.swiper-pagination {
    top: 60px;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .main-section .section-3 {
    width: 500px;
  }

  .second.swiper-slide>div:nth-child(1) {
    height: 300px !important; 
  }

  .second.swiper-slide>div:nth-child(2) {
    /* height: 400px; */
  }

  .second.swiper-slide>div:nth-child(2) h5 {
    font-size: 1.7rem !important;
  }

  .section-3 .second.swiper-pagination {
    top: 15%;
  }

  .loveit {
    font-size: 2.5rem !important;
  }

  .section-3 .second.swiper-pagination {
    top: 120px;
  }
}

@media (min-width: 601px) and (max-width: 786px) {
  .main-section .section-3 {
    width: 650px;
  }

  .section-3 .second.swiper-slide {
    align-items: center;
  }

  .second.swiper-slide>div:nth-child(1) {
    width: 70% !important;
    height: 342px !important;
  }

  .second.swiper-slide>div:nth-child(2) {
    /* height: 400px; */
  }

  .second.swiper-slide>div:nth-child(2) h5 {
    font-size: 2rem !important;
  }

  .loveit {
    font-size: 2.8rem !important;
  }

  .section-3 .second.swiper-pagination {
    top: 60px;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .main-section .section-3 {
    width: 700px;
  }
}

@media (min-width: 1001px) and (max-width: 1100px) {
  .main-section .section-3 {
    width: 900px;
  }

  .second.swiper-slide>div:nth-child(1) {
    height: 270px !important;
  }

  .second.swiper-slide>div:nth-child(2) {
    /* height: 300px; */
  }

  .second.swiper-slide>div:nth-child(2) h5 {
    font-size: 1.4rem !important;
  }

  .section-3 .second.swiper-pagination {
    top: 15%;
  }

  .loveit {
    font-size: 2rem !important;
  }

  .section-3 .second.swiper-pagination {
    top: 10%;
  }
}

.section-4 {
  width:1070px;
  margin: 0 auto;
  margin-top: 30px;
}
.section-4 h1 {
  margin: auto;
  font-size: 5rem;
  color: var(--primary);
}
.section-4 > div.row {
  min-height: 270px;
  border: 1px solid rgb(210, 210, 210);
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 50px;
}
.section-4 > div.row > div:nth-child(1) h2 {
  font-size: 3rem;
  color: rgb(210, 210, 210);
}
.section-4 > div.row > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.section-4 > div.row > div:nth-child(1) p {
  color: rgb(210, 210, 210);
  font-size: 1.6rem;
}
.section-4 > div.row > div:nth-child(1) p span {
  color: rgb(210, 210, 210);
  font-size: 2rem;
}
.section-4 > div.row > div:nth-child(1) i {
  font-size: 2rem;
  margin-bottom: 9px;
}

.section-4 .stars i {
  font-size: 4rem;
  background-color: #00b67a;
  color: white;
  padding: 3px;
  margin-left: 5px;
}
.section-4 .trustpilot {
  padding: 5px;
  display: flex;
  align-items: center;
}
.section-4 .trustpilot i {
  font-size: 3rem;
  color: #00b67a;
}
.section-4 .trustpilot h5 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: rgb(210, 210, 210);
  font-size: 2rem;
}
.third-slider {
  height: 70%;
  overflow: hidden;
}
.section-4 > div.row > div:nth-child(2){
  display: flex;
  align-items: center;
}

.clients {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 40px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
}
.clients > .stars i {
  font-size: 1.9rem;
  padding: 3px;
}
.clients h2 {
  width: 175px;
  font-size: 2.3rem;
  margin-top: 9px;
  color: rgb(210, 210, 210);
  overflow: hidden;
}
.clients p {
  font-size: 1.4rem;
  color: rgb(221, 220, 220);
  height: 80px;
  overflow: hidden;
}
.clients .customer {
  color: gray;
  font-size: 2rem;
}
.swiper-button-next {
  color: rgb(228, 228, 228);
  right: 0;
}
.swiper-button-prev {
  color: rgb(228, 228, 228);

  left: 0;
}
.third .swiper-button-next::after,
.third .swiper-button-prev::after {
  font-size: 4rem;
}


@media (min-width: 0px) and (max-width: 800px) {
  .section-4 {
    width: 90%;
    margin-top: 60px;
  }
  .section-4 h1 {
    font-size: 3rem;
  }
  .section-4 > .row > div:nth-child(2) {
    min-height: 250px;
  }
  .section-4 > .row > div:nth-child(2) > .third-slider .clients {
    height: 100%;
  }
  .section-4 .stars i {
    font-size: 3rem;
    background-color: var(--primary);
    color: white;
    padding: 3px;
    margin-left: 5px;
  }
  .section-4 .trustpilot {
    padding: 5px;
    display: flex;
    align-items: center;
  }
  .section-4 .trustpilot i {
    font-size: 3rem;
    color: var(--primary);
  }
  .section-4 .trustpilot h5 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: rgb(210, 210, 210);
    font-size: 2rem;
  }
  .swiper-wrapper {
    display: flex;
    align-items: center;
}
.clients {
  display: flex;
  height: 260px;
  width: 100%; /* Ensure full width is used */
  max-width: 280px; /* Add a max width for consistent card size */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  margin-top: 20px;
  grid-gap: 20px;
  gap: 20px; /* Use gap for spacing */
  margin-left: auto; /* Center align */
  margin-right: auto; /* Center align */
}

  .clients .stars {
    padding: 0 !important;
    margin-top: 0px;
    margin-left: 12px !important;
  }
  .clients > .stars i {
    font-size: 1.9rem;
    padding: 3px;
  }
  .clients h2 {
    margin-top: 0px;
    font-size: 2.5rem;
    color: rgb(210, 210, 210);
  }
  .clients p {
    color: rgb(210, 210, 210);
    height: 125px;
    width: 45vw;
    overflow: hidden;
  }
  .clients .customer {
    display: block;
    font-size: 1.5rem;
    color: rgb(0, 0, 0);
    font: 600;
  }

.swiper-button-next,
.swiper-button-prev {
    color: #000; 
    background-color: rgba(255, 255, 255, 0.7); 
    border-radius: 50%; 
    width: 40px;
    height: 40px; 
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute; 
    top: 60%; 
    z-index: 10; 
    -webkit-transform: translateY(-50%); 
            transform: translateY(-50%); 
    cursor: pointer;
}

.third .swiper-button-next {
  background-color: #696969de !important;
    right: 4px; 
}

.third .swiper-button-prev {
    left: 4px; 
}


.third .swiper-button-next:hover,
.third .swiper-button-prev:hover {
    background-color: #696969de; 
    color: #fff; 
}

.third .swiper-button-disabled {
  opacity: 0.5 !important; 
  pointer-events: none; 
}

  .third .swiper-button-next::after,
  .third .swiper-button-prev::after {
    font-size: 3rem;
  }
}

@media (max-width:345px)
{
  .section-4
  {
    width: 270px;
    margin-top: 20px;
  }
  .section-4 h1
  {
    text-align: center;
  }
}

@media (min-width:346px) and (max-width:480px)
{
  .main-section .section-4
  {
    width: 310px;
    text-align: center !important;
    margin-top: 20px;
  }
  .section-4 h1
  {
    text-align: center;
  }
}

@media (min-width:481px) and (max-width:600px)
{
  .main-section .section-4
  {
    width: 500px;
    text-align: start !important;
  }
}

@media (min-width:601px) and (max-width:786px)
{
  .main-section .section-4
  {
    width: 650px;
  }
}

@media (min-width:800px) and (max-width:1000px)
{
  .main-section .section-4
  {
    width: 700px;
  }
}

@media (min-width:1001px) and (max-width:1100px)
{
  .main-section .section-4
  {
    width: 900px;
  }
}

@media (max-width:410px){

}

@media (max-width:376px){

}
@media (max-width:321px){
  .clients {
    
  }
  .clients h2 {
    width: 151px;
  }
  .clients p {
    margin: 0px 2px;
  }
  .clients .stars{
    margin-left: 5px !important;
  }
  .clients .customer {
    margin-top: 15px;
  }
}
.h-giftshop {
  width: 1070px;
  margin: auto;
  margin-top: 50px;
}
.h-giftshop > h1 {
  font-size: 5rem;
  color: var(--primary);
}
.h-giftshop > div {
  min-height: 220px;
  border: 1px solid rgb(210, 210, 210);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 50px;
  margin-bottom: 50px;
}
.h-giftshop > div > div {
  width: 22%;
  height: 200px;
  text-align: center;
}
.h-giftshop > div figure {
  width: 235px;
  height: 177px;
  height: 100%;
  background-size: cover;
  background-position: center;
}
.h-giftshop > div video,
.h-giftshop > div video source {
  width: 100%;
  height: 100%;
}
.h-giftshop > div > div:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

@media (min-width: 0px) and (max-width: 800px) {
  .h-giftshop {
    width: 90%;
    margin-top: 40px;
  }
  .h-giftshop > h1 {
    font-size: 3rem;
  }

  .h-giftshop > div > div {
    margin-bottom: 30px;
    margin-top: 30px;
  }
}

@media (max-width: 345px) {
  .h-giftshop {
    width: 270px;
  }
  .h-giftshop > h1 {
    text-align: center;
  }
  .h-giftshop > div > div {
    width: 95%;
  }
  .h-giftshop > div {
    flex-direction: column;
  }
  .h-giftshop > div video,
  .h-giftshop > div figure {
    width: 255px;
    height: 191px;
  }
}

@media (min-width: 346px) and (max-width: 480px) {
  .h-giftshop {
    width: 310px;
    text-align: center !important;
  }
  .h-giftshop > div > div {
    width: 95%;
  }
  .h-giftshop > div {
    flex-direction: column;
  }
  .h-giftshop > div video,
   .h-giftshop > div figure {
    width: 293px;
    height: 220px;
  }
} 

@media (min-width: 481px) and (max-width: 600px) {
  .h-giftshop {
    width: 500px;
  }
  .h-giftshop > div > div {
    width: 45%;
  }
}

@media (min-width: 601px) and (max-width: 786px) {
  .h-giftshop {
    width: 650px;
  }
  .h-giftshop > div > div {
    width: 45%;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .h-giftshop {
    width: 700px;
  }
  .h-giftshop > div > div {
    width: 30%;
    height: 150px;
  }
}

@media (min-width: 1001px) and (max-width: 1100px) {
  .h-giftshop {
    width: 900px;
  }
  .h-giftshop > div > div {
    width: 30%;
    height: 190px;
  }
}

.c-section-1 {
  width: 1070px;
  margin: auto;
  margin-top: 50px;
}
.undisableClass {
  pointer-events: auto;
  cursor: pointer;
}

.c-section-1 > h1 {
  font-size: 5rem;
  color: var(--primary);
}
.c-section-1 .items-link {
  height: 182px;
  display: flex;
  text-decoration: none;
}
.c-section-1 .items-container {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.c-section-1 .items {
  width: 22.8%;
  /* height: 260px; */
  margin-top: 30px;
  margin-right: 2.2%;
}

.c-section-1 .items .items-link > div {
  position: relative;
  overflow: hidden;
}
.items .items-link > div > div:hover {
  -webkit-transform: scale(1.07);
          transform: scale(1.07);
}
.c-section-1 .items .items-link > div > div:hover .sold-out {
  width: 90%;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
/* .c-section-1 .items .items-link > div > div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  background-position: center;
  background-size: cover;
  transition: all 0.5s;
  align-items: center;
} */
.c-section-1 .sold-out {
  width: 95%;
  height: 30px;
  background-color: var(--primary);
  -webkit-transform: rotate(-19deg);
          transform: rotate(-19deg);
  text-align: center;
  margin: auto;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.3s;
  color: black;
}
/* .progress {
  border: 2px solid var(--primary);
  width: 100%;
  height: 3vh;
  border-radius: 50px;
  position: relative;
  background-color: transparent;
}
.progress-bar {
  background-color: var(--primary);
} */
/* .c-section-1 .items-colorfull {
  width: 90%;
  height: 20px;
  border-radius: 10px;
  border: 2px solid var(--primary);
  margin-bottom: 15px;
  position: relative;
}
.c-section-1 .progress-bar {
  height: 100%;
  position: absolute;
  background-color: var(--primary);
  border-radius: 10px;
} */

/* .c-section-1 .items .items-link > div {
  width: 100%;
  height: 100%;
} */
.c-section-1 .items h3 {
  font-size: 1.6rem;
  color: rgb(210, 210, 210);
  margin-top: 5px;
  padding-left: 10px;
}
.c-section-1 .items h2 {
  padding-left: 10px;
  font-size: 2.7rem;
  color: var(--primary);
}
.discount-bar {
  margin-right: 10px;
  text-decoration: line-through;
}

@media (max-width: 345px) {
  .c-section-1 .items-link {
    height: 225px;
  }
  .c-section-1 {
    width: 310px;
    margin-top: 10px;
  }
  .c-section-1 > h1 {
    text-align: center;
    font-size: 3rem;
  }
  .c-section-1 .items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .c-section-1 .items {
    width: 100%;
    margin-right: 0;
  }
}

@media (min-width: 346px) and (max-width: 480px) {
  .c-section-1 .items-link {
    height: 225px;
  }
  .c-section-1 {
    width: 333px;
    margin-top: 15px;
  }
  .c-section-1 > h1 {
    text-align: center;
    font-size: 3rem;
  }
  .c-section-1 .items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .c-section-1 .items {
    width: 90%;
    margin-right: 0;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .c-section-1 {
    width: 500px;
  }
  .c-section-1 > h1 {
    text-align: center;
    font-size: 3rem;
  }
  .c-section-1 .items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .c-section-1 .items {
    width: 45%;
    /* height: 230px; */
    margin-right: 0;
  }
}

@media (min-width: 601px) and (max-width: 786px) {
  .c-section-1 {
    width: 650px;
  }
  .c-section-1 > h1 {
    text-align: start;
    font-size: 3rem;
  }
  .c-section-1 .items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .c-section-1 .items {
    width: 41%;
    margin-right: 0;
  }
  .c-section-1 .items-link {
    height: 200px;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .main-section .c-section-1 {
    width: 700px;
  }
  .main-section .items-container {
    justify-content: space-between;
  }
  .c-section-1 .items {
    width: 30%;
    /* height: 230px; */
    margin-right: 0;
  }
  .c-section-1 .items-link {
    height: 157px;
  }
}

@media (min-width: 1001px) and (max-width: 1100px) {
  .main-section .c-section-1 {
    width: 900px;
  }
  .c-section-1 .items-container {
    justify-content: space-between;
  }
  .c-section-1 .items {
    width: 30%;
    /* height: 265px; */
    margin-right: 0;
  }
  .c-section-1 .items-link {
    height: 203px;
  }
}


.logIn {
  padding-bottom: 60px;
}

.form-modal {
  position: relative;
  width: 400px;
  height: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: #fff;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0 3px 20px 0px rgba(60, 237, 183, 0.2);
}
.form-modal button {
  cursor: pointer;
  position: relative;
  text-transform: capitalize;
  font-size: 4rem;
  z-index: 2;
  outline: none;
  background: #fff;
  transition: 0.2s;
}

.form-modal .btn {
  border-radius: 20px;
  border: none;
  font-size: 1.6rem;
  padding: 0.5em 1em 0.5em 1em !important;
  transition: 0.5s;
  border: 1px solid #ebebeb;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}

.form-modal .login {
  background: var(--primary);
  color: black;
}

.form-modal .login:hover {
  background: #222;
  color: white;
}

.form-toggle {
  position: relative;
  width: 100%;
  height: auto;
}

.form-toggle button {
  width: 100%;
  float: left;
  padding: 1.2rem;
  margin-bottom: 1.5em;
  border: none;
  transition: 0.2s;
  font-size: 3rem;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

#login-toggle {
  background: var(--primary);
  color: black;
}

.form-modal form {
  position: relative;
  width: 90%;
  height: auto;
  left: 50%;
  top: -20px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  flex-direction: column;
}

#login-form {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 1em;
}

#login-form button {
  width: 100%;
  margin-top: 0.5em;
  padding: 0.6em;
}

.form-modal input {
  position: relative;
  width: 100%;
  margin-top: 0.6em;
  margin-bottom: 0.6em;
  border-radius: 20px;
  border: none;
  background: #ebebeb;
  outline: none;
  transition: 0.4s;
  padding-left: 18px;
}

.form-modal input {
  font-size: 1.5rem;
}
.form-modal input::-webkit-input-placeholder {
  color: #222;
  font-size: 1.7rem;
}

.logIn .form-modal p {
  font-size: 1.6rem;
  padding-left: 20px;
}
.logIn .form-modal p a {
  color: black;
  text-decoration: none;
  transition: 0.2s;
}

.logIn .form-modal p a:hover {
  color: var(--primary);
}

.fa-google {
  color: #dd4b39;
}

.box-sd-effect .fa-facebook-square {
  color: #0072c6;
}

.box-sd-effect:hover {
  box-shadow: 0 10px 5px 0px rgba(60, 237, 183, 0.2);
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
}
.login {
  font-weight: bold;
}

@media only screen and (max-width: 500px) {
  .logIn {
    padding-bottom: 60px;
  }
  .form-modal {
    width: 80%;
  }
  #login-toggle {
    font-size: 3rem;
  }
  input {
    margin: 0;
  }
  #login-form {
    padding: 0;
  }
  .box-sd-effect i {
    padding-left: -10px !important;
  }
  .box-sd-effect {
    font-size: 1.4em !important;
  }
}

dl,
ol,
ul {
  font-size: 1.4rem;
}
.sticky-title {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #161413; 
  z-index: 100; 
  padding: 8px 0; 
}
.affilated {
  width: 1070px;
  height: 430px;
  margin: 60px auto 70px auto;

  -webkit-user-select: none;

          user-select: none;
}
.affilated > div {
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  border-top: 1px solid rgb(90, 90, 90);
  border-bottom: 1px solid rgb(90, 90, 90);
  margin: auto;
}
.affilated h4 {
  font-size: 2.4rem;
  color: rgb(210, 210, 210);
  margin-top: 7px;
}
.affilated p {
  margin-top: 7px;
  font-size: 1.4rem;
  color: rgb(210, 210, 210);
}

.head{
  width: 500px;
}
.head-p {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.body-p {
  display: flex;
  justify-content: space-between;
}
.title-p{
  width: 200px;
}
.title-p1{
  width: 177px;
}
@media (max-width: 1024px) {
  .affilated > div {
    width: 94%;
  }
  .affilated h4 {
  margin-left: 10px;
    font-size: 2rem;
  }
  .affilated p {
    font-size: 1.2rem;
  }
}

.affilated > div::-webkit-scrollbar-thumb {
  background: rgb(53, 53, 53);
  border: 3px solid gray; /* creates padding around scroll thumb */
}

.affilated > div::-webkit-scrollbar-track {
  background: gray;
}

.affilated > div::-webkit-scrollbar {
  width: 9px;
}

@media (max-width: 500px) {
  .affilated {
    width: 1070px;
    height: 460px;
  }
  .head{
    width: 270px;
  }
  .title-p{
    width: 86px;
  }
  .title-p1{
    width: 75px;
  }
}

@media (max-width: 345px) {
  .affilated {
    width: 270px;
  }
}

@media (min-width: 346px) and (max-width: 480px) {
  .affilated {
    width: 310px;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .affilated {
    width: 500px;
  }
}
@media (min-width: 601px) and (max-width: 786px) {
  .affilated {
    width: 650px;
  }
}
@media (min-width: 800px) and (max-width: 1000px) {
  .affilated {
    width: 700px;
  }
}
@media (min-width: 1001px) and (max-width: 1100px) {
  .affilated {
    width: 900px;
  }
}

.logIn {
  padding-bottom: 60px;
}

.form-modal {
  position: relative;
  width: 400px;
  height: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: #fff;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0 3px 20px 0px rgba(60, 237, 183, 0.2);
}
.form-modal button {
  cursor: pointer;
  position: relative;
  text-transform: capitalize;
  font-size: 4rem;
  z-index: 2;
  outline: none;
  background: #fff;
  transition: 0.2s;
}

.form-modal .btn {
  border-radius: 20px;
  border: none;
  font-size: 1.6rem;
  padding: 0.5em 1em 0.5em 1em !important;
  transition: 0.5s;
  border: 1px solid #ebebeb;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}

.form-modal .login {
  background: var(--primary);
  color: black;
}

.form-modal .login:hover {
  background: #222;
  color: white;
}

.form-toggle {
  position: relative;
  width: 100%;
  height: auto;
}

.form-toggle button {
  width: 100%;
  float: left;
  padding: 1.2rem;
  margin-bottom: 1.5em;
  border: none;
  transition: 0.2s;
  font-size: 3rem;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

#login-toggle {
  background: var(--primary);
  color: black;
}

.form-modal form {
  position: relative;
  width: 90%;
  height: auto;
  left: 50%;
  top: -20px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  flex-direction: column;
}

#login-form {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 1em;
}

#login-form button {
  width: 100%;
  margin-top: 0.5em;
  padding: 0.6em;
}

.form-modal input {
  position: relative;
  width: 100%;
  margin-top: 0.6em;
  margin-bottom: 0.6em;
  border-radius: 20px;
  border: none;
  background: #ebebeb;
  outline: none;
  transition: 0.4s;
  padding-left: 18px;
}

.form-modal input {
  font-size: 1.5rem;
}
.form-modal input::-webkit-input-placeholder {
  color: #222;
  font-size: 1.7rem;
}

.logIn .form-modal p {
  font-size: 1.6rem;
  padding-left: 20px;
}
.logIn .form-modal p a {
  color: black;
  text-decoration: none;
  transition: 0.2s;
}

.logIn .form-modal p a:hover {
  color: var(--primary);
}

.fa-google {
  color: #dd4b39;
}

.box-sd-effect .fa-facebook-square {
  color: #0072c6;
}

.box-sd-effect:hover {
  box-shadow: 0 10px 5px 0px rgba(60, 237, 183, 0.2);
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
}
.login {
  font-weight: bold;
}

@media only screen and (max-width: 500px) {
  .logIn {
    padding-bottom: 60px;
  }
  .form-modal {
    width: 80%;
  }
  #login-toggle {
    font-size: 3rem;
  }
  input {
    margin: 0;
  }
  #login-form {
    padding: 0;
  }
  .box-sd-effect i {
    padding-left: -10px !important;
  }
  .box-sd-effect {
    font-size: 1.4em !important;
  }
}

.logIn {
  padding-bottom: 60px;
}

.form-modal {
  position: relative;
  width: 400px;
  height: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: #fff;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0 3px 20px 0px rgba(60, 237, 183, 0.2);
}
.form-modal button {
  cursor: pointer;
  position: relative;
  text-transform: capitalize;
  font-size: 4rem;
  z-index: 2;
  outline: none;
  background: #fff;
  transition: 0.2s;
}

.form-modal .btn {
  border-radius: 20px;
  border: none;
  font-size: 1.6rem;
  padding: 0.5em 1em 0.5em 1em !important;
  transition: 0.5s;
  border: 1px solid #ebebeb;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}

.form-modal .login {
  background: var(--primary);
  color: black;
}

.form-modal .login:hover {
  background: #222;
  color: white;
}

.form-toggle {
  position: relative;
  width: 100%;
  height: auto;
}

.form-toggle button {
  width: 100%;
  float: left;
  padding: 1.2rem;
  margin-bottom: 1.5em;
  border: none;
  transition: 0.2s;
  font-size: 3rem;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

#login-toggle {
  background: var(--primary);
  color: black;
}

.form-modal form {
  position: relative;
  width: 90%;
  height: auto;
  left: 50%;
  top: -20px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  flex-direction: column;
}

#login-form {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 1em;
}

#login-form button {
  width: 100%;
  margin-top: 0.5em;
  padding: 0.6em;
}

.form-modal input {
  position: relative;
  width: 100%;
  margin-top: 0em;
  margin-bottom: 0.6em;
  border-radius: 20px;
  border: none;
  background: #ebebeb;
  outline: none;
  transition: 0.4s;
  padding-left: 18px;
}

.form-modal input {
  font-size: 1.5rem;
}
.form-modal input::-webkit-input-placeholder {
  color: #222;
  font-size: 1.7rem;
}

.logIn .form-modal p {
  font-size: 1.6rem;
  padding-left: 20px;
}
.logIn .form-modal p a {
  color: black;
  text-decoration: none;
  transition: 0.2s;
}

.logIn .form-modal p a:hover {
  color: var(--primary);
}

.fa-google {
  color: #dd4b39;
}
.error{
  color: red;
}

.box-sd-effect .fa-facebook-square {
  color: #0072c6;
}

.box-sd-effect:hover {
  box-shadow: 0 10px 5px 0px rgba(60, 237, 183, 0.2);
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
}
.login {
  font-weight: bold;
}

@media only screen and (max-width: 500px) {
  .logIn {
    padding-bottom: 60px;
  }
  .form-modal {
    width: 80%;
  }
  #login-toggle {
    font-size: 3rem;
  }
  input {
    margin: 0;
  }
  #login-form {
    padding: 0;
  }
  .box-sd-effect i {
    padding-left: -10px !important;
  }
  .box-sd-effect {
    font-size: 1.4em !important;
  }
}

.privacy {
  width: 1070px;
  height: 430px;
  margin: 60px auto 70px auto;
  -webkit-user-select: none;
          user-select: none;
}
.privacy>div
{
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  border-top: 1px solid rgb(90,90,90);
  border-bottom: 1px solid rgb(90,90,90);
  margin: auto;
}

.privacy h4 {
  font-size: 2.4rem;
  color: rgb(210,210,210);
  margin-top: 7px;
}
.privacy p {
  margin-top: 7px;
  font-size: 1.4rem;
  color: rgb(210,210,210);
}

@media (max-width: 500px) {
  .terms {
    height: 460px;
  }
}
@media (max-width: 800px) {
  .privacy
{
  width: 100%;
}
  .privacy h4 {
    font-size: 2rem;
  }
  .privacy p {
    font-size: 1.2rem;
  }
}

.privacy>div::-webkit-scrollbar-thumb {
  background: rgb(53, 53, 53);
  border: 3px solid gray;  /* creates padding around scroll thumb */

}

.privacy>div::-webkit-scrollbar-track {
  background: gray;
}

.privacy>div::-webkit-scrollbar {
  width: 9px;
}


@media (max-width:500px)
{
  .privacy {
    width: 1070px;
    height: 460px;
  }
}

@media (max-width:345px)
{
  .privacy
  {
    width: 270px;
  }
}

@media (min-width:346px) and (max-width:480px)
{
  .privacy
  {
    width: 310px;
  }
}


@media (min-width:481px) and (max-width:600px)
{
  .privacy
  {
    width: 500px;
  }
}
@media (min-width:601px) and (max-width:786px)
{
  .privacy
  {
    width: 650px;
  }
}
@media (min-width:800px) and (max-width:1000px)
{
  .privacy
  {
    width: 700px;
  }
}
@media (min-width:1001px) and (max-width:1100px)
{
  .privacy
  {
    width: 900px;
  }
}
/* SLIDER SECTION */

.valueConparent .progress {
  border: 1.5px solid rgb(0 188 173) !important;
  width: 100% !important;
  height: 1.7vh !important;
  border-radius: 16px;
  position: relative;
  background-color: transparent;
  margin-bottom: 15px;
  box-shadow: 3px 4px 10px rgb(0 94 102 / 88%); 
}
.valueConparent .progress-bar {
  height: 100%;
  width: 0; 
  border-radius: 9px;
    background: var(--primary);
    background: linear-gradient(90deg, rgb(0 188 173) 0, #00ffe9 100%);
    transition: width .6s, background-color 0.4s ease;;
}

.com-section-3 {
  width: 1070px;
  margin: auto;
  margin-top: 10px;
}

.com-section-3 h1 {
  font-size: 4rem;
  text-align: center;
  font-weight: 200;
  color: var(--primary);
}

.main-section .navigation-buttons {
  display: flex;
  justify-content: space-between;
}

.main-section .navigation-button {
  background-color: var(--primary); 
  font-size: 2rem;
  padding: 5px 10px;
  font-weight: 600;
  border-radius: 4px;
  margin-top: 40px;
  margin-left: 29px;
  margin-right: 57px;
  border: none; 
  color: black;
  cursor: pointer;
}

.main-section .navigation-button:hover {
  opacity: 0.9; 
}

.main-section .navigation-button:disabled {
  background-color: #d2d2d2; 
  color: #303030;
  cursor: not-allowed; 
  opacity: 0.6;
}

.com-section-3 .portfolio-item-container {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.com-section-3 .portfolio-item-container > div {
  width: 45%;
  height: 540px;
  margin-top: 30px;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1;
}

.com-section-3 .btn-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 22px;
  margin-bottom: 50px;
}

.com-section-3 .btn-wrapper button {
  margin-left: 130px;

  
}

.com-section-3 .btn-wrapper .luckydrip {
  width: 100%;
  padding: -1px 0px;
  max-width: 494px;
}

.com-section-3 .portfolio-item-container > div:nth-child(2) {
  width: 45%;
  /* margin-left: 70px; */
  padding: 0 20px;
}

.com-section-3 .portfolio-item button {
  padding: 3px 30px 3px 30px;
  font-size: 2rem;
  border-radius: 20px;
  margin-top: 5px;
}

.com-section-3 .portfolio-item:nth-child(1) h1 {
  font-size: 2rem;
  font-weight: 600;
  color: rgb(210, 210, 210);
  margin-top: 10px;
}

.com-section-3 .swiper {
  width: 100%;
  height: 500px;
  margin-left: auto;
  margin-right: auto;
}

.com-section-3 .portfolio-item:nth-child(2) {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.com-section-3 .portfolio-item.has-subtitle {
 height: 522px;
}
.com-section-3 .portfolio-item.no-subtitle {
  height: 468px;
 }

.com-section-3 .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: rgb(210, 210, 210);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}

.com-section-3 .swiper-slide {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.com-section-3 button:hover {
  background-color: var(--primary);
}

/* .com-section-3 .swiper-slide:hover {
  transform: scale(1.07);
} */

.com-section-3 .swiper-button-next,
.com-section-3 .swiper-button-prev {
  border: 2px solid white;
  border-radius: 50%;
  padding: 20px;
  font-size: 1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-next::after,
.com-section-3 .swiper-button-prev::after {
  font-size: 3rem;
  color: white;
}

.com-section-3 > h1 input {
  border-radius: 20px;
  font-size: 3rem;
  padding: 0;
  padding-left: 10px;
  outline: none;
  border: none;
}

.gallery-thumbs .swiper-slide {
  width: 18% !important;
  max-width: 18%; /* Preventing the width from exceeding the fixed width */
  height: 70px;
  opacity: 1;
  flex: 0 0 auto;
}

.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

.gallery-thumbs.swiper {
  height: 20%;
}

.slider-counter {
  width: 80%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0px;
  padding-right: 10px;
}

.portfolio-item h6,
.slider-counter > p {
  color: #d2d2d2;
  font-size: 1.5rem;
}
.portfolio-item h6{
  width: 90%;
  height: 65px;
  margin-top: 5px;
  text-align: left;
  overflow: hidden;
}
.cup {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
}

.cup img {
  width: 25px;
  height: 25px;
  margin-right: 10px; 
}

.cup p {
  color: #d2d2d2;
  font-size: 1.5rem;
  margin: 0;
}

.inner-counter {
  width: 100%;
  min-height: 7vh;
  display: flex;
  justify-content: center;
}

.inner-counter > div {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inner-counter > div > h1 {
  font-size: 5rem;
  margin: 0;
}

.inner-counter > div > h3,
.slider-counter > h4 {
  font-size: 1.7rem;
  color: rgb(210, 210, 210);
}

.slider-counter .comunity {
  padding: 3px 26px 3px 26px;
  font-size: 1.6rem !important;
  margin-left: 8px;
  background-color: #4287f5;
  border-radius: 20px !important;
  transition: all 0.2;
  margin-bottom: 20px;
}
.hide {
  display: none;
}

.show {
  display: block;
}

.slider-counter .comunity i {
  color: white;
  margin-right: 10px;
}

.slider-counter .comunity:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  background-color: rgb(108, 150, 217);
}

.valueConparent {
  width: 100%;
  min-height: 7vh;
  margin: auto;
}

.valueCont {
  width: 100%;
  min-height: 6vh;
  border-radius: 50px;
  position: relative;
  margin-top: 35px;
}

.valueCont div:nth-child(1) {
  font-size: 2rem;
  color: var(--primary);
}

.valueCont div:nth-child(1) div:nth-child(1) {
  text-align: start;
}

.valueCont div:nth-child(1) div:nth-child(2) {
  text-align: center;
}

.valueCont div:nth-child(1) div:nth-child(3) {
  text-align: end;
}

.value-inner {
  border: 2px solid var(--primary);
  width: 100%;
  height: 3vh;
  border-radius: 50px;
  position: relative;
}

.value-inner > div {
  position: absolute;
  height: 100%;
  background-color: var(--primary);
  border-radius: 50px 50px 50px 50px;
}

.c-section-1 .items-colorfull {
  width: 90%;
  height: 20px;
  border-radius: 10px;
  border: 2px solid var(--primary);
  margin-bottom: 15px;
  position: relative;
}
@media (min-width: 900px) and (max-width: 1200px) {

  .com-section-3 h1 {
    font-size: 4rem;
  }

  .valueConparent {
    width: 100%;
  }

  .valueCont {
    margin-top: 35px;
  }

  .com-section-3 .portfolio-item-container > div:nth-child(2) {
    width: 40%;
  }
  
}
@media (max-width: 1100px) {
  .com-section-3 .portfolio-item.has-subtitle {
    height: 465px;
  }
  .com-section-3 .portfolio-item.no-subtitle {
    height: 400px;
   }
  .portfolio-item h6{
    width: 90%;
    height: 82px;
  }
  .com-section-3 .portfolio-item-container > div {
    height: 490px;
  }
}
@media (max-width:1000px){
  .com-section-3 .portfolio-item.has-subtitle {
    height: 465px;
   }
   .com-section-3 .portfolio-item.no-subtitle {
    height: 352px;
   }
   .portfolio-item h6{
    height: 105px;
  }
   .com-section-3 .portfolio-item-container > div{
    height: 510px;
   }
}
@media (min-width:900px) and (max-width:980px){
  .com-section-3 .portfolio-item.has-subtitle {
    height: 471px;
   }
   .com-section-3 .portfolio-item.no-subtitle {
    height: 401px;
   }
   .com-section-3 .portfolio-item-container > div{
    height: 457px;
   }
}
@media (max-width: 800px) {
  .com-section-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .com-section-3 .gallery-thumbs.swiper{
    height: 100px;
  }

  .com-section-3 h1 {
    width: 100%;
    margin: 0;
  }

  .com-section-3 .portfolio-item-container {
    justify-content: center;
  }

  .com-section-3 .portfolio-item-container > div {
    margin: 0;
    margin-top: 5px;
    height: 210px;
    width: 100%;
    padding: 0;
  }

  .com-section-3 .portfolio-item:nth-child(2) {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .com-section-3 .portfolio-item:nth-child(2) > div:nth-child(1) > div {
    display: flex;
    justify-content: center;
  }

  .slider-counter {
    width: 100% !important;
    height: 40vh;
    padding: 0;
    padding-bottom: 5px;
  }

  .cup img {
    width: 20px;
    height: 20px;
    margin-right: 5px; 
  }

  .inner-counter {
    width: 100%;
  }

  .slider-counter .comunity {
    padding: 3px 26px 3px 26px;
    font-size: 1.5rem !important;
    margin-left: 8px;
    background-color: #4287f5;
    border-radius: 10px;
    transition: all 0.2;
    margin-bottom: 20px;
  }

  .inner-counter > div > h1 {
    font-size: 2.5rem;
    margin: 0;
  }

  .inner-counter > div > h3 {
    font-size: 1.5rem;
    font-weight: 300;

  }
  .valueCont {
    width: 100%;
    margin: auto;
    margin-top: 35px;
  }

  .valueCont div {
    font-size: 1.5rem;
  }
  .valueCont div:nth-child(1) {
    font-size: 1.5rem;
    color: var(--primary);
  }
  .valueConparent .progress {
    height: 1.5vh !important;
  }

}
@media (min-width: 600px) and (max-width: 800px) {
  .com-section-3 {
    width: 75%;
  }

  .valueCont {
    margin: auto;
    margin-top: 35px;
  }

  .com-section-3 .portfolio-item-container > div {
    margin: 0;
    margin-top: 20px;
    height: 300px;
    width: 100%;
    padding: 0;
  }
}
@media (min-width: 1001px) and (max-width: 1100px) {
  .com-section-3 {
    width: 900px;
  }

  .gallery-thumbs .swiper-slide {
    width: 18% !important;
    height: 58px;
  }

  .com-section-3 .portfolio-item-container > div {
    margin: 0;
    margin-top: 20px;
    width: 45%;
    padding: 0;
  }
}
@media (min-width: 800px) and (max-width: 1000px) {
  .com-section-3 {
    width: 700px;
  }
  .gallery-thumbs .swiper-slide {
    height: 44px;
  }
}
@media (max-width:800px){
  .com-section-3 .portfolio-item.has-subtitle {
    height: 733px;
   }
   .com-section-3 .portfolio-item.no-subtitle {
    height: 684px;
   }
   .portfolio-item h6{
    height: 55px;
  }
   .gallery-thumbs .swiper-slide{
    height: 94px;
   }
}
@media (min-width: 601px) and (max-width: 786px) {
  .com-section-3 {
    width: 650px;
  }

  .com-section-3 > h1 {
    text-align: start;
  }

  .com-section-3 .portfolio-item-container > div {
    margin: 0;
    margin-top: 20px;
    height: 375px;
    width: 70%;
    padding: 0;
  }

  .com-section-3 .portfolio-item-container > div:nth-child(2) {
    height: 300px;
  }
 

  .gallery-thumbs .swiper-slide {
    width: 14% !important;
    height: 100%;
  }
}
@media (max-width:780px){
  .gallery-thumbs .swiper-slide{
   height: 73px;
  }
}
@media (min-width: 481px) and (max-width: 600px) {
  .com-section-3 {
    width: 500px;
  }
}
@media (max-width: 500px) {
  .com-section-3 .btn-wrapper {
    flex-direction: column;
    margin-top: -1px;
    
  }
  .com-section-3 h1 {
    font-size: 2.5rem;
  }

  .valueConparent {
    width: 100%;
    margin-top: 110px;
  }

  .com-section-3 .btn-wrapper .luckydrip,
  .com-section-3 .btn-wrapper button,
  .com-section-3 .btn-wrapper a {
    width: 100%;
    font-size: 14px;
  }
  .com-section-3 .btn-wrapper .tickets-addtocart{
    margin-right: 20px;
    width: 60%;
    margin-left: 20px;
    padding: 0 20px;
  }
  .main-section .navigation-button {
    background-color: var(--primary); 
    font-size: 1.5rem;
    padding: 3px 8px;
    font-weight: 600;
    border-radius: 3px;
    margin-bottom: 15px;
    margin-left: 26px;
    margin-right: 29px;
  }
  .gallery-thumbs .swiper-slide {
    height: 44px;
  }
}
@media (min-width: 346px) and (max-width: 480px) {
  .com-section-3 {
    width: 324px;
  }
  .com-section-3 .portfolio-item.has-subtitle {
    height: 462px;
  }
  .com-section-3 .portfolio-item.no-subtitle {
    height: 400px;
  }
  .portfolio-item h6 {
    width: 75%;
    height: 105px;
}
} 
@media (max-width:377px){
  .main-section .navigation-button {
    margin-bottom: 10px;
    margin-left: 8px;
    margin-right: 10px;
  }
}
@media (max-width: 345px) {
  .com-section-3 {
    width: 270px;
  }

  .com-section-3 h1 {
    font-size: 2.5rem;
    font-weight: 200;
  }

  .com-section-3 .portfolio-item.has-subtitle {
    height: 380px;
  }
  .com-section-3 .portfolio-item.no-subtitle {
    height: 320px;
  }
  .com-section-3 .portfolio-item:nth-child(1) h1 {
    font-size: 15px;
    font-weight: 500;
    
  }
  .portfolio-item h6 {
    width: 75%;
    height: 125px;
}

  .inner-counter > div > h3,
.slider-counter > h4 {
  font-size: 1.3rem;
  color: rgb(210, 210, 210);
}
}



/* TICKETS */

.tickets,
.instantPrice {
  width: 1070px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.instantPrice{
  margin-top: 30px;
  align-items: center;
}

.tickets h1,
.instantPrice h1 {
  width: 100%;
  font-size: 3.5rem;
  font-weight: 200;
  margin-left: 5rem;
  cursor: pointer;
  color: var(--primary);
}

.tickets p,
.instantPrice p {
  width: 100%;
  margin-top: 20px;
  color: rgb(210, 210, 210);
  font-size: 1.8rem;
}

.luckydrip {
  padding: 5px 20px 5px 20px;
  font-size: 2rem;
  background-color: var(--primary);
  color: black;
  align-self: flex-end;
  position: relative;
  border-radius: 20px;
  cursor: pointer;
}

.luckydrip span {
  min-width: 45px;
  min-height: 40px;
  border-radius: 50%;
  background-color: gold;
  position: absolute;
  right: -3%;
  top: -30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.luckydrip:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.alphabets,
.ticketsNo {
  width: 750px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 3px;
  margin-top: 1px;
  border: none;
  border-radius: 0 !important;
}
.ticketsNo{
  height: 120px;
  overflow: hidden;
  transition: all .5s;
}
.ticketsNo.less{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow: auto;
  transition: all .5s;
}

.alphabets a {
  width: 40px;
  height: 40px;
  border: 1px solid var(--primary) !important;
  font-size: 2rem;
  margin-right: 15px;
  background-color: transparent;
  color: rgb(210, 210, 210);
  margin-top: 15px;
  margin-bottom: 0px;
  border-radius: 0 !important;
}

.alphabets a.active,
.alphabets a:active,
.alphabets a:focus {
  border: 1px solid var(--primary) !important;
  background-color: var(--primary) !important;
  color: black !important;
  border-radius: 0 !important;
}

.alphabets a:hover {
  background-color: var(--primary);
  color: black;
}

.ticketsNo {
  width: 90%;
}

.ticketsNo button {
  min-width: 90px;
  height: 40px;
  font-size: 2.3rem;
  margin-right: 15px;
  background-color: transparent;
  color: black;
  margin-top: 15px;
  margin-bottom: 0px;
  background-color: var(--primary);
  cursor: pointer;
}



.ticketsNo button:disabled {
  background-color: rgb(90, 90, 90);
  cursor: not-allowed;
}

.ticketsNo button:hover,
.ticketsNo .activeTicket {
  background-color: gold;
}

.ticketsNo button:disabled:hover {
  background-color: gray;
}

.tickets-add {
  align-self: flex-end;
}

.tickets-addtocart {
  padding: 5px 20px 5px 20px;
  font-size: 2rem;
  background-color: var(--primary);
  color: black;
  cursor: pointer;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-right: 0;
  position: relative;
  border-radius: 20px;
}

.tickets-addtocart:hover {
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
}
.stickyBar {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 120px; 
  z-index: 999;
  background-color: #161413;

  /* text-align: center; */
}
.discription {
  width: 100%;
  margin-top: 10px;
  padding-bottom: 10px;
  line-height: 40px;
}

.discription > h1 {
  font-size: 3.5rem;
  color: var(--primary);
  margin: 0;
}

.discription > div > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.discription > div > div > p {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.discription p{
  font-size: 1.4rem;
  line-height: 24px;
}

.discription p p{
  margin-top: 0;
}

.discription hr {
  background-color: gray;
}
.rules .comunity {
  width: 230px;
  padding: 5px 20px 5px 20px !important;
  font-size: 1.44rem;
  margin-left: 5px;
  background-color: #4287f5;
  border-radius: 30px;
  transition: all 0.2;
  margin-top: 15px;
  display: inline-flex;
  height: 2.8rem;
}

.rules .comunity a {
  -webkit-transform: translateY(-25%);
          transform: translateY(-25%);
}

.rules  i {
  color: white;
  margin-right: 10px;
}

.rules .comunity:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  background-color: rgb(108, 150, 217);
}

.discription h4 {
  font-size: 1.5rem;
  margin-top: 15px;
  color: rgb(210, 210, 210);
}

@media (max-width: 1000px) {
  .discription > div > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
  }

  .discription > div > div p {
    font-size: 1.5rem !important;
  }

  .discription > div > div button {
    align-self: center;
  }
}

@media (max-width: 345px) {
  .tickets,
  .instantPrice {
    width: 270px;
    justify-content: center;
    align-items: center;
  }

  .rules .comunity {
  margin-top: 0px;

}

  .alphabets,
  .ticketsNo {
    width: 270px;
  }

  .alphabets a {
    margin-right: 8px;
    margin-left: 6px;
  }

  .ticketsNo button {
    min-width: 64px;
    margin-right: 1.5px;
    margin-left: 1.5px;
    font-size: 13px;
     /* margin-top: 2px;
     margin-bottom: 2px; */
     margin-top: 3px;

  }
  .ticketsNo{
    height: 89px;
  }

  .tickets-addtocart {
    padding: 2px 10px 2px 10px;
    font-size: 1.3rem;
    margin-bottom: 20px;
    margin-top: 5px;
  }
  .discription {
    width: 100%;
    padding-bottom: 20px;
    line-height: 0px;
  }
}

@media (max-width: 324px) {
  .tickets h1, 
  .instantPrice h1 {
    margin-left: 1.2rem;
  }
  .tickets h1,
  .instantPrice h1 {
    font-size: 2rem;
    font-weight: 200; 
  }
  .instantPrice h1{
    padding-left: 3.4rem;
  }
  .tickets p,
  .instantPrice p {
    font-size: 1.5rem;  
  }
  .alphabets a {
    font-size: 1.3rem;
    width: 28px;
    height: 28px;
    margin-top: 4px;
  }
  .rules .comunity {
    width: 130px;
    padding: 5px 3px 0px 5px !important;
    font-size: 1.1rem;
    margin: 3px 0px 3px 70px;
    height: 1.8rem;
  }
  .rules .comunity i {
    color: white;
    margin-right: 5px;
  }

}
@media (max-width: 426px) {
  .tickets p,
  .instantPrice p {
  margin-top: 5px;
  font-size: 1.5rem;  
  }
  .discription p{
    line-height: 22px;
  }
  .discription {
    width: 100%;
    margin-top: 10px;
    padding-bottom: 10px;
    line-height: 30px;
  }
  .luckydrip span {
    min-width: 32px;
    min-height: 28px;
  }
  .com-section-3 .portfolio-item button {
    padding: 3px 20px 3px 20px;
  }
}

@media (min-width: 324px) and (max-width: 480px) {
  .tickets,
  .instantPrice {
    width: 310px;
    justify-content: center;
    align-items: center;
  }
  .discription p{
    line-height: 22px;
  }
  .tickets-addtocart {
    padding: 4px 15px 4px 15px;
    font-size: 1.7rem;
    margin-bottom: 20px;
    margin-top: 5px;
  }
  .tickets h1{
    margin-left: 1.8rem;
  }
  .instantPrice h1{
    margin-left: 3.5rem;
    padding-left: 1.3rem;
  }

   .tickets h1,
  .instantPrice h1 {
    font-size: 2.5rem;
    font-weight: 200;
  }
  .tickets p,
  .instantPrice p {
    font-size: 1.5rem;  

  }

  .alphabets,
  .ticketsNo {
    width: 310px;
    margin-left: 3px;
  }

  .alphabets a {
    font-size: 1.5rem;
    width: 31px;
    height: 31px;
    margin-top: 4px;
  }


  .ticketsNo button {
    min-width: 70px;
    margin-right: 2px;
    margin-left: 2px;
    font-size: 13px;
     /* margin-top: 2px;
     margin-bottom: 2px; */
     margin-top: 4px;

  }
  .ticketsNo{
    height: 90px;
  }

  .rules .comunity {
    width: 150px;
    padding: 2px 15px 5px 10px !important;
    font-size: 1.1rem;
    margin: 3px 0px 3px 75px;
    height: 2rem;
  }
  .rules .comunity i {
    color: white;
    margin-right: 5px;
  }
}
@media (min-width: 421px) and (max-width: 480px) {
  .rules .comunity {
    width: 150px;
    padding: 2px 15px 5px 10px !important;
    font-size: 1.1rem;
    height: 2rem;
    margin: 3px 0px 3px 77px;
  }
}
@media (min-width: 481px) and (max-width: 600px) {
  .tickets,
  .instantPrice {
    width: 500px;
  }

  .alphabets,
  .ticketsNo {
    width: 500px;
  }

  .ticketsNo {
    margin-left: 0;
  }

  .alphabets a {
    margin-right: 8px;
    margin-left: 7px;
  }

  .ticketsNo button {
    min-width: 79px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .alphabets a {
    font-size: 1.5rem;
  }
  .rules .comunity {
    width: 220px;
    padding: 0px 5px 0px 10px !important;
    font-size: 1.1rem;
    margin-top: 20px;
    height: 1.8rem;
  }
  .rules .comunity i {
    color: white;
    margin-right: 5px;
  }
}

@media (min-width: 601px) and (max-width: 786px) {
  .tickets,
  .instantPrice {
    width: 650px;
  }

  .alphabets,
  .ticketsNo {
    width: 500px;
  }

  .ticketsNo {
    margin-left: 0;
  }

  .alphabets a {
    margin-left: 10px;
    margin-right: 4px;
  }

  .ticketsNo button {
    min-width: 79px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .rules .comunity {
    width: 250px;
    padding: 0px 10px 0px 20px !important;
    font-size: 1.4rem;
    margin-top: 20px;
    height: 1.8rem;
  }
  .rules .comunity i {
    color: white;
    margin-right: 5px;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .tickets,
  .instantPrice {
    width: 700px;
  }

  .alphabets,
  .ticketsNo {
    width: 500px;
  }

  .ticketsNo {
    margin-left: 0;
  }

  .alphabets a {
    margin-left: 10px;
    margin-right: 4px;
  }

  .ticketsNo button {
    min-width: 79px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (min-width: 1001px) and (max-width: 1100px) {
  .tickets,
  .instantPrice {
    width: 900px;
  }

  .alphabets,
  .ticketsNo {
    width: 750px;
  }

  .ticketsNo {
    margin-left: 0;
  }

  .alphabets a {
    margin-right: 14px;
  }

  .ticketsNo button {
    min-width: 79px;
    margin-right: 14px;
  }

  .discription > div > div button {
    font-size: 1.4rem !important;
  }
}

@media (max-width:345px){
  
  .gallery-thumbs .swiper-slide {
    width: 18% !important;
    height: 36px;
    opacity: 1;
  }
  .discription {
    line-height: 25px;
}
}
.comp-description {
  /* border: 1px solid red; */
  width: 400px;
  word-wrap: break-word;
}
.stickyBar{
	position: -webkit-sticky;
	position: sticky;
	top: 120px; 
	z-index: 999;
	background-color: #161413;
}

.section-2 {
	width: 1070px;
	margin: 0 auto;
	margin-top: 50px;
}

            
.section-2 .fortune {
	position: relative;
	font-weight: 200;
	font-size:3.5rem;
	margin-top:-34px;
	margin-left: 5.1rem;
	cursor: pointer;
	color: var(--primary);
}
.section-2 hr{
	margin-top: 0;
	margin-left: -6px;
	width: 100%;
	background-color: gray;
}
.section-2 .fortune span {
	cursor: pointer;
	position: absolute;
	font-size: 4.9rem;
	font-weight: 200;
	bottom: -10px;
	left:-53px;
	width: 4.9rem; 
	color: var(--primary);
	text-align: center; 
}

Video {
	position: relative;
	z-index: 1;
}

.video_uppar_text {
	position: absolute;
	top: 30%;
	left: 35%;
	-webkit-transform: translate(-50%, 0);
	        transform: translate(-50%, 0);
	z-index: 2;
	color: white;
	font-size: 30px;
}

.eCard {
	width: 100%;
	min-height: 320px;
	display: flex;
	align-items: center;
}

.eCard>div {
	width: 30%;
	min-height: 230px;
}

.eCard>div>video {
	width: 300px;
	height: 226px;
}

.eCard>div:nth-child(1)>h1 {
	font-size: 3rem;
	color: var(--primary);
	text-align: center;
}

.eCard>div:nth-child(2) {
	margin-left: 10px;
	color: rgb(290, 290, 290);
	display: flex;
	flex-direction: column;
}

.eCard>div:nth-child(2) p {
	font-size: 1.8rem;
	margin-bottom: 20px;
}

@media (min-width: 0px) and (max-width: 800px) {
	

	.section-2 hr{
		margin-left: -6px;
		width: 103%;
	}

	/* .section-2 h1 {
		text-align: center;
		padding-bottom: 15px;
		font-size: 3.4rem;
		font-weight: 400;
	} */
	.eCard>div:nth-child(2) p {
		font-size: 1.3rem;
		margin-bottom: 5px;
	}
	.eCard {
		flex-direction: column;
		justify-content: space-evenly;
	}
	.eCard h1{
		padding-bottom: 0;
	}

	.eCard>div {
		width: 100%;
	}

	.eCard>div:nth-child(2) {
		padding: 0;
		margin: 0;
		margin-top: 0px;
		text-align: center;
	}
	.section-2 .fortune {
		margin-top: -5px;
		margin-left: 4.3rem;
		font-size: 2.5rem;
	}
	.section-2 .fortune span {
		left: -48px;
		font-size:4.4rem ;
		width:4.4rem;
	}
}

@media (max-width: 345px) {
	.section-2 .fortune {
		font-size: 2rem;
	}
	.section-2 {
		width: 270px;
		margin-top: 20px;
	}
	.eCard>div {
		min-height: 132px;
	}
	.eCard>div>video {
		width: 275px;
		height: 206px;
	}
	.eCard>div {
		min-height: 132px;
	}
	.eCard>div>video {
		width: 275px;
		height: 206px;
	}

	/* .section-2 h1 {
		font-size: 2.5rem;
		font-weight: 400;
	} */
	.eCard h1{
		padding-bottom: 0;
	}
}

@media (min-width: 346px) and (max-width: 480px) {
	.section-2 {
		width: 300px;
		margin-top: 20px;
	}
	.eCard>div {
		min-height: 113px;
	}
	/* .section-2 h1 {
		font-size: 2.5rem;
	} */
	.eCard h1{
		padding-bottom: 0;
	}
}

@media (min-width: 481px) and (max-width: 600px) {
	.section-2 {
		width: 500px;
	}
}

@media (min-width: 601px) and (max-width: 785px) {
	.section-2 {
		width: 650px;
	}


	.eCard {
		width: 100%;
		align-items: center;
	}

	.eCard>div {
		width: 50%;
		align-items: center !important;
	}

	.eCard>div>video {
		width: 321px;
		height: 242px;
	}

	.eCard>div:nth-child(2) {
		align-items: flex-start;
	}
}

@media (min-width: 800px) and (max-width: 1000px) {
	.section-2 {
		width: 700px;
	}

	.eCard>div {
		width: 50%;
		min-height: 200px;
	}

	.eCard>div>video {
		width: 321px;
		height: 242px;
	}

	.eCard>div:nth-child(2) {
		margin-top: -20px;
		align-items: flex-start;
	}
}

@media (min-width: 1000px) and (max-width: 1100px) {
	.section-2 {
		width: 900px;
	}

	.eCard>div {
		width: 35%;
		min-height: 200px;
	}

	.eCard>div>video {
		width: 321px;
		height: 242px;
	}

	.eCard>div:nth-child(2) {
		margin-top: -20px;
		align-items: flex-start;
	}
}

.stickyBar {
  position: -webkit-sticky; 
  position: sticky;
  top: 120px; 
  z-index: 999;
  background-color: #161413;
}

.stickBar{
  position: -webkit-sticky;
  position: sticky;
  top: 116px;
  padding-top: 5px;
  z-index: 999;
  background-color: #161413;
}

.instantPrice .row {
   height: 0px;
  overflow: hidden;
  grid-row-gap: "30px";
  row-gap: "30px";
  transition: all 0.3s; 
}

/* .instantPrice .row .show::-webkit-scrollbar {
  height: 100px;
  width: 10px;
}
.instantPrice .row .show::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.instantPrice .row .show::-webkit-scrollbar-thumb {
  background: #888;
}
.instantPrice .row .show::-webkit-scrollbar-thumb:hover {
  background: #555;
} */


/* Ticket Counter */
.ticket-counter {
  display: flex;
  align-items: center;
}

.lucky-dip-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #2a2a2a;
  padding: 10px 15px;
  border-radius: 10px;
  max-width: 500px;
  margin: 0 auto;
}
.counter-number {
  font-size: 22px;
  border: 1px solid #00ffea;
  padding: 5px 10px;
  font-weight: bold;
  border-radius: 4px;
  background-color: #202020;
  color: #00ffea;
  display: flex;
  align-self: center;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.counter-number:hover {
  border: 1px solid #00d7c5;
  background-color: #3c3c3c;
  color: #00efdb;
}

.lucky-dip-slider {
  width: 20vw;
  margin: 0 20px;
  cursor: pointer;
  -webkit-appearance: none;
  height: 4px;
  background: linear-gradient(to right, #00d7c5 0%, #00d7c5 0%, #464646 0%);
  background: #464646;
  outline: none;
  border-radius: 5px;
}

.lucky-dip-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #00d7c5;
  cursor: pointer;
}

.lucky-dip-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}

.ticket-limit-text {
  font-size:14px;
  color: gold;
}

.progress-container {
  display: flex;
  min-width: 1070px;
  width: 100%;
  height: 50px;
  font-family: Arial, sans-serif;
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
position:"relative";
}

.available {
  width:65%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgb(0 188 173) 0, #00ffe9 100%);
  color: black;
  font-size: 18px;
  -webkit-clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
  font-weight: 500;
}

.won {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #ffd700 0, #ffb20a 100%);
  width:35%;
  right:0rem;
  bottom:6rem;
  color: black;
  -webkit-clip-path: polygon(17% 0, 100% 0, 100% 100%, 0 100%);
          clip-path: polygon(17% 0, 100% 0, 100% 100%, 0 100%);
  font-size: 18px;
  font-weight: 500;
}

.progress-text {
  display: block;
  text-align: center;
}

.progress-text span {
  display: block;
  font-size: 12px;
  color: black;
}


.instantPrice .row.show {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* overflow-y: scroll;  */

}

.instantPrice .item-wrapper {
  overflow: hidden;
  margin-left: 5px;
  
}

.instantPrice .items {
  min-height: 182px;
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background-position: center;
  background-size: cover;
  transition: all 0.5s;
  align-items: center;
  overflow: hidden;
  margin-bottom: 8px;
}

.instantPrice .items:hover {
  margin-bottom: 10px;
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.instantPrice .ticket-no,
.instantPrice .not-won {
  border-radius: 50px;
  width: 100%;
  height: 21.27px;
  background-color: rgb(90, 90, 90);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  cursor: pointer;
  transition: all 0.3s;
  color: rgb(210, 210, 210);
}

.instantPrice .not-won {
  width: 100px;
  background-color: transparent;
  border: 2px solid var(--primary);
  color: var(--primary);
}

.instantPrice .items:hover .not-won,
.instantPrice .items:hover .ticket-no {
  -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
          animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.instantPrice p {
  margin-top: 15px;
}

.instantPrice hr,
.tickets hr {
  margin-top: 0;
  width: 100%;
  background-color: gray;
}
.discriptionSection span,
.ticketsSection span,
.priceSection span {
  
  cursor: pointer;
  font-size: 4.9rem;
  margin-right: 2rem;
  left: 1px;
  bottom: 66px;
  position: absolute;
  width: 4.2rem; 
  text-align: center; 
}

.ticketsSection span{
  bottom:8px;
}
.discriptionSection h1{
  margin-left: 0rem;
}
.discriptionSection span{
  bottom: -4px;
}

/* .instantPrice .seeAll,
.tickets .seeAll {
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 5px 5px 5px 0px ;
  background-color: #161413;
  border: none;
  outline: none;
  color: rgb(210, 210, 210);
  transition: all 0.3s;
}
.instantPrice .seeAll{
  display: flex;
  align-items: center;
  gap: 30px;
  margin-left:470px ;
  padding: 5px 5px 5px 0px ;
  background-color: #161413;
  border: none;
  outline: none;
  color: rgb(210, 210, 210);
  transition: all 0.3s;
}
.tickets .seeAll {
  width: 100%;
  justify-content: flex-end;
}

.instantPrice .seeAll label,
.tickets .seeAll label {
  margin-bottom: 0;
  font-size: 18px;
  cursor: pointer;
}

.instantPrice .seeAll i,
.tickets .seeAll i {
  font-size: 35px;
  transform: rotate(-90deg);
  position: relative;
  bottom: 2px;
  transition: all 0.3s;
  cursor: pointer;
} */
.instantPrice .stickBar .hrLine{
  width:1070px;

}
.instantPrice .seeAll.less i,
.tickets .seeAll.less i {
  font-size: 35px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  position: relative;
  bottom: 2px;
}

@-webkit-keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-2px, 0, 0);
            transform: translate3d(-2px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }
}

@keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-2px, 0, 0);
            transform: translate3d(-2px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }
}

.instantPrice .see-all {
  margin-left: auto;
}
@media (min-width: 1800px) {
  .lucky-dip-slider {
    width: 13vw;
  }
}
@media (max-width:1101px){
  .progress-container{
    min-width:900px;
  }
}
@media (max-width: 776px) {
  .instantPrice .seeAll,
  .tickets .seeAll {
    justify-content: flex-end;
  }
  .progress-container{
    min-width:650px;
  }
}

@media (max-width: 991px) {
  .instantPrice .row {
    height: 0px;
    overflow: hidden;
    transition: all 0.3s;
  }
}

@media (max-width: 575px) {
  .lucky-dip-container{
    padding: 10px 10px;
  }
  .counter-number {
    font-size: 16px;
    padding: 3px 8px;
  }
  
  .lucky-dip-slider {
    width: 30vw;
    margin: 0 15px;
    margin-bottom: 5px;
  }
  
  
  .progress-container{
    min-width:320px;
  }

  .available {
    -webkit-clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%);
    font-size: 15px;
  }
  
  .won {
    -webkit-clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%);
    font-size: 15px;
  }
  .progress-text span {
    font-size: 10px;
  }
  
  .instantPrice .seeAll label,
  .tickets .seeAll label {
    font-size: 16px;
  }
  .instantPrice .seeAll {
margin-left: 0;
  }
  .instantPrice .seeAll i,
.tickets .seeAll i {
  font-size: 25px;
}
  .instantPrice .row {
    height: 0px;
    overflow: hidden;
    transition: all 0.3s;
  }
  .instantPrice .seeAll.less i,
.tickets .seeAll.less i {
  font-size: 25px;
}
.stickyBar {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  padding-top: 3px;
  top: 56px; 
  padding-top: 3px;
  z-index: 999;
  text-align: center;
}

.stickBar{
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 56px;
  z-index: 999;
  background-color: #161413;
}

.discriptionSection span,
.ticketsSection span,
.priceSection span {
  left: 1px;
  font-size:4.4rem ;
  bottom: 62px;
}
  
.priceSection span {
  left: 3px;
}
  
.ticketsSection span{
  bottom: 4px;
}
.discriptionSection span{
  bottom: -7px;
}
}

@media (max-width: 324px) {
  .progress-container{
    min-width:280px;
  }
  .instantPrice .seeAll label,
  .tickets .seeAll label {
    font-size: 15px;
    
  }

  .instantPrice .seeAll i,
.tickets .seeAll i {
  font-size: 20px;
}
.instantPrice .seeAll.less i,
.tickets .seeAll.less i {
  font-size: 25px;
}
.stickyBar {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 56px; /* Adjust this value to be the height of the navbar */
  z-index: 999;
  text-align: center;
}

.stickBar{
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 56px;
  z-index: 999;
  background-color: #161413;
}
}

.col ,
.col-3{
  padding-left: 0;
  padding-right: 0;
}

.activeTickets-detail {
  min-height: 10vh;
  display: flex;
}
.activeTickets-detail > div {
  border: 1px solid rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(210, 210, 210);
}
.activeTickets-detail .heading h1{
   font-size: 2rem;
}
.activeTickets-detail > div > figure {
  margin: 0;
  width: 100px;
  height: 80px;
  background-position: center;
  background-size: cover;
}
.activeTickets-detail > div > h1 {
  font-size: 1.5rem;
}
.ticket-ids p {
  font-size: 1.3rem;
}
@media (max-width: 600px) {
  /* .activeTickets-detail > div > h1 {
    font-size: 0.5rem;
  } */
  .ticket-ids p {
    font-size: 1rem;
  }
  .heading h3{
    font-size: 1.0rem;
  }
  .activeTickets-detail > div {
    border: 3px solid rgb(0, 0, 0);
  }
  .activeTickets-detail .heading h1{
    font-size: 1.0rem;
 }
 .activeTickets-detail > div > figure {
  margin: 0;
  width: 75px;
  height: 56px;
  background-position: center;
  background-size: cover;
}

}

.terms {
  width: 1070px;
  height: 430px;
  margin: 60px auto 70px auto;
  -webkit-user-select: none;
          user-select: none;
}
.terms > div {
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  border-top: 1px solid rgb(90, 90, 90);
  border-bottom: 1px solid rgb(90, 90, 90);
  margin: auto;
}
.terms h4 {
  font-size: 2.4rem;
  color: rgb(210, 210, 210);
  margin-top: 1.5rem !important;
  margin-top: 7px;
}
.terms p {
  margin-top: 5px;
  font-size: 1.4rem;
  color: rgb(210, 210, 210);
}

@media (max-width: 500px) {
  .terms {
    height: 460px;
  }
}

@media (max-width: 800px) {
  .terms {
    width: 100%;
  }
  .terms h4 {
  margin-left:15px;
    font-size: 2rem;
  }
  .terms p {
    font-size: 1.2rem;
  }
}
.terms > div::-webkit-scrollbar-thumb {
  background: rgb(53, 53, 53);
  border: 3px solid gray; /* creates padding around scroll thumb */
}

.terms > div::-webkit-scrollbar-track {
  background: gray;
}

.terms > div::-webkit-scrollbar {
  width: 9px;
}

@media (max-width: 345px) {
  .terms {
    width: 270px;
  }
}

@media (min-width: 346px) and (max-width: 480px) {
  .terms {
    width: 310px;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .terms {
    width: 500px;
  }
}
@media (min-width: 601px) and (max-width: 786px) {
  .terms {
    width: 650px;
  }
}
@media (min-width: 800px) and (max-width: 1000px) {
  .terms {
    width: 700px;
  }
}
@media (min-width: 1001px) and (max-width: 1100px) {
  .terms {
    width: 900px;
  }
}

.col ,
.col-3{
  padding-left: 0;
  padding-right: 0;
}

.activeTickets-detail {
  min-height: 10vh;
  display: flex;
}
.activeTickets-detail > div {
  border: 1px solid rgb(22, 20, 19);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2A2A2A;
  color: rgb(210, 210, 210);
}
.activeTickets-detail .heading h1{
   font-size: 2rem;
}
.activeTickets-detail > div > figure {
  margin: 0;
  width: 100px;
  height: 80px;
  background-position: center;
  background-size: cover;
}
.activeTickets-detail > div > h1 {
  font-size: 1.5rem;
}
.ticket-ids p {
  font-size: 1.3rem;
}
@media (max-width: 600px) {
  /* .activeTickets-detail > div > h1 {
    font-size: 0.5rem;
  } */
  .ticket-ids p {
    font-size: 1rem;
  }
  .heading h3{
    font-size: 1.0rem;
  }
  .activeTickets-detail > div {
    border: 3px solid #161413;
    border-left: 0px;
    border-right: 0px;
  }
  .activeTickets-detail .heading h1{
    font-size: 1.0rem;
 }
 .activeTickets-detail > div > figure {
  margin: 0;
  width: 75px;
  height: 56px;
  background-position: center;
  background-size: cover;
}

}

.logIn {
  padding-bottom: 60px;
}

.form-modal {
  position: relative;
  width: 400px;
  height: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: #fff;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0 3px 20px 0px rgba(60, 237, 183, 0.2);
}
.form-modal button {
  cursor: pointer;
  position: relative;
  text-transform: capitalize;
  font-size: 4rem;
  z-index: 2;
  outline: none;
  background: #fff;
  transition: 0.2s;
}

.form-modal .btn {
  border-radius: 20px;
  border: none;
  font-size: 1.6rem;
  padding: 0.5em 1em 0.5em 1em !important;
  transition: 0.5s;
  border: 1px solid #ebebeb;
  margin-bottom: 0em;
  margin-top: 0.5em;
}

.form-modal .login {
  background: var(--primary);
  color: black;
}

.form-modal .login:hover {
  background: #222;
  color: white;
}

.form-toggle {
  position: relative;
  width: 100%;
  height: auto;
}

.form-toggle button {
  width: 100%;
  float: left;
  padding: 1.2rem;
  margin-bottom: 1em;
  border: none;
  transition: 0.2s;
  font-size: 3rem;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

#login-toggle {
  background: var(--primary);
  color: black;
}

.form-modal form {
  position: relative;
  width: 90%;
  height: auto;
  left: 50%;
  top: -20px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  flex-direction: column;
}

#login-form {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 1em;
}

#login-form button {
  width: 100%;
  margin-top: 0.5em;
  padding: 0.6em;
}

.form-modal input {
  position: relative;
  width: 100%;
  margin-top: 0em;
  margin-bottom: 0.6em;
  border-radius: 20px;
  border: none;
  background: #ebebeb;
  outline: none;
  transition: 0.4s;
  padding-left: 18px;
}

.form-modal input {
  font-size: 1.5rem;
}
.form-modal input::-webkit-input-placeholder {
  color: #222;
  font-size: 1.7rem;
}

.logIn .form-modal p {
  font-size: 1.6rem;
  padding-left: 20px;
}
.logIn .form-modal p a {
  color: black;
  text-decoration: none;
  transition: 0.2s;
}

.logIn .form-modal p a:hover {
  color: var(--primary);
}

.fa-google {
  color: #dd4b39;
}

.box-sd-effect .fa-facebook-square {
  color: #0072c6;
}

.box-sd-effect:hover {
  box-shadow: 0 10px 5px 0px rgba(60, 237, 183, 0.2);
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
}
.login {
  font-weight: bold;
}

@media only screen and (max-width: 500px) {
  .logIn {
    padding-bottom: 60px;
  }
  .form-modal {
    width: 80%;
  }
  #login-toggle {
    font-size: 3rem;
  }
  input {
    margin: 0;
  }
  #login-form {
    padding: 0;
  }
  .box-sd-effect i {
    padding-left: -10px !important;
  }
  .box-sd-effect {
    font-size: 1.4em !important;
  }
}

