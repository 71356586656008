.subtotal-section h3{
    font-size: 30px;
  }
  .pro-check-btn {
    width: 300px;
    height: 40px;
    margin-top: 15px;
    margin-right: 0px;
    background-color: #ffc439;
  border-radius: 23px;
  outline: none;
  border: none; 
    font-weight: 400;
    transition: all 0.2s;
  font-size: 1.8rem;
  }
  .pro-check-btn > i {
    text-decoration: none;
    padding-right: 8px;
  }
  .pro-check-btn:hover {
    transform: scale(1.02);
    background-color: rgba(0, 255, 234, 0.87);
    cursor: pointer;
  }
  @media (max-width: 346px){
    .subtotal-section h3{
        font-size: 17px;
      }
      .pro-check-btn {
        width: 230px;
        height: 30px;
        margin-top: 15px;
        margin-right: 29px; 
        font-weight: 400;
        transition: all 0.2s;
      font-size: 1.3rem;
      }
  }
  @media (min-width: 346px) and (max-width: 480px) {
    .subtotal-section h3{
        font-size: 21px;
      }
      .pro-check-btn {
        width: 230px;
        height: 30px;
        margin-top: 15px;
        margin-right: 29px; 
        font-weight: 400;
        transition: all 0.2s;
      font-size: 1.3rem;
      }
  }
