.main-section {
  width: 100%;
  min-height: 60vh;
}
.main-section .home-section1 {
  width: 1070px;
  margin: auto;
  margin-top: 50px;
}
.home-section1 div h1 {
  color: var(--primary);
  font-size: 5rem;
  margin-bottom: 30px !important;
  margin: -4px;
  font-weight: 200;
}
.home-section1 div p span {
  color: var(--primary);
  font-size: 3.5rem;
  font-weight: 600;
  padding-right: 1rem;
}
.home-section1 div p {
  font-weight: 400;
}
.home-section1 div p span.number:hover {
  cursor: pointer;
  color: #00b67a;
}
.home-section1 div p {
  color: rgb(210, 210, 210);
  font-size: 1.8rem;
}
.home-section1 h4 {
  color: rgb(210, 210, 210);
  font-size: 2.7rem;
  font-weight: 700;
}
.home-section1 .stars i {
  font-size: 3rem;
  background-color: #00b67a;
  color: white;
  padding: 5px;
  margin-left: 5px;
}
.home-section1 .sub-section {
  width: 62%;
  border: 1px solid var(--primary);
  margin-top: 30px;
}
.home-section1 .sub-section .img-fluid{
  width: 100%;
  height: auto;
}
.trustpilot {
  padding: 5px;
  display: flex;
  align-items: center;
}
.trustpilot i {
  font-size: 3rem;
  color: #00b67a;
}
.trustpilot h5 {
  width: fit-content;
  color: rgb(210, 210, 210);
  font-size: 2rem;
}
.home-section1 .like,
.home-section1 .comunity {
  padding: 3px 20px 3px 20px;
  font-size: small;
  font-weight: 600;
  margin-left: 8px;
  background-color: #367ef3;
  border-radius: 5px;
  transition: all 0.2;
}
.home-section1 .like i,
.home-section1 .comunity i {
  color: white;
  margin-right: 10px;
}
.home-section1 .like:hover,
.home-section1 .comunity:hover {
  /* transform: scale(1.05); */
  background-color: rgb(23, 84, 181);
}
.home-section1 .newsletter {
  margin-top: 80px;
  margin-bottom: 50px;
}
.home-section1 .newsletter button {
  padding: 5px 30px 5px 30px;
  font-size: 1.5rem;
  border-radius: 20px;
  transition: all 0.2s;
  background-color: rgb(210, 210, 210);
  font-weight: 600;
}
.home-section1 .newsletter button:hover {
  background-color: var(--primary);
}

@media (max-width: 800px) {
  .home-section1 div h1 {
    color: var(--primary);
    font-size: 3rem;
  }
  .home-section1 div p span {
    color: var(--primary);
    font-size: 2.6rem;
    font-weight: 600;
  }

  .home-section1 div p {
    color: rgb(210, 210, 210);
    font-size: 1.9rem;
  }
  .home-section1 div p:not(.last) {
    display: flex;
    flex-direction: column;
  }
  .home-section1 > div > p:nth-child(5) {
    line-height: 1.2;
  }
  .sub-section h4 {
    font-size: 2rem;
  }
  .sub-section h4,
  .stars {
    text-align: center;
  }
  .btn-group {
    display: flex;
    justify-content: center;
  }
  .home-section1 .like,
  .home-section1 .comunity {
    padding: 8px 10px 8px 10px !important;
    font-size: 1rem;
    background-color: #4287f5;
  }
  .home-section1 .like i,
  .home-section1 .comunity i {
    color: white;
    margin-right: 5px;
  }
  .home-section1 .newsletter {
    width: 100% !important;
    margin-top: 40px;
  }
  .home-section1 .newsletter button {
    padding: 5px 30px 5px 30px;
    font-size: 1.5rem;
  }
  .home-section1 .sub-section .img-fluid{
    width: 132px;
    height: 132px;
  }
}

@media (max-width: 345px) {
  .main-section .home-section1 {
    width: 270px;
    text-align: center !important;
  }
  .home-section1 div h1 {
    color: var(--primary);
    font-size: 3rem;
  }
  .sub-section {
    width: 100% !important;
  }
}

@media (min-width: 346px) and (max-width: 480px) {
  .main-section .home-section1 {
    width: 310px;
    text-align: center !important;
  }
  .sub-section {
    width: 100% !important;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .main-section .home-section1 {
    width: 500px;
    text-align: center;
  }
  .sub-section {
    width: 100% !important;
    height: 40vh !important;
    margin-top: 30px;
  }
  .main-section .sub-section h4 {
    text-align: start !important;
  }
  .main-section .btn-group {
    justify-content: flex-start;
  }
}

@media (min-width: 601px) and (max-width: 786px) {
  .main-section .home-section1 {
    width: 650px;
  }
  .main-section .sub-section {
    width: 90%;
  }
  .main-section .sub-section h4 {
    text-align: start !important;
  }
  .main-section .btn-group {
    justify-content: flex-start;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .main-section .home-section1 {
    width: 700px;
  }
  .main-section .sub-section {
    width: 90%;
  }
  .main-section .sub-section h4 {
    text-align: start !important;
  }
  .main-section .btn-group {
    justify-content: flex-start;
  }
}

@media (min-width: 1001px) and (max-width: 1100px) {
  .main-section .home-section1 {
    width: 900px;
  }
  .main-section .sub-section {
    width: 75%;
  }
  .home-section1 .newsletter button {
    font-size: 1.5rem;
  }
}
