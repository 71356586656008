/* SLIDER SECTION */

.valueConparent .progress {
  border: 1.5px solid rgb(0 188 173) !important;
  width: 100% !important;
  height: 1.7vh !important;
  border-radius: 16px;
  position: relative;
  background-color: transparent;
  margin-bottom: 15px;
  box-shadow: 3px 4px 10px rgb(0 94 102 / 88%); 
}
.valueConparent .progress-bar {
  height: 100%;
  width: 0; 
  border-radius: 9px;
    background: var(--primary);
    background: linear-gradient(90deg, rgb(0 188 173) 0, #00ffe9 100%);
    transition: width .6s, background-color 0.4s ease;;
}

.com-section-3 {
  width: 1070px;
  margin: auto;
  margin-top: 10px;
}

.com-section-3 h1 {
  font-size: 4rem;
  text-align: center;
  font-weight: 200;
  color: var(--primary);
}

.main-section .navigation-buttons {
  display: flex;
  justify-content: space-between;
}

.main-section .navigation-button {
  background-color: var(--primary); 
  font-size: 2rem;
  padding: 5px 10px;
  font-weight: 600;
  border-radius: 4px;
  margin-top: 40px;
  margin-left: 29px;
  margin-right: 57px;
  border: none; 
  color: black;
  cursor: pointer;
}

.main-section .navigation-button:hover {
  opacity: 0.9; 
}

.main-section .navigation-button:disabled {
  background-color: #d2d2d2; 
  color: #303030;
  cursor: not-allowed; 
  opacity: 0.6;
}

.com-section-3 .portfolio-item-container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.com-section-3 .portfolio-item-container > div {
  width: 45%;
  height: 540px;
  margin-top: 30px;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.com-section-3 .btn-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 22px;
  margin-bottom: 50px;
}

.com-section-3 .btn-wrapper button {
  margin-left: 130px;

  
}

.com-section-3 .btn-wrapper .luckydrip {
  width: 100%;
  padding: -1px 0px;
  max-width: 494px;
}

.com-section-3 .portfolio-item-container > div:nth-child(2) {
  width: 45%;
  /* margin-left: 70px; */
  padding: 0 20px;
}

.com-section-3 .portfolio-item button {
  padding: 3px 30px 3px 30px;
  font-size: 2rem;
  border-radius: 20px;
  margin-top: 5px;
}

.com-section-3 .portfolio-item:nth-child(1) h1 {
  font-size: 2rem;
  font-weight: 600;
  color: rgb(210, 210, 210);
  margin-top: 10px;
}

.com-section-3 .swiper {
  width: 100%;
  height: 500px;
  margin-left: auto;
  margin-right: auto;
}

.com-section-3 .portfolio-item:nth-child(2) {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.com-section-3 .portfolio-item.has-subtitle {
 height: 522px;
}
.com-section-3 .portfolio-item.no-subtitle {
  height: 468px;
 }

.com-section-3 .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: rgb(210, 210, 210);
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: all 0.5s;
}

.com-section-3 .swiper-slide {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.com-section-3 button:hover {
  background-color: var(--primary);
}

/* .com-section-3 .swiper-slide:hover {
  transform: scale(1.07);
} */

.com-section-3 .swiper-button-next,
.com-section-3 .swiper-button-prev {
  border: 2px solid white;
  border-radius: 50%;
  padding: 20px;
  font-size: 1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-next::after,
.com-section-3 .swiper-button-prev::after {
  font-size: 3rem;
  color: white;
}

.com-section-3 > h1 input {
  border-radius: 20px;
  font-size: 3rem;
  padding: 0;
  padding-left: 10px;
  outline: none;
  border: none;
}

.gallery-thumbs .swiper-slide {
  width: 18% !important;
  max-width: 18%; /* Preventing the width from exceeding the fixed width */
  height: 70px;
  opacity: 1;
  flex: 0 0 auto;
}

.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

.gallery-thumbs.swiper {
  height: 20%;
}

.slider-counter {
  width: 80%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0px;
  padding-right: 10px;
}

.portfolio-item h6,
.slider-counter > p {
  color: #d2d2d2;
  font-size: 1.5rem;
}
.portfolio-item h6{
  width: 90%;
  height: 65px;
  margin-top: 5px;
  text-align: left;
  overflow: hidden;
}
.cup {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
}

.cup img {
  width: 25px;
  height: 25px;
  margin-right: 10px; 
}

.cup p {
  color: #d2d2d2;
  font-size: 1.5rem;
  margin: 0;
}

.inner-counter {
  width: 100%;
  min-height: 7vh;
  display: flex;
  justify-content: center;
}

.inner-counter > div {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inner-counter > div > h1 {
  font-size: 5rem;
  margin: 0;
}

.inner-counter > div > h3,
.slider-counter > h4 {
  font-size: 1.7rem;
  color: rgb(210, 210, 210);
}

.slider-counter .comunity {
  padding: 3px 26px 3px 26px;
  font-size: 1.6rem !important;
  margin-left: 8px;
  background-color: #4287f5;
  border-radius: 20px !important;
  transition: all 0.2;
  margin-bottom: 20px;
}
.hide {
  display: none;
}

.show {
  display: block;
}

.slider-counter .comunity i {
  color: white;
  margin-right: 10px;
}

.slider-counter .comunity:hover {
  transform: scale(1.05);
  background-color: rgb(108, 150, 217);
}

.valueConparent {
  width: 100%;
  min-height: 7vh;
  margin: auto;
}

.valueCont {
  width: 100%;
  min-height: 6vh;
  border-radius: 50px;
  position: relative;
  margin-top: 35px;
}

.valueCont div:nth-child(1) {
  font-size: 2rem;
  color: var(--primary);
}

.valueCont div:nth-child(1) div:nth-child(1) {
  text-align: start;
}

.valueCont div:nth-child(1) div:nth-child(2) {
  text-align: center;
}

.valueCont div:nth-child(1) div:nth-child(3) {
  text-align: end;
}

.value-inner {
  border: 2px solid var(--primary);
  width: 100%;
  height: 3vh;
  border-radius: 50px;
  position: relative;
}

.value-inner > div {
  position: absolute;
  height: 100%;
  background-color: var(--primary);
  border-radius: 50px 50px 50px 50px;
}

.c-section-1 .items-colorfull {
  width: 90%;
  height: 20px;
  border-radius: 10px;
  border: 2px solid var(--primary);
  margin-bottom: 15px;
  position: relative;
}
@media (min-width: 900px) and (max-width: 1200px) {

  .com-section-3 h1 {
    font-size: 4rem;
  }

  .valueConparent {
    width: 100%;
  }

  .valueCont {
    margin-top: 35px;
  }

  .com-section-3 .portfolio-item-container > div:nth-child(2) {
    width: 40%;
  }
  
}
@media (max-width: 1100px) {
  .com-section-3 .portfolio-item.has-subtitle {
    height: 465px;
  }
  .com-section-3 .portfolio-item.no-subtitle {
    height: 400px;
   }
  .portfolio-item h6{
    width: 90%;
    height: 82px;
  }
  .com-section-3 .portfolio-item-container > div {
    height: 490px;
  }
}
@media (max-width:1000px){
  .com-section-3 .portfolio-item.has-subtitle {
    height: 465px;
   }
   .com-section-3 .portfolio-item.no-subtitle {
    height: 352px;
   }
   .portfolio-item h6{
    height: 105px;
  }
   .com-section-3 .portfolio-item-container > div{
    height: 510px;
   }
}
@media (min-width:900px) and (max-width:980px){
  .com-section-3 .portfolio-item.has-subtitle {
    height: 471px;
   }
   .com-section-3 .portfolio-item.no-subtitle {
    height: 401px;
   }
   .com-section-3 .portfolio-item-container > div{
    height: 457px;
   }
}
@media (max-width: 800px) {
  .com-section-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .com-section-3 .gallery-thumbs.swiper{
    height: 100px;
  }

  .com-section-3 h1 {
    width: 100%;
    margin: 0;
  }

  .com-section-3 .portfolio-item-container {
    justify-content: center;
  }

  .com-section-3 .portfolio-item-container > div {
    margin: 0;
    margin-top: 5px;
    height: 210px;
    width: 100%;
    padding: 0;
  }

  .com-section-3 .portfolio-item:nth-child(2) {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .com-section-3 .portfolio-item:nth-child(2) > div:nth-child(1) > div {
    display: flex;
    justify-content: center;
  }

  .slider-counter {
    width: 100% !important;
    height: 40vh;
    padding: 0;
    padding-bottom: 5px;
  }

  .cup img {
    width: 20px;
    height: 20px;
    margin-right: 5px; 
  }

  .inner-counter {
    width: 100%;
  }

  .slider-counter .comunity {
    padding: 3px 26px 3px 26px;
    font-size: 1.5rem !important;
    margin-left: 8px;
    background-color: #4287f5;
    border-radius: 10px;
    transition: all 0.2;
    margin-bottom: 20px;
  }

  .inner-counter > div > h1 {
    font-size: 2.5rem;
    margin: 0;
  }

  .inner-counter > div > h3 {
    font-size: 1.5rem;
    font-weight: 300;

  }
  .valueCont {
    width: 100%;
    margin: auto;
    margin-top: 35px;
  }

  .valueCont div {
    font-size: 1.5rem;
  }
  .valueCont div:nth-child(1) {
    font-size: 1.5rem;
    color: var(--primary);
  }
  .valueConparent .progress {
    height: 1.5vh !important;
  }

}
@media (min-width: 600px) and (max-width: 800px) {
  .com-section-3 {
    width: 75%;
  }

  .valueCont {
    margin: auto;
    margin-top: 35px;
  }

  .com-section-3 .portfolio-item-container > div {
    margin: 0;
    margin-top: 20px;
    height: 300px;
    width: 100%;
    padding: 0;
  }
}
@media (min-width: 1001px) and (max-width: 1100px) {
  .com-section-3 {
    width: 900px;
  }

  .gallery-thumbs .swiper-slide {
    width: 18% !important;
    height: 58px;
  }

  .com-section-3 .portfolio-item-container > div {
    margin: 0;
    margin-top: 20px;
    width: 45%;
    padding: 0;
  }
}
@media (min-width: 800px) and (max-width: 1000px) {
  .com-section-3 {
    width: 700px;
  }
  .gallery-thumbs .swiper-slide {
    height: 44px;
  }
}
@media (max-width:800px){
  .com-section-3 .portfolio-item.has-subtitle {
    height: 733px;
   }
   .com-section-3 .portfolio-item.no-subtitle {
    height: 684px;
   }
   .portfolio-item h6{
    height: 55px;
  }
   .gallery-thumbs .swiper-slide{
    height: 94px;
   }
}
@media (min-width: 601px) and (max-width: 786px) {
  .com-section-3 {
    width: 650px;
  }

  .com-section-3 > h1 {
    text-align: start;
  }

  .com-section-3 .portfolio-item-container > div {
    margin: 0;
    margin-top: 20px;
    height: 375px;
    width: 70%;
    padding: 0;
  }

  .com-section-3 .portfolio-item-container > div:nth-child(2) {
    height: 300px;
  }
 

  .gallery-thumbs .swiper-slide {
    width: 14% !important;
    height: 100%;
  }
}
@media (max-width:780px){
  .gallery-thumbs .swiper-slide{
   height: 73px;
  }
}
@media (min-width: 481px) and (max-width: 600px) {
  .com-section-3 {
    width: 500px;
  }
}
@media (max-width: 500px) {
  .com-section-3 .btn-wrapper {
    flex-direction: column;
    margin-top: -1px;
    
  }
  .com-section-3 h1 {
    font-size: 2.5rem;
  }

  .valueConparent {
    width: 100%;
    margin-top: 110px;
  }

  .com-section-3 .btn-wrapper .luckydrip,
  .com-section-3 .btn-wrapper button,
  .com-section-3 .btn-wrapper a {
    width: 100%;
    font-size: 14px;
  }
  .com-section-3 .btn-wrapper .tickets-addtocart{
    margin-right: 20px;
    width: 60%;
    margin-left: 20px;
    padding: 0 20px;
  }
  .main-section .navigation-button {
    background-color: var(--primary); 
    font-size: 1.5rem;
    padding: 3px 8px;
    font-weight: 600;
    border-radius: 3px;
    margin-bottom: 15px;
    margin-left: 26px;
    margin-right: 29px;
  }
  .gallery-thumbs .swiper-slide {
    height: 44px;
  }
}
@media (min-width: 346px) and (max-width: 480px) {
  .com-section-3 {
    width: 324px;
  }
  .com-section-3 .portfolio-item.has-subtitle {
    height: 462px;
  }
  .com-section-3 .portfolio-item.no-subtitle {
    height: 400px;
  }
  .portfolio-item h6 {
    width: 75%;
    height: 105px;
}
} 
@media (max-width:377px){
  .main-section .navigation-button {
    margin-bottom: 10px;
    margin-left: 8px;
    margin-right: 10px;
  }
}
@media (max-width: 345px) {
  .com-section-3 {
    width: 270px;
  }

  .com-section-3 h1 {
    font-size: 2.5rem;
    font-weight: 200;
  }

  .com-section-3 .portfolio-item.has-subtitle {
    height: 380px;
  }
  .com-section-3 .portfolio-item.no-subtitle {
    height: 320px;
  }
  .com-section-3 .portfolio-item:nth-child(1) h1 {
    font-size: 15px;
    font-weight: 500;
    
  }
  .portfolio-item h6 {
    width: 75%;
    height: 125px;
}

  .inner-counter > div > h3,
.slider-counter > h4 {
  font-size: 1.3rem;
  color: rgb(210, 210, 210);
}
}



/* TICKETS */

.tickets,
.instantPrice {
  width: 1070px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.instantPrice{
  margin-top: 30px;
  align-items: center;
}

.tickets h1,
.instantPrice h1 {
  width: 100%;
  font-size: 3.5rem;
  font-weight: 200;
  margin-left: 5rem;
  cursor: pointer;
  color: var(--primary);
}

.tickets p,
.instantPrice p {
  width: 100%;
  margin-top: 20px;
  color: rgb(210, 210, 210);
  font-size: 1.8rem;
}

.luckydrip {
  padding: 5px 20px 5px 20px;
  font-size: 2rem;
  background-color: var(--primary);
  color: black;
  align-self: flex-end;
  position: relative;
  border-radius: 20px;
  cursor: pointer;
}

.luckydrip span {
  min-width: 45px;
  min-height: 40px;
  border-radius: 50%;
  background-color: gold;
  position: absolute;
  right: -3%;
  top: -30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.luckydrip:hover {
  transform: scale(1.05);
}

.alphabets,
.ticketsNo {
  width: 750px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 3px;
  margin-top: 1px;
  border: none;
  border-radius: 0 !important;
}
.ticketsNo{
  height: 120px;
  overflow: hidden;
  transition: all .5s;
}
.ticketsNo.less{
  height: fit-content;
  overflow: auto;
  transition: all .5s;
}

.alphabets a {
  width: 40px;
  height: 40px;
  border: 1px solid var(--primary) !important;
  font-size: 2rem;
  margin-right: 15px;
  background-color: transparent;
  color: rgb(210, 210, 210);
  margin-top: 15px;
  margin-bottom: 0px;
  border-radius: 0 !important;
}

.alphabets a.active,
.alphabets a:active,
.alphabets a:focus {
  border: 1px solid var(--primary) !important;
  background-color: var(--primary) !important;
  color: black !important;
  border-radius: 0 !important;
}

.alphabets a:hover {
  background-color: var(--primary);
  color: black;
}

.ticketsNo {
  width: 90%;
}

.ticketsNo button {
  min-width: 90px;
  height: 40px;
  font-size: 2.3rem;
  margin-right: 15px;
  background-color: transparent;
  color: black;
  margin-top: 15px;
  margin-bottom: 0px;
  background-color: var(--primary);
  cursor: pointer;
}



.ticketsNo button:disabled {
  background-color: rgb(90, 90, 90);
  cursor: not-allowed;
}

.ticketsNo button:hover,
.ticketsNo .activeTicket {
  background-color: gold;
}

.ticketsNo button:disabled:hover {
  background-color: gray;
}

.tickets-add {
  align-self: flex-end;
}

.tickets-addtocart {
  padding: 5px 20px 5px 20px;
  font-size: 2rem;
  background-color: var(--primary);
  color: black;
  cursor: pointer;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-right: 0;
  position: relative;
  border-radius: 20px;
}

.tickets-addtocart:hover {
  transform: scale(1.06);
}
.stickyBar {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 120px; 
  z-index: 999;
  background-color: #161413;

  /* text-align: center; */
}
.discription {
  width: 100%;
  margin-top: 10px;
  padding-bottom: 10px;
  line-height: 40px;
}

.discription > h1 {
  font-size: 3.5rem;
  color: var(--primary);
  margin: 0;
}

.discription > div > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.discription > div > div > p {
  width: fit-content;
}

.discription p{
  font-size: 1.4rem;
  line-height: 24px;
}

.discription p p{
  margin-top: 0;
}

.discription hr {
  background-color: gray;
}
.rules .comunity {
  width: 230px;
  padding: 5px 20px 5px 20px !important;
  font-size: 1.44rem;
  margin-left: 5px;
  background-color: #4287f5;
  border-radius: 30px;
  transition: all 0.2;
  margin-top: 15px;
  display: inline-flex;
  height: 2.8rem;
}

.rules .comunity a {
  transform: translateY(-25%);
}

.rules  i {
  color: white;
  margin-right: 10px;
}

.rules .comunity:hover {
  transform: scale(1.05);
  background-color: rgb(108, 150, 217);
}

.discription h4 {
  font-size: 1.5rem;
  margin-top: 15px;
  color: rgb(210, 210, 210);
}

@media (max-width: 1000px) {
  .discription > div > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
  }

  .discription > div > div p {
    font-size: 1.5rem !important;
  }

  .discription > div > div button {
    align-self: center;
  }
}

@media (max-width: 345px) {
  .tickets,
  .instantPrice {
    width: 270px;
    justify-content: center;
    align-items: center;
  }

  .rules .comunity {
  margin-top: 0px;

}

  .alphabets,
  .ticketsNo {
    width: 270px;
  }

  .alphabets a {
    margin-right: 8px;
    margin-left: 6px;
  }

  .ticketsNo button {
    min-width: 64px;
    margin-right: 1.5px;
    margin-left: 1.5px;
    font-size: 13px;
     /* margin-top: 2px;
     margin-bottom: 2px; */
     margin-top: 3px;

  }
  .ticketsNo{
    height: 89px;
  }

  .tickets-addtocart {
    padding: 2px 10px 2px 10px;
    font-size: 1.3rem;
    margin-bottom: 20px;
    margin-top: 5px;
  }
  .discription {
    width: 100%;
    padding-bottom: 20px;
    line-height: 0px;
  }
}

@media (max-width: 324px) {
  .tickets h1, 
  .instantPrice h1 {
    margin-left: 1.2rem;
  }
  .tickets h1,
  .instantPrice h1 {
    font-size: 2rem;
    font-weight: 200; 
  }
  .instantPrice h1{
    padding-left: 3.4rem;
  }
  .tickets p,
  .instantPrice p {
    font-size: 1.5rem;  
  }
  .alphabets a {
    font-size: 1.3rem;
    width: 28px;
    height: 28px;
    margin-top: 4px;
  }
  .rules .comunity {
    width: 130px;
    padding: 5px 3px 0px 5px !important;
    font-size: 1.1rem;
    margin: 3px 0px 3px 70px;
    height: 1.8rem;
  }
  .rules .comunity i {
    color: white;
    margin-right: 5px;
  }

}
@media (max-width: 426px) {
  .tickets p,
  .instantPrice p {
  margin-top: 5px;
  font-size: 1.5rem;  
  }
  .discription p{
    line-height: 22px;
  }
  .discription {
    width: 100%;
    margin-top: 10px;
    padding-bottom: 10px;
    line-height: 30px;
  }
  .luckydrip span {
    min-width: 32px;
    min-height: 28px;
  }
  .com-section-3 .portfolio-item button {
    padding: 3px 20px 3px 20px;
  }
}

@media (min-width: 324px) and (max-width: 480px) {
  .tickets,
  .instantPrice {
    width: 310px;
    justify-content: center;
    align-items: center;
  }
  .discription p{
    line-height: 22px;
  }
  .tickets-addtocart {
    padding: 4px 15px 4px 15px;
    font-size: 1.7rem;
    margin-bottom: 20px;
    margin-top: 5px;
  }
  .tickets h1{
    margin-left: 1.8rem;
  }
  .instantPrice h1{
    margin-left: 3.5rem;
    padding-left: 1.3rem;
  }

   .tickets h1,
  .instantPrice h1 {
    font-size: 2.5rem;
    font-weight: 200;
  }
  .tickets p,
  .instantPrice p {
    font-size: 1.5rem;  

  }

  .alphabets,
  .ticketsNo {
    width: 310px;
    margin-left: 3px;
  }

  .alphabets a {
    font-size: 1.5rem;
    width: 31px;
    height: 31px;
    margin-top: 4px;
  }


  .ticketsNo button {
    min-width: 70px;
    margin-right: 2px;
    margin-left: 2px;
    font-size: 13px;
     /* margin-top: 2px;
     margin-bottom: 2px; */
     margin-top: 4px;

  }
  .ticketsNo{
    height: 90px;
  }

  .rules .comunity {
    width: 150px;
    padding: 2px 15px 5px 10px !important;
    font-size: 1.1rem;
    margin: 3px 0px 3px 75px;
    height: 2rem;
  }
  .rules .comunity i {
    color: white;
    margin-right: 5px;
  }
}
@media (min-width: 421px) and (max-width: 480px) {
  .rules .comunity {
    width: 150px;
    padding: 2px 15px 5px 10px !important;
    font-size: 1.1rem;
    height: 2rem;
    margin: 3px 0px 3px 77px;
  }
}
@media (min-width: 481px) and (max-width: 600px) {
  .tickets,
  .instantPrice {
    width: 500px;
  }

  .alphabets,
  .ticketsNo {
    width: 500px;
  }

  .ticketsNo {
    margin-left: 0;
  }

  .alphabets a {
    margin-right: 8px;
    margin-left: 7px;
  }

  .ticketsNo button {
    min-width: 79px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .alphabets a {
    font-size: 1.5rem;
  }
  .rules .comunity {
    width: 220px;
    padding: 0px 5px 0px 10px !important;
    font-size: 1.1rem;
    margin-top: 20px;
    height: 1.8rem;
  }
  .rules .comunity i {
    color: white;
    margin-right: 5px;
  }
}

@media (min-width: 601px) and (max-width: 786px) {
  .tickets,
  .instantPrice {
    width: 650px;
  }

  .alphabets,
  .ticketsNo {
    width: 500px;
  }

  .ticketsNo {
    margin-left: 0;
  }

  .alphabets a {
    margin-left: 10px;
    margin-right: 4px;
  }

  .ticketsNo button {
    min-width: 79px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .rules .comunity {
    width: 250px;
    padding: 0px 10px 0px 20px !important;
    font-size: 1.4rem;
    margin-top: 20px;
    height: 1.8rem;
  }
  .rules .comunity i {
    color: white;
    margin-right: 5px;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .tickets,
  .instantPrice {
    width: 700px;
  }

  .alphabets,
  .ticketsNo {
    width: 500px;
  }

  .ticketsNo {
    margin-left: 0;
  }

  .alphabets a {
    margin-left: 10px;
    margin-right: 4px;
  }

  .ticketsNo button {
    min-width: 79px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (min-width: 1001px) and (max-width: 1100px) {
  .tickets,
  .instantPrice {
    width: 900px;
  }

  .alphabets,
  .ticketsNo {
    width: 750px;
  }

  .ticketsNo {
    margin-left: 0;
  }

  .alphabets a {
    margin-right: 14px;
  }

  .ticketsNo button {
    min-width: 79px;
    margin-right: 14px;
  }

  .discription > div > div button {
    font-size: 1.4rem !important;
  }
}

@media (max-width:345px){
  
  .gallery-thumbs .swiper-slide {
    width: 18% !important;
    height: 36px;
    opacity: 1;
  }
  .discription {
    line-height: 25px;
}
}
.comp-description {
  /* border: 1px solid red; */
  width: 400px;
  word-wrap: break-word;
}