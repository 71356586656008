.ccv_payment_btn {
  padding: 5px 2px;
  cursor: pointer;
  margin: 20px;
  background-color: aliceblue;
}

.credit-card-btn,
.credit-card-btn:hover {
  width: 100%;
  background: #ffc439;
  margin-top: 22px;
  border-radius: 23px;
  height: 45px;
  vertical-align: top;
  min-height: 30px;
  max-height: 55px;
  outline: none;
  border: none;
  font-size: 16px;
  line-height: 22px;
  color: #2C2E2F;
}

.py-30px {
  padding-top: 25px;
  padding-bottom: 25px;
}

.ban-contact {
  margin: 8px;
  width: 90px;
  height: 70px;
  cursor: pointer;
  margin-top: 20px;
}
@media (max-width: 445px) {
  .credit-card-btn,
.credit-card-btn:hover {
  width: 85%;
  margin-top: 15px;
  margin-left: 30px;
  height: 40px;
  font-size: 14px;
  line-height: 20px;
}
}
@media screen and (min-width: 1000px) {
  .ban-contact {
    margin: 8px 18px;
    margin-top: 20px;
  }
  
}

