
.section-4 {
  width:1070px;
  margin: 0 auto;
  margin-top: 30px;
}
.section-4 h1 {
  margin: auto;
  font-size: 5rem;
  color: var(--primary);
}
.section-4 > div.row {
  min-height: 270px;
  border: 1px solid rgb(210, 210, 210);
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 50px;
}
.section-4 > div.row > div:nth-child(1) h2 {
  font-size: 3rem;
  color: rgb(210, 210, 210);
}
.section-4 > div.row > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.section-4 > div.row > div:nth-child(1) p {
  color: rgb(210, 210, 210);
  font-size: 1.6rem;
}
.section-4 > div.row > div:nth-child(1) p span {
  color: rgb(210, 210, 210);
  font-size: 2rem;
}
.section-4 > div.row > div:nth-child(1) i {
  font-size: 2rem;
  margin-bottom: 9px;
}

.section-4 .stars i {
  font-size: 4rem;
  background-color: #00b67a;
  color: white;
  padding: 3px;
  margin-left: 5px;
}
.section-4 .trustpilot {
  padding: 5px;
  display: flex;
  align-items: center;
}
.section-4 .trustpilot i {
  font-size: 3rem;
  color: #00b67a;
}
.section-4 .trustpilot h5 {
  width: fit-content;
  color: rgb(210, 210, 210);
  font-size: 2rem;
}
.third-slider {
  height: 70%;
  overflow: hidden;
}
.section-4 > div.row > div:nth-child(2){
  display: flex;
  align-items: center;
}

.clients {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 40px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
}
.clients > .stars i {
  font-size: 1.9rem;
  padding: 3px;
}
.clients h2 {
  width: 175px;
  font-size: 2.3rem;
  margin-top: 9px;
  color: rgb(210, 210, 210);
  overflow: hidden;
}
.clients p {
  font-size: 1.4rem;
  color: rgb(221, 220, 220);
  height: 80px;
  overflow: hidden;
}
.clients .customer {
  color: gray;
  font-size: 2rem;
}
.swiper-button-next {
  color: rgb(228, 228, 228);
  right: 0;
}
.swiper-button-prev {
  color: rgb(228, 228, 228);

  left: 0;
}
.third .swiper-button-next::after,
.third .swiper-button-prev::after {
  font-size: 4rem;
}


@media (min-width: 0px) and (max-width: 800px) {
  .section-4 {
    width: 90%;
    margin-top: 60px;
  }
  .section-4 h1 {
    font-size: 3rem;
  }
  .section-4 > .row > div:nth-child(2) {
    min-height: 250px;
  }
  .section-4 > .row > div:nth-child(2) > .third-slider .clients {
    height: 100%;
  }
  .section-4 .stars i {
    font-size: 3rem;
    background-color: var(--primary);
    color: white;
    padding: 3px;
    margin-left: 5px;
  }
  .section-4 .trustpilot {
    padding: 5px;
    display: flex;
    align-items: center;
  }
  .section-4 .trustpilot i {
    font-size: 3rem;
    color: var(--primary);
  }
  .section-4 .trustpilot h5 {
    width: fit-content;
    color: rgb(210, 210, 210);
    font-size: 2rem;
  }
  .swiper-wrapper {
    display: flex;
    align-items: center;
}
.clients {
  display: flex;
  height: 260px;
  width: 100%; /* Ensure full width is used */
  max-width: 280px; /* Add a max width for consistent card size */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  margin-top: 20px;
  gap: 20px; /* Use gap for spacing */
  margin-left: auto; /* Center align */
  margin-right: auto; /* Center align */
}

  .clients .stars {
    padding: 0 !important;
    margin-top: 0px;
    margin-left: 12px !important;
  }
  .clients > .stars i {
    font-size: 1.9rem;
    padding: 3px;
  }
  .clients h2 {
    margin-top: 0px;
    font-size: 2.5rem;
    color: rgb(210, 210, 210);
  }
  .clients p {
    color: rgb(210, 210, 210);
    height: 125px;
    width: 45vw;
    overflow: hidden;
  }
  .clients .customer {
    display: block;
    font-size: 1.5rem;
    color: rgb(0, 0, 0);
    font: 600;
  }

.swiper-button-next,
.swiper-button-prev {
    color: #000; 
    background-color: rgba(255, 255, 255, 0.7); 
    border-radius: 50%; 
    width: 40px;
    height: 40px; 
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute; 
    top: 60%; 
    z-index: 10; 
    transform: translateY(-50%); 
    cursor: pointer;
}

.third .swiper-button-next {
  background-color: #696969de !important;
    right: 4px; 
}

.third .swiper-button-prev {
    left: 4px; 
}


.third .swiper-button-next:hover,
.third .swiper-button-prev:hover {
    background-color: #696969de; 
    color: #fff; 
}

.third .swiper-button-disabled {
  opacity: 0.5 !important; 
  pointer-events: none; 
}

  .third .swiper-button-next::after,
  .third .swiper-button-prev::after {
    font-size: 3rem;
  }
}

@media (max-width:345px)
{
  .section-4
  {
    width: 270px;
    margin-top: 20px;
  }
  .section-4 h1
  {
    text-align: center;
  }
}

@media (min-width:346px) and (max-width:480px)
{
  .main-section .section-4
  {
    width: 310px;
    text-align: center !important;
    margin-top: 20px;
  }
  .section-4 h1
  {
    text-align: center;
  }
}

@media (min-width:481px) and (max-width:600px)
{
  .main-section .section-4
  {
    width: 500px;
    text-align: start !important;
  }
}

@media (min-width:601px) and (max-width:786px)
{
  .main-section .section-4
  {
    width: 650px;
  }
}

@media (min-width:800px) and (max-width:1000px)
{
  .main-section .section-4
  {
    width: 700px;
  }
}

@media (min-width:1001px) and (max-width:1100px)
{
  .main-section .section-4
  {
    width: 900px;
  }
}

@media (max-width:410px){

}

@media (max-width:376px){

}
@media (max-width:321px){
  .clients {
    
  }
  .clients h2 {
    width: 151px;
  }
  .clients p {
    margin: 0px 2px;
  }
  .clients .stars{
    margin-left: 5px !important;
  }
  .clients .customer {
    margin-top: 15px;
  }
}