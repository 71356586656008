.terms {
  width: 1070px;
  height: 430px;
  margin: 60px auto 70px auto;
  user-select: none;
}
.terms > div {
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  border-top: 1px solid rgb(90, 90, 90);
  border-bottom: 1px solid rgb(90, 90, 90);
  margin: auto;
}
.terms h4 {
  font-size: 2.4rem;
  color: rgb(210, 210, 210);
  margin-top: 1.5rem !important;
  margin-top: 7px;
}
.terms p {
  margin-top: 5px;
  font-size: 1.4rem;
  color: rgb(210, 210, 210);
}

@media (max-width: 500px) {
  .terms {
    height: 460px;
  }
}

@media (max-width: 800px) {
  .terms {
    width: 100%;
  }
  .terms h4 {
  margin-left:15px;
    font-size: 2rem;
  }
  .terms p {
    font-size: 1.2rem;
  }
}
.terms > div::-webkit-scrollbar-thumb {
  background: rgb(53, 53, 53);
  border: 3px solid gray; /* creates padding around scroll thumb */
}

.terms > div::-webkit-scrollbar-track {
  background: gray;
}

.terms > div::-webkit-scrollbar {
  width: 9px;
}

@media (max-width: 345px) {
  .terms {
    width: 270px;
  }
}

@media (min-width: 346px) and (max-width: 480px) {
  .terms {
    width: 310px;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .terms {
    width: 500px;
  }
}
@media (min-width: 601px) and (max-width: 786px) {
  .terms {
    width: 650px;
  }
}
@media (min-width: 800px) and (max-width: 1000px) {
  .terms {
    width: 700px;
  }
}
@media (min-width: 1001px) and (max-width: 1100px) {
  .terms {
    width: 900px;
  }
}
