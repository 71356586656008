.customized {
    color: white;
    font-size: 1.3rem;
}
.Toastify__toast--default, .Toastify__toast--error {
    background: rgb(90, 90, 90) !important;
}

.Toastify__progress-bar--default{
    background: var(--primary) !important;
}
.Toastify__close-button {
    color: white !important;
    opacity: 0.5 !important;
}
.Toastify__close-button--default:hover {
    color: var(--primary) !important;
    opacity: 1 !important;
}
.Toastify__close-button--error:hover {
    color: red !important;
    opacity: 1 !important;
}

.Toastify__progress-bar--error {
    background-color: red !important;
}

@media (max-width:500px){
    .Toastify__toast--default, .Toastify__toast--error {
        background: rgb(90, 90, 90) !important;
        z-index: 1050;
        top: 50px; 
    }
}