.transectionorderdetail {
  background-color: black;
  width: auto;
}

.transectionorderdetail .header {
  background-color: black;
  color: white;
  border-bottom: 1px solid rgb(90, 90, 90);
}

.transectionorderdetail .title {
  font-size: 24px;
}

.transectionorderdetail .body {
  background-color: black;
  color: white;
  font-size: 18px;
}

.transectionorderdetail .button {
  font-size: 18px;
  background-color: rgb(0, 255, 234);
  color: black;
}

/* Responsive Adjustments */
@media (max-width: 450px) {
  .transectionorderdetail .title {
    font-size: 16px;
  }

  .transectionorderdetail .head {
    font-size: 12px;
  }

  .transectionorderdetail .body {
    font-size: 11px;
  }

  .transectionorderdetail .button {
    font-size: 11px;
    font-weight: 500;
  }
}


.transection-section {
  margin-top: 8.1px;
  /* overflow-x: scroll; */
}
.overflower-video1,
.overflower-video{
  width: 713px;
  display: flex;
  flex-direction: column;
}
.transection-head1,
.transection-head{
  display: flex;
  flex-direction: row;
}
.transection-col1 .col,
.transection-col .col{
  margin: 0px;
  color: #161413;
   background-color: #ffd452;
   border: 1px solid #5a5a5a;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0px;
}
.transection-row-col .col,
.transection-row1 .col,
.transection-row .col{
  margin: 0px;
  color: white;
   border: 1px solid #5a5a5a;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0px;
}

.transection-head1,
.transection-head,
.transection-detail1,
.transection-detail {
  min-height: 7vh;
  width: 100%;
  display: flex;
}

.transection-head1 h1,
.transection-head h1{
  font-size: 2rem;
}

.transection-detail1>div ,
.transection-detail>div {
  border: 1px solid #5a5a5a;
  display: flex;

  align-items: center;
  justify-content: center;
  color: rgb(210, 210, 210);
  padding: 0px;
}
.transection-head1>div>h2,
.transection-head>div>h2 {
  color: var(--primary);
  font-size: 1.5rem;
}
.transection-detail1>div>h1,
.transection-detail>div>h1 {
  font-size: 1.5rem;
  overflow-wrap: anywhere;
}

.transection-detail1>div>p,
.transection-detail>div>p {
  font-size: 1.5rem;
  font-weight: 500;
}

.modal-backdrop.show {
  opacity: .1 !important;
}
.detail h3{
  color: rgb(210,210,210);
   margin: "10px";
  font-size: 1.75rem;
}
.table-responsive {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: auto;
  max-width: 800px;
  border: 1px solid rgb(90, 90, 90);
}

.transectionorderdetail .modal-content {
  border: 1px solid rgb(90, 90, 90);
}

.transectionorderdetail .close {
  font-size: 30px;
  color: rgb(0, 255, 234);
  opacity: 1;
}

/* Pagination CSS */

.pagination .page-link {
  color: #d2d2d2; 
  border: 1px solid rgb(153 145 145);
  padding: 1rem 1.5rem !important;
  margin: 0 0rem;
  border-radius: 0.25rem;
  background-color: #2A2A2A;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pagination .page-link:hover {
  background-color: #161413; 
  color: black; 
  border-color: #ccc; 
}

.pagination .page-item.active .page-link {
  background-color: var(--primary); 
  border-color: var(--primary);
  color: black !important; 
}

.pagination .page-item.disabled .page-link {
  color: #d2d2d2 !important; /* Make sure color is not overridden */
  background-color: #565656 !important; /* Same for background color */
  border-color: #656565 !important;
}

.pagination .page-link:focus {
  color: #161413;
  font-weight: 600;
  border: #161413;
  box-shadow: 0 0 0 0.2rem rgba(114, 254, 238, 0.911); 
}

.pagination .page-link span {
  vertical-align: middle;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  padding: 1rem 1.2rem !important;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  background-color: #2A2A2A;
  border-radius: 6px !important;
  border-color: #d2d2d2 !important;
}
.page-item:first-child .page-link:hover,
.page-item:last-child .page-link:hover {
  background-color: var(--primary) !important;
  color:#161413 !important;
  border-color: #666666 !important;
}

@media (max-width: 800px) {
  .transection-detail {
    flex-direction: column;
  }
  .transection-table{
    overflow-x: scroll;
  }
  .transection-head1 h1,
  .transection-head h1{
    font-size: 1.3rem;
  }
  .overflower-video{
    width: 300px;
    display: flex;
    flex-direction: row;
  }
  .overflower-video1{
    width: 375px;
  }
  .transection-head1 .amount,
.transection-detail1 .amount {
  flex: 0 0 22%;
  max-width: 22%;
}
  .transection-detail .col {
    margin: 0px 5px 5px 5px;
    color: white;
    border: 1px solid #4a4a4a; 
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 5px; 
    background-color: #333;
    font-size: 14px; 
    white-space: nowrap; 
    overflow: hidden; 
  }
  .transection-head>div {
  padding: 20px 10px 20px 10px;
}

.transection-col .col{
  padding: 20px 45px 20px 45px;
  margin: 0px 10px 5px 5px;
}
.transection-col1 .col{
  padding: 20px 10px 20px 20px;
  margin: 12px 10px 5px 5px;
}
.transection-row-col .col{
  padding: 20px 10px 20px 20px;
  margin: 12px 52px 5px 5px;
}
.transection-row .col{
  padding: 20px 10px 20px 20px;
  margin: 0px 10px 5px 5px;
}
.detail h3{
  font-size: 1.4rem;
}
.transection-head1>div>h2,
  .transection-head>div>h2 {
    color: var(--primary);
    font-size: 1rem;
  }
  .transection-detail1>div>h1,
  .transection-detail>div>h1 {
    font-size: 1.2rem;
  }
  .transection-head{
    display: flex;
    flex-direction: column;
  }
  .transection-head1,
  .transection-detail1 {
    min-height:5.5vh;
}
  .transection-detail1>div>p,
  .transection-detail>div>p {
    font-size: 1rem;
  }
  .setWidth{
    width: 550px;
  }
  .pagination .page-link {
    color: #d2d2d2; 
    border: 1px solid rgb(153 145 145);
    padding: 0.5rem 0.8rem !important;
    margin: 0 0rem;
    border-radius: 0.25rem;
    background-color: #2A2A2A;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  .pagination .page-link:focus {
    color: #161413;
    font-weight: 500;
    border: #161413;
    box-shadow: 0 0 0 0.2rem rgba(114, 254, 238, 0.911); 
  }
  .page-item:first-child .page-link,
  .page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-radius: 3px !important;
    padding: 0.5rem 0.8rem !important;
    background-color: #2A2A2A;
    border-color: #d2d2d2 !important;
    
  }
  @media (max-width: 324px) {
    .transection-col .col{
      margin: 0px 8px 5px 2px;
    }
  }
}