.address-container {
	display: flex;
	width: 100%; /* Ensure container takes full width */
  }

  .address-container input {
	flex: 1; /* Allow input to take available space */
	color: tan;
	border: 1px solid #ccc; /* Optional: add border */
	border-radius: 5px; /* Optional: add border-radius */
  }
  .address-container textarea {
	width: 100%;
	max-width: 700px;
	resize: none; /* Prevent resizing */
  }

.account-detail h2 {
	color: rgb(255, 255, 255);
	padding-top: 6px;
}
.account-detail h1 {
	padding: 9px 10px 9px 10px;
	font-size: 1.8rem;
	background-color: #2a2a2a;
	color: #b0b0b0;
	margin-top: 8px;
	margin-bottom: 8px;
	padding: 1.33rem;
}
.account-detail h1 i {
	margin-right: 10px;
}
.account-detail h1 input {
	width: fit-content;
	background-color: transparent;
	outline: none;
	border: none;
}
.account-detail h1 input,
.account-detail h1 input::placeholder {
	color: #b0b0b0;
	text-transform: capitalize;
}
.account-detail h1.textarea {
	padding-left: 50px;
	padding-bottom: 40px;
}
.account-detail h1 textarea {
	width: 100%;
	margin: auto;
	background-color: transparent;
	border: none;
	outline: none;
	color: #b0b0b0;
	line-height: 1.6;
	text-transform: capitalize;
}
.account-detail h1 textarea::placeholder {
	color: #b0b0b0;
}
.account-detail button {
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 15px;
	padding-right: 15px;
	font-size: 2rem;
	border-radius: 10px;
	background-color: var(--primary);
	color: black;
	margin-top: 20px;
	margin-bottom: 10px;
}

.pr-2{
	padding-right: 1rem;
}

@media (min-width: 600px) and (max-width: 900px) {
	.account-detail h2 {
		text-align: center;
	}
}

@media (max-width: 344px){
	.account-detail h1 {
		padding: 9px 10px 9px 10px;
		font-size: 1.1rem;
		margin-top: 0px;
		margin-bottom: 2.5px;
		padding: 1.1rem;
	}
	.address-container textarea {
		width: 100%;
		max-width: 500px;
	  }
	  .account-detail button {
		width: 93%;
		margin-left: 10px;
		padding-top: 8px;
		padding-bottom: 8px;
		padding-left: 10px;
		padding-right: 10px;
		font-size: 1.5rem;
		margin-top: 15px;
		margin-bottom: 5px;
	}
	
}
@media (min-width: 345px) and (max-width: 480px){
	.account-detail h1 {
		padding: 9px 10px 9px 10px;
		font-size: 1.2rem;
		margin-top: 0px;
		margin-bottom: 2.6px;
		padding: 1.2rem;
	}
	.address-container textarea {
		width: 100%;
		max-width: 500px;
	  }
	  .account-detail button {
		width: 95%;
		margin-left: 8px;
		padding-top: 8px;
		padding-bottom: 8px;
		padding-left: 10px;
		padding-right: 10px;
		font-size: 1.5rem;
		margin-top: 15px;
		margin-bottom: 5px;
	}
}
