.withdraw-btn {
  height: 60px;
  margin: 0px 0px 10px 0px;
  cursor: pointer;
  padding: 20px;
  background-color: #ffd452;
  border-radius: 20px;
  display: flex;
  margin: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #161413;
  font-size: 2rem;
}
.withdraw-btn.disabled {
  cursor: not-allowed;
}
.warning {
  color: #ff7000;
  font-size: 1.75rem;
}
.widthdraw-btns {
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.paypal-connected {
  font-size: 1.75rem;
  color: white;
  margin: 10px 0;
  text-align: center;
}
.paypal-connected-email {
  font-size: 1.4rem;
  text-align: center;
  color: #ffffff;
  margin: 10px 0;
}
.widthdraw-errors {
  color: #d2461b;
  padding-top: 20px;
  font-size: 1.5rem;
}

.pagination .page-link {
  color: #d2d2d2; 
  border: 1px solid rgb(153 145 145);
  padding: 1rem 1.5rem !important;
  margin: 0 0rem;
  border-radius: 0.25rem;
  background-color: #2A2A2A;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pagination .page-link:hover {
  background-color: #161413; 
  color: black; 
  border-color: #ccc; 
}

.pagination .page-item.active .page-link {
  background-color: var(--primary); 
  border-color: var(--primary);
  color: black !important; 
}

.pagination .page-item.disabled .page-link {
  color: #161413; 
  background-color: #e9ecef; 
  border-color: #dee2e6; 
}

.pagination .page-link:focus {
  color: #161413;
  font-weight: 600;
  border: #161413;
  box-shadow: 0 0 0 0.2rem rgba(114, 254, 238, 0.911); 
}

.pagination .page-link span {
  vertical-align: middle;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  background-color: #2A2A2A;
  border-radius: 6px !important;
  border-color: #d2d2d2 !important;
}
.page-item:first-child .page-link:hover,
.page-item:last-child .page-link:hover {
  background-color: #484848 !important;
  border-color: #666666 !important;
}

@media (max-width:426px){
  .withdraw-btn {
    height: 40px;
    margin: 0px 0px 35px 0px;
    padding: 10px;
    font-size: 1.6rem;
  }
  .paypal-connected {
    font-size: 1.4rem;
    color: white;
    margin: 8px 0;
    text-align: center;
  }
  
}
