.h-hero-section {
  width: 100%;
  margin-top: 30px;
}
.h-hero-section > div {
  width: 1070px;
  margin: auto;
  position: relative;
}
.h-hero-section > div > h1 {
  font-size: 5rem;
  color: var(--primary) !important;
  font-weight: 200;
}

.hero.swiper {
  width: 100%;
  /* height: 600px; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}
.hero.siwper .swiper-wrapper {
  width: 100%;
  height: 100%;
  border: 1px solid red;
}
.hero.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  /* border: 1px solid red; */
  align-items: center;
}
.hero.swiper-slide {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.hero.swiper-slide img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero.swiper-slide img{
  width: 798px;
  height: 600px;
}
.swiper-slide button {
  font-size: 1.9rem;
  font-weight: 600;
  outline: none;
  border: none;
  border-radius: 30px;
  padding: 3px 20px 3px 20px;
  background-color: var(--primary);
  transition: all 0.2s;
}
.swiper-slide button:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.hero.swiper-pagination {
  height: fit-content;
  top: 65px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.swiper-pagination-bullet {
  padding: 7px;
  border: 2px solid rgb(210, 210, 210);
  outline: none;
  margin-left: 5px;
}
.swiper-pagination-bullet-active {
  background: gray;
}

@media (min-width: 0px) and (max-width: 800px) {
  .h-hero-section .hero.swiper {
    width: 90%;
    margin-top: 30px;
  }
  .h-hero-section .hero.swiper-pagination {
    top: 26% !important;
  }
  .h-hero-section > div > h1 {
    font-size: 3rem;
    text-align: center;
    font-weight: 200;
  }
  .h-hero-section .swiper button {
    font-size: 2rem;
  }
}

@media (max-width: 345px) {
  .hero.swiper-slide img{
    width: 319px;
  }
  .h-hero-section > div {
    /* width: 280px; */
    width: 100%;
  }
  .h-hero-section .hero.swiper {
    width: 100%;
    margin-top: 30px;
  }
  .h-hero-section .hero.swiper img{
    height: 240px;
  }
  .h-hero-section .hero.swiper-pagination {
    top: 55px !important;
  }
}

@media (min-width: 346px) and (max-width: 480px) {
  .hero.swiper-slide img{
    width: 373px;
  }
  .h-hero-section > div {
    /* width: 310px; */
    width: 100%;
  }
  .h-hero-section .hero.swiper {
    width: 100%;
    margin-top: 30px;
  }
  .h-hero-section .hero.swiper img{
    height: 281px;
  }
  .h-hero-section .hero.swiper-pagination {
    top: 55px !important;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .h-hero-section > div {
    width: 500px;
  }
  .h-hero-section .hero.swiper {
    width: 100%;
    margin-top: 30px;
  }
  .hero.swiper-slide img{
    width: 558px;
  }
  .h-hero-section .hero.swiper img{
    height: 420px;
  }
  .h-hero-section .hero.swiper-pagination {
    top: 55px !important;
  }
}

@media (min-width: 602px) and (max-width: 786px) {
  .h-hero-section > div {
    width: 650px;
  }
  .h-hero-section > div > h1 {
    text-align: start;
  }
  .h-hero-section .hero.swiper {
    width: 100%;
    margin-top: 30px;
  }
  .hero.swiper-slide img{
    width: 452px;
  }
  .h-hero-section .hero.swiper img{
    height: 340px;
  }
  .h-hero-section .hero.swiper-pagination {
    top: 55px !important;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .h-hero-section > div {
    width: 700px;
  }
  .h-hero-section > div > h1 {
    text-align: start;
  }
  .h-hero-section .hero.swiper {
    width: 100%;
    margin-top: 30px;
  }
  .h-hero-section .hero.swiper img{
    height: 340px;
  }
  .h-hero-section .hero.swiper-pagination {
    top: 55px !important;
  }
}

@media (min-width: 1001px) and (max-width: 1100px) {
  .h-hero-section > div {
    width: 900px;
  }
  .h-hero-section > div > h1 {
    text-align: start;
  }
  .h-hero-section .hero.swiper {
    width: 100%;
    margin-top: 30px;
  }
  .hero.swiper-slide img{
    width: 665px;
  }
  .h-hero-section .hero.swiper img{
    height: 500px;
  }
  .h-hero-section .hero.swiper-pagination {
    top: 75px !important;
  }
}