.how-to-play {
  width: 100%;
  min-height: 30vh;
  margin: auto;
  margin-top: 15px;
}
.how-to-play > div {
  width: 1070px;
  margin: auto;
}
.how-to-play > div > div h5 {
  font-size: 3rem;
  color: var(--primary);
}
.how-to-play > div > div p {
  color: rgb(210, 210, 210);
  font-size: 1.7rem;
}

@media (max-width: 800px) {
  .how-to-play > div > div h5 {
    font-size: 2.2rem;
  }
  .how-to-play > div > div p {
    font-size: 1.4rem;
  }
}

@media (max-width:345px)
{
  .how-to-play>div
  {
    width: 270px;
    text-align: center;
  }
}

@media (min-width: 346px) and (max-width: 480px) {
  .how-to-play > div {
    width: 310px;
    text-align: center;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .how-to-play > div {
    width:500px;
  }
}

@media (min-width: 800px) and (max-width:1000px) {
  .how-to-play > div {
    width: 700px;
  }
  .how-to-play > div > div h5 {
    font-size: 2.2rem;
  }
  .how-to-play > div > div p {
    font-size: 1.4rem;
  }
}

@media (min-width: 1001px) and (max-width:1100px) {
  .how-to-play > div {
    width: 900px;
  }
}

@media (min-width: 601px) and (max-width:786px) {
  .how-to-play > div {
    width:650px;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .how-to-play > div > div > h5 {
    font-size: 2.2rem;
  }
  .how-to-play > div > div p {
    font-size: 1.4rem;
  }
}
