.winner {
  width: 100%;
  margin-top: 50px;
}
.winner > div {
  width: 1070px;
  margin: auto;
}
.winner > div > div > h1 {
  font-size: 5rem;
  font-weight: 200;
  color: var(--primary);
}
.winner > div > div:nth-child(1) {
  display: flex;
}
.winner > div > div:nth-child(1) > h1,
.winner > div > div:nth-child(1) > div {
  width: 40%;
  display: flex;
  align-items: center;
}
.winner > div > div:nth-child(1) > div {
  width: 60%;
}

.date {
  padding: 3px 10px 3px 10px;
  background-color: rgb(210, 210, 210);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.date > h1 {
  font-size: 2rem;
  display: flex;
  align-items: center;
  color: black;
  padding-left: 5px;
  font-weight: bold;
  justify-content: center;
  margin: 0;
}
.date > i {
  font-size: 2rem;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  color: black;
}
.winner .swiper:hover .winner-content_hov {
  visibility: visible;
}
.winner .swiper .winner-content_hov {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.68);
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  padding-left: 50px;
  visibility: hidden;
}
.winner .swiper .winner-content_hov h1 {
  font-size: 30px;
  padding: 10px 0;
}
.winner .swiper .winner-content_hov p {
  font-size: 16px;
  margin: 0;
}
.winner .portfolio-item-container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 50px;
}

.winner .portfolio-item-container > div {
  width: 45%;
  height: 408px;
  margin-top: 30px;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.winner .portfolio-item button {
  padding: 3px 20px 3px 20px;
  font-size: 2rem;
  border-radius: 20px;
  margin-top: 10px;
  background-color: rgb(90, 90, 90);
  font-weight: bold;
}

.winner .swiper {
  width: 100%;
  height: 90%;
  margin-left: auto;
  margin-right: auto;
}

.winner .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: rgb(210, 210, 210);
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: all 0.5s;
}
.winner .swiper-slide {
  background-position: center;
  background-size: cover;
}
.winner button:hover {
  background-color: var(--primary);
}
.winner .swiper-slide:hover {
  transform: scale(1.07);
}
.winner .swiper-button-next,
.winner .swiper-button-prev {
  border: 2px solid rgb(210, 210, 210);
  border-radius: 50%;
  padding: 20px;
  font-size: 1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-button-next::after,
.winner .swiper-button-prev::after {
  font-size: 3rem;
  color: white;
}
@media (max-width: 1000px) {
  .winner .swiper .winner-content_hov {
    padding-left: 0px;
  }
  .winner .swiper .winner-content_hov h1 {
    font-size: 23px;
    padding: 6px 0;
  }
  .winner .swiper .winner-content_hov p {
    font-size: 16px;
    margin: 0;
  }
}

@media (max-width: 800px) {
  .winner > div {
    text-align: center;
  }
  .winner > div > div:nth-child(1) {
    display: flex;
    flex-direction: column;
  }
  .winner > div > div:nth-child(1) > h1,
  .winner > div > div:nth-child(1) > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .winner > div > div:nth-child(1) > h1 {
    font-size: 3rem;
  }
  .winner .date {
    margin-left: 0 !important;
  }
  .winner .portfolio-item-container {
    justify-content: center;
  }
}

@media (max-width: 345px) {
  .winner > div {
    width: 270px;
    text-align: center;
  }
  .winner .portfolio-item-container > div {
    width: 100%;
    height: 249px;
  }
  .winner .portfolio-item button{
    font-size: 1.4rem;
  }
}

@media (min-width: 346px) and (max-width: 480px) {
  .winner > div {
    width: 310px;
    text-align: center;
  }
  .winner .portfolio-item-container > div {
    width: 100%;
    height: 279px;
  }
  .winner .portfolio-item button{
    font-size: 1.5rem;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .winner > div {
    width: 500px;
  }
  .winner .portfolio-item-container {
    justify-content: space-between;
  }
  .winner .portfolio-item-container > div {
    width: 46%;
    height: 279px;
  }
  .winner .portfolio-item button{
    font-size: 1.5rem;
  }
}

@media (min-width: 601px) and (max-width: 786px) {
  .winner > div {
    width: 650px;
  }

  .winner .portfolio-item-container {
    justify-content: space-between;
  }
  .winner .portfolio-item-container > div {
    width: 46%;
    height: 270px;
  }
  .winner .portfolio-item button {
    font-size: 1.6rem;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .winner > div {
    width: 700px;
  }
  .winner .portfolio-item-container > div {
    width: 46%;
    height: 288px;
  }
  .winner > div > div:nth-child(1) > h1,
  .winner > div > div:nth-child(1) > div {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
}

@media (min-width: 1001px) and (max-width: 1100px) {
  .winner > div {
    width: 900px;
  }
  .winner .portfolio-item-container > div {
    width: 46%;
    height: 357px;
  }
  .winner > div > div:nth-child(1) > h1,
  .winner > div > div:nth-child(1) > div {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
}
