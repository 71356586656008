.myacount-con
{
     width: 1070px; 
    margin: auto;
    margin-top: 60px;
    display: flex;
    justify-content: center;
}

@media (max-width:900px)
{
    .myacount-con
    {
        flex-direction: column;
        justify-content: flex-start;
    }
}

@media (max-width:345px)
{
    .myacount-con
    {
        width: 299px;
    }
}


@media (min-width:346px) and (max-width:480px)
{
    .myacount-con
    {
        width: 310px;
    }
}

@media (min-width:481px) and (max-width:600px)
{
    .myacount-con
    {
        width: 500px;
    }
}

@media (min-width:601px) and (max-width:800px)
{
    .myacount-con
    {
        width: 650px;
    }
}

@media (min-width:801px) and (max-width:1000px)
{
    .myacount-con
    {
        width: 700px;
    }
}

@media (min-width:1001px) and (max-width:1100px)
{
    .myacount-con
    {
        width: 900px;
    }
}