.trustpilot-widget {
  display: flex;
  justify-content: center;
  /* border: 1px solid red; */
}

.colorx {
  color: red;
  font-size: 30px;
}
