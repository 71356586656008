.privacy {
  width: 1070px;
  height: 430px;
  margin: 60px auto 70px auto;
  user-select: none;
}
.privacy>div
{
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  border-top: 1px solid rgb(90,90,90);
  border-bottom: 1px solid rgb(90,90,90);
  margin: auto;
}

.privacy h4 {
  font-size: 2.4rem;
  color: rgb(210,210,210);
  margin-top: 7px;
}
.privacy p {
  margin-top: 7px;
  font-size: 1.4rem;
  color: rgb(210,210,210);
}

@media (max-width: 500px) {
  .terms {
    height: 460px;
  }
}
@media (max-width: 800px) {
  .privacy
{
  width: 100%;
}
  .privacy h4 {
    font-size: 2rem;
  }
  .privacy p {
    font-size: 1.2rem;
  }
}

.privacy>div::-webkit-scrollbar-thumb {
  background: rgb(53, 53, 53);
  border: 3px solid gray;  /* creates padding around scroll thumb */

}

.privacy>div::-webkit-scrollbar-track {
  background: gray;
}

.privacy>div::-webkit-scrollbar {
  width: 9px;
}


@media (max-width:500px)
{
  .privacy {
    width: 1070px;
    height: 460px;
  }
}

@media (max-width:345px)
{
  .privacy
  {
    width: 270px;
  }
}

@media (min-width:346px) and (max-width:480px)
{
  .privacy
  {
    width: 310px;
  }
}


@media (min-width:481px) and (max-width:600px)
{
  .privacy
  {
    width: 500px;
  }
}
@media (min-width:601px) and (max-width:786px)
{
  .privacy
  {
    width: 650px;
  }
}
@media (min-width:800px) and (max-width:1000px)
{
  .privacy
  {
    width: 700px;
  }
}
@media (min-width:1001px) and (max-width:1100px)
{
  .privacy
  {
    width: 900px;
  }
}