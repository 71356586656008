.bar{
    background-color: var(--primary);
  color: #161413;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  line-height: 23px;
  padding: 5px 20px;
  flex-wrap: wrap;
  height: auto;
  margin-bottom: 3px;
}

@media (max-width:430px){
.bar{
  font-size: 14px;
  line-height: 18px;
  padding: 5px 15px;
  margin-bottom: 4px;

    }
}