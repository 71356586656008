.card-container {
  display: flex;
  flex-direction: column;
  background-color: #2a2a2a;
  align-items: center;
  width: 158px;
  padding-bottom: 15px;
  padding-left: 7px;
  padding-right: 7px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.card-image {
  width: 158px;
  height: 118px;
  object-fit: cover;
}

.card-heading {
  color: var(--primary);
  color: #d2d2d2 !important;
  font-size: 1.65rem !important;
  height: 40px;
  overflow: hidden;
  font-weight: 400 !important;
  margin: 10px 0px 5px 0px;
}

.card-quantity {
  display: flex;
  justify-content: center;
  width: 130px;
  margin: 10px 0;
  align-items: center;
}

.card-quantity button {
  border: 1px solid #00ffea;
  padding: 5px 5px;
  font-weight: bold;
  border-radius: 4px;
  background-color: #202020;
  color: #00ffea;
  display: flex;
  align-self: center;
  cursor: pointer;
  user-select: none;
  box-shadow: 5px 5px 10px rgb(15 15 15);
}
.card-quantity button:active {
  color: rgb(98 98 98);
  border: 2px solid var(--primary);
  background-color: var(--primary); 
  transform: scale(0.95); 
}

.card-quantity button:disabled {
  color: var(--primary);
  border: 2px solid rgb(105, 105, 105);
  background-color: rgb(105, 105, 105);
  opacity: 0.6; 
}
.card-quantity h2 {
  background-color: #202020;
  font-size: 16px;
  width: 45px;
  height: 35px;
  border-radius: 4px;
  color: gold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  margin: 0px 7px;
  box-shadow: 2px 2px 10px #00ffea;
}

.card-price {
  color: #d2d2d2 !important;
  font-size: 1.6rem !important;
  font-weight: 600 !important;
}

.card-price-text {
  color: #d2d2d2 !important;
  font-size: 1.5rem !important;
  font-weight: 400 !important;
}

.card-button {
  background-color: var(--primary);
  color: rgb(41, 41, 41);
  font-weight: 700;
  font-size: 13px;
  border: none;
  width: 120px;
  padding: 8px 10px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.card-button:hover {
  background-color: rgb(41, 41, 41);
  color: var(--primary);
  border: 1px solid var(--primary);
}

.card-container .progress {
  width: 100%;
  height: 8px;
  background-color: #e0e0e000;
  border-radius: 2px;
  border:1px solid var(--primary);
  overflow: hidden;
  margin: 2px 0 0px 0px;
}

.card-container .progress-bar {
  height: 100%;
  background-color: var(--primary);
  transition: width 0.4s ease;
}


.c-section-1 {
  width: 1070px;
  margin: auto;
  margin-top: 50px;
}
.undisableClass {
  pointer-events: auto;
  cursor: pointer;
}
.c-section-1 > h1 {
  font-size: 5rem;
  color: var(--primary);
}
.c-section-1 .items-link {
  text-decoration: none;
  height: 182px;
  display: flex;
}
.c-section-1 .items-container {
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.c-section-1 .items {
  width: 22.8%;
  /* height: 260px; */
  margin-top: 30px;
  margin-right: 2.2%;
}

.c-section-1 .items .items-link > div {
  position: relative;
  overflow: hidden;
}
.items .items-link > div > div:hover {
  transform: scale(1.07);
}
.c-section-1 .items .items-link > div > div:hover .sold-out {
  width: 90%;
  transform: rotate(0deg);
}
.c-section-1 .items .items-link > div > div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  background-position: center;
  background-size: cover;
  transition: all 0.5s;
  align-items: center;
}
.c-section-1 .sold-out {
  width: 95%;
  height: 30px;
  background-color: var(--primary);
  transform: rotate(-19deg);
  text-align: center;
  margin: auto;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.3s;
  color: black;
}
.items .progress {
  border: 1.6px solid rgb(0 188 173) !important;
  width: 94% !important;
  height: 1.7vh !important;
  border-radius: 16px;
  position: relative;
  background-color: transparent;
  margin-bottom: 15px;
  /* box-shadow: 3px 4px 10px rgb(0 94 102 / 88%);  */
}
.items .progress-bar {
  height: 100%;
  width: 0; 
  border-radius: 9px;
    background: var(--primary);
    background: linear-gradient(90deg, rgb(0 188 173) 0, #00ffe9 100%);
    transition: width .6s, background-color 0.4s ease;;
}
/* .c-section-1 .items-colorfull {
  width: 90%;
  height: 20px;
  border-radius: 10px;
  border: 2px solid var(--primary);
  margin-bottom: 15px;
  position: relative;
}
.c-section-1 .progress-bar {
  height: 100%;
  position: absolute;
  background-color: var(--primary);
  border-radius: 10px;
} */

.c-section-1 .items .items-link > div {
  width: 100%;
  height: 100%;
}
.c-section-1 .items h3 {
  font-size: 1.6rem;
  color: rgb(210, 210, 210);
  margin-top: 5px;
  padding-left: 10px;
}
.c-section-1 .items h2 {
  padding-left: 5px;
  font-size: 2.7rem;
  color: var(--primary);
}
.discount-bar {
  margin-right: 10px;
  text-decoration: line-through;
}

@media (max-width: 345px) {
  .c-section-1 .items .items-link > div > div {
    width:300px;
    height: 225px;
  }
  .c-section-1 {
    width: 270px;
  }
  .c-section-1 > h1 {
    text-align: center;
    font-size: 3rem;
  }
  .c-section-1 .items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .c-section-1 .items {
    width: 100%;
    margin-right: 0;
  }

  .card-container {
    width: 135px;
    padding-left: 3px;
    padding-right: 3px;
  }
  
  .card-image {
    width: 135px;
    height: 118px;
    object-fit: cover;
  }
  
  .card-heading {
    font-size: 1.35rem !important;
    height: 30px;
    overflow: hidden;
  }
  
  .card-quantity h2 {
    font-size: 1.45rem;
  }
  
  .card-price {
    font-size: 1.3rem !important;
  }
  
  .card-price-text {
    font-size: 1.3rem !important;
  }
  
}

@media (min-width: 346px) and (max-width: 480px) {
  .c-section-1 .items .items-link > div > div {
    width:300px;
    height: 225px;
  }
  .c-section-1 {
    width: 300px;
  }
  .c-section-1 > h1 {
    text-align: center;
    font-size: 3rem;
  }
  .c-section-1 .items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .c-section-1 .items {
    width: 90%;
    margin-right: 0;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .c-section-1 {
    width: 500px;
  }
  .c-section-1 > h1 {
    text-align: center;
    font-size: 3rem;
  }
  .c-section-1 .items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .c-section-1 .items {
    width: 45%;
    /* height: 230px; */
    margin-right: 0;
  }
}

@media (min-width: 601px) and (max-width: 786px) {
  .c-section-1 {
    width: 650px;
  }
  .c-section-1 > h1 {
    text-align: start;
    font-size: 3rem;
  }
  .c-section-1 .items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .c-section-1 .items {
    width: 41%;
    margin-right: 0;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .main-section .c-section-1 {
    width: 700px;
  }
  .main-section .items-container {
    justify-content: space-between;
  }
  .c-section-1 .items {
    width: 30%;
    /* height: 230px; */
    margin-right: 0;
  }
}

@media (min-width: 1001px) and (max-width: 1100px) {
  .main-section .c-section-1 {
    width: 900px;
  }
  .c-section-1 .items-container {
    justify-content: space-between;
  }
  .c-section-1 .items {
    width: 30%;
    /* height: 265px; */
    margin-right: 0;
  }
}
